import React, { useCallback, useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Add as AddIcon } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ClientType, UserType } from "../../types";
import ClientSearch from "./ClientSearch";
import useClientService from "../../hooks/ClientService";
import AddClientDialog from "./AddClientDialog";
import { useApplicationState } from "../../hooks/ApplicationState";
import { useNavigate } from "react-router-dom";
import DeleteClientDialog from "./DeleteClientDialog";
import ClientStatusChip from "../client/ClientStatusChip";
import FormattedTimestamp from "../FormattedTimestamp";
import useUserService from "../../hooks/UserService";
import { get, includes, keyBy, orderBy } from "lodash";
import FormattedUserName from "../FormattedUserName";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import { Button, TableSortLabel } from "@mui/material";
import ClientAvatar from "../client/ClientAvatar";
import RemindClientDialog from "./RemindClientDialog";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemText from "@mui/material/ListItemText";
import { RequirementStatusEnumAgentDisplay, RequirementStatusEnumClientDisplay } from "../../enums/RequirementStatus";

type SortBy = "name" | "manager" | "nextDueDate" | "activePeriodName" | "status";
type SortDirection = "asc" | "desc";

const Home = () => {
  const clientService = useClientService();
  const userService = useUserService();
  const navigate = useNavigate();
  const { isAccountant, organisationIds } = useApplicationState();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [clients, setClients] = useState<ClientType[]>([]);

  // clients after applying search filters
  const [filteredClients, setFilteredClients] = useState<ClientType[]>([]);

  // clients after applying sort filters
  const [sortedClients, setSortedClients] = useState<ClientType[]>([]);

  const [accountants, setAccountants] = useState<Record<string, UserType>>({});

  const [addClientDialogOpen, setAddClientDialogOpen] = useState<boolean>(false);
  const [clientToDelete, setClientToDelete] = useState<ClientType>();
  const [clientToRemind, setClientToRemind] = useState<ClientType>();

  const [menuClient, setMenuClient] = useState<ClientType>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const loadClients = useCallback(async () => {
    const results = await clientService.getClients(organisationIds);
    setClients(results);
  }, [organisationIds, clientService]);

  const loadAccountants = useCallback(async () => {
    if (isAccountant) {
      const results = await userService.listAccountantUsers(organisationIds);
      setAccountants(keyBy(results, (result) => result.uid));
    }
  }, [organisationIds, userService, isAccountant]);

  useEffect(() => {
    void loadClients();
    void loadAccountants();
  }, [loadClients, loadAccountants]);

  useEffect(() => {
    if (sortBy && includes(["name", "nextDueDate", "activePeriodName", "status"], sortBy)) {
      setSortedClients(orderBy(filteredClients, (client) => client[sortBy], sortDirection));
    } else if (sortBy === "manager") {
      setSortedClients(
        orderBy(
          filteredClients,
          (client) => {
            const manager = client.manager ? accountants[client.manager] : undefined;
            return manager ? `${manager.firstName} ${manager.lastName}` : "";
          },
          sortDirection
        )
      );
    } else {
      setSortedClients(filteredClients);
    }
  }, [sortBy, sortDirection, filteredClients, accountants]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchResults = (results: ClientType[]) => {
    setFilteredClients(results);
  };

  const handleKebabMenuClick = (event: React.MouseEvent<HTMLElement>, client: ClientType) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setMenuClient(client);
  };

  const addClient = () => {
    setAddClientDialogOpen(true);
  };

  const addClientClose = () => {
    setAddClientDialogOpen(false);
  };

  const viewClient = async (client: ClientType) => {
    if (isAccountant && client.isUpdated) {
      await clientService.clearIsUpdated(client);
    }
    navigate(clientService.getClientUrl(client.organisationId, client.clientId));
  };

  const manageClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      navigate(`${clientService.getClientUrl(menuClient.organisationId, menuClient.clientId)}/manage`);
    }
  };

  const deleteClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      setClientToDelete(menuClient);
    }
  };

  const remindClient = () => {
    if (menuClient) {
      setAnchorEl(undefined);
      setClientToRemind(menuClient);
    }
  };

  const deleteClientClose = (clientDeleted: boolean) => {
    setClientToDelete(undefined);
    if (clientDeleted) {
      void loadClients();
    }
  };

  const remindClientClose = () => {
    setClientToRemind(undefined);
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          p: 2,
        }}
      >
        <Typography variant="h4">Clients</Typography>
        {isAccountant && (
          <Box>
            <Button variant="contained" startIcon={<AddIcon />} size="small" onClick={addClient}>
              New client
            </Button>
          </Box>
        )}
      </Box>

      <Grid container spacing={2} sx={{ p: 2 }}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <ClientSearch clients={clients} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Clients" size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortLabel field="name" label="Client" />
                  </TableCell>

                  {isAccountant && (
                    <TableCell>
                      <SortLabel field="manager" label="Manager" />
                    </TableCell>
                  )}

                  <TableCell>
                    <SortLabel field="nextDueDate" label="Next due date" />
                  </TableCell>

                  <TableCell>
                    <SortLabel field="activePeriodName" label="Active period" />
                  </TableCell>

                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["WITH_CLIENT"]
                      : RequirementStatusEnumClientDisplay["WITH_CLIENT"]}
                  </TableCell>
                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["WITH_ACCOUNTANT"]
                      : RequirementStatusEnumClientDisplay["WITH_ACCOUNTANT"]}
                  </TableCell>
                  <TableCell sx={{ px: 0.5, textAlign: "right" }}>
                    {isAccountant
                      ? RequirementStatusEnumAgentDisplay["COMPLETE"]
                      : RequirementStatusEnumClientDisplay["COMPLETE"]}
                  </TableCell>

                  <TableCell sx={{ textAlign: "right" }}>
                    <SortLabel field="status" label="Status" />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedClients.map((client) => (
                  <TableRow key={client.clientId} onClick={() => viewClient(client)} hover sx={{ cursor: "pointer" }}>
                    {/* AVATAR AND NAME */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ClientAvatar client={client} />
                        <Typography component="div" sx={{ fontWeight: "bold" }}>
                          {client.name}
                        </Typography>
                      </Box>
                    </TableCell>

                    {/* MANAGER */}
                    {isAccountant && (
                      <TableCell sx={{ py: 1.5 }}>
                        <Typography>
                          <FormattedUserName
                            user={client.manager ? accountants[client.manager] : undefined}
                            defaultValue="Not assigned"
                          />
                        </Typography>
                      </TableCell>
                    )}

                    {/* NEXT DUE DATE */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Typography>
                        <FormattedTimestamp timestamp={client.nextDueDate} defaultValue="Not set" />
                      </Typography>
                    </TableCell>

                    {/* ACTIVE PERIOD */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Typography>{client.activePeriodName || ""}</Typography>
                    </TableCell>

                    {/* REQUIREMENT COUNTS */}
                    <TableCell sx={{ textAlign: "right" }}>
                      {get(client.requirementCounts, "WITH_CLIENT", "")}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      {get(client.requirementCounts, "WITH_ACCOUNTANT", "")}
                    </TableCell>
                    <TableCell sx={{ textAlign: "right" }}>{get(client.requirementCounts, "COMPLETE", "")}</TableCell>

                    {/* STATUS AND OPTIONS */}
                    <TableCell sx={{ py: 1.5 }}>
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Box>
                          <ClientStatusChip client={client} />
                        </Box>
                        <Box>
                          {isAccountant && (
                            <IconButton
                              size="small"
                              aria-label="More options"
                              sx={{ ml: 1 }}
                              onClick={(event) => handleKebabMenuClick(event, client)}
                            >
                              <MoreVertIcon fontSize="inherit" />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* KEBAB MENU ACTIONS */}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(undefined)}>
        <MenuItem onClick={deleteClient} dense>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete client</ListItemText>
        </MenuItem>
        <MenuItem onClick={manageClient} dense>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Manage client</ListItemText>
        </MenuItem>
        <MenuItem onClick={remindClient} dense>
          <ListItemIcon>
            <EmailIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Send reminder</ListItemText>
        </MenuItem>
      </Menu>

      <AddClientDialog open={addClientDialogOpen} onClose={addClientClose} />

      {!!clientToDelete && <DeleteClientDialog client={clientToDelete} hasPeriods={true} onClose={deleteClientClose} />}

      {!!clientToRemind && <RemindClientDialog client={clientToRemind} onClose={remindClientClose} />}
    </>
  );
};

export default Home;
