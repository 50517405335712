import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { map } from "lodash";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { ShoeboxItem } from "../../types";
import FormattedTimestamp from "../FormattedTimestamp";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import TaxyTooltip from "../TaxyTooltip";
import { useApplicationState } from "../../hooks/ApplicationState";

type ShoeboxItemDetailsDialogProps = {
  organisationId: string;
  clientId: string;
  shoeboxItem: ShoeboxItem;
  onClose: () => void;
};

const ShoeboxItemDetailsDialog = ({
  organisationId,
  clientId,
  shoeboxItem,
  onClose,
}: ShoeboxItemDetailsDialogProps) => {
  const { refreshIdToken } = useApplicationState();

  const previewFile = useCallback(
    async (fileId: string) => {
      await refreshIdToken();
      const path = `/shoebox-preview/${organisationId}/${clientId}/${shoeboxItem.id}/${fileId}`;
      const newWindow = window.open(path, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    },
    [organisationId, clientId, shoeboxItem, refreshIdToken]
  );

  return (
    <Dialog
      open={true}
      onClose={() => onClose()}
      disableRestoreFocus
      fullWidth
      maxWidth="xl"
      scroll="paper"
      sx={{ m: 4 }}
      PaperProps={{ sx: { height: "100vh" } }}
      className="requirementDetails"
    >
      <DialogTitle>
        <Box sx={{ display: "flex" }}>
          <Box className="title" sx={{ display: "flex" }}>
            {shoeboxItem.subject}
          </Box>
        </Box>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "grey.500",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
              <FormattedTimestamp timestamp={shoeboxItem.createdTimestamp} />
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
              {shoeboxItem.from}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
              {shoeboxItem.text}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ mb: 2 }}>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", color: "text.secondary" }}>
              {map(shoeboxItem.files, (name, fileId) => {
                return (
                  <Box
                    key={fileId}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TaxyTooltip title="Click to preview">
                      <ListItem dense disableGutters>
                        <ListItemButton dense onClick={() => previewFile(fileId)}>
                          <ListItemIcon>
                            <InsertDriveFileOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary={name} />
                        </ListItemButton>
                      </ListItem>
                    </TaxyTooltip>
                  </Box>
                );
              })}
            </Box>
          </Grid>

          <Grid item xs={12} textAlign="right">
            <Button onClick={() => onClose()} variant="outlined" color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ShoeboxItemDetailsDialog;
