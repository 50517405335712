import { useMemo } from "react";
import { indexOf } from "lodash";

export class ShoeboxService {
  private ID_SEPARATOR = "/";

  public parseShoeboxDragId(dragId: string): string[] {
    const separatorIndex = indexOf(dragId, this.ID_SEPARATOR);
    if (separatorIndex > 0) {
      const shoeboxId = dragId.substring(0, separatorIndex);
      const fileId = dragId.substring(separatorIndex + 1);
      return [shoeboxId, fileId];
    } else {
      return [];
    }
  }

  public createShoeboxDragId(shoeboxId: string, fileId: string): string {
    return `${shoeboxId}${this.ID_SEPARATOR}${fileId}`;
  }
}

const useShoeboxService = () => {
  return useMemo(() => new ShoeboxService(), []);
};

export default useShoeboxService;
