import React from "react";
import { OrganisationType } from "../../types";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TeamList from "./TeamList";

const ManageTeams = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            pl: 2,
            pt: 2,
            pb: 4,
            borderBottom: "1px solid",
            borderColor: "neutral.light",
          }}
        >
          <Typography variant="h4">My teams</Typography>
        </Grid>

        <TeamList
          onClick={(organisation: OrganisationType) => {
            navigate(`/manage/${organisation.organisationId}`);
          }}
        />
      </Grid>
    </>
  );
};

export default ManageTeams;
