import { Box } from "@mui/material";
import { FileType } from "../../types";
import { map } from "lodash";
import RenameFileDialog from "./RenameFileDialog";
import React, { useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import startDownload from "../../utilities/startDownload";
import TaxyTooltip from "../TaxyTooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import OpenIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FileTypeIcon from "./FileTypeIcon";

type FileListProps = {
  files: FileType[];
  remove: (file: FileType) => Promise<void>;
  rename: (file: FileType, fileName: string) => Promise<void>;
  togglePermanent: (file: FileType) => Promise<void>;
  isEditable: boolean;
};

const FileList = ({ files, remove, rename, togglePermanent, isEditable }: FileListProps) => {
  const { refreshIdToken, isAccountant } = useApplicationState();

  const [selectedFile, setSelectedFile] = useState<FileType>();
  const [renameFileDialogOpen, setRenameFileDialogOpen] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const handleRemoveFile = async (file: FileType) => {
    try {
      setProcessing(true);
      await remove(file);
    } finally {
      setProcessing(false);
    }
  };

  const handleTogglePermanent = async (file: FileType) => {
    try {
      setProcessing(true);
      await togglePermanent(file);
    } finally {
      setProcessing(false);
    }
  };

  const handlePreviewFile = async (file: FileType) => {
    try {
      setProcessing(true);
      await refreshIdToken();
      const newWindow = window.open(file.previewURL, "_blank", "noopener,noreferrer");
      if (newWindow) {
        newWindow.opener = null;
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleDownloadFile = async (file: FileType) => {
    try {
      if (file.downloadURL) {
        setProcessing(true);
        await refreshIdToken();
        startDownload(file.downloadURL);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleRenameFile = (file: FileType) => {
    setSelectedFile(file);
    setRenameFileDialogOpen(true);
  };

  const handleRenameFileDialogClose = () => {
    setRenameFileDialogOpen(false);
    setSelectedFile(undefined);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
      {map(files, (file) => (
        <Box
          key={file.name}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexGrow: 1, minWidth: 0 }}>
            <FileTypeIcon filename={file.name} />

            <TaxyTooltip title={file.name}>
              <Box component="span" sx={{ pl: 1, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                {file.name}
              </Box>
            </TaxyTooltip>

            {file.isPermanent && (
              <TaxyTooltip title="Permanent files are copied over when a new financial period is created. This can be used for documents such as trust deeds etc which are referenced every year.">
                <Box component="span" sx={{ ml: 1, color: "grey.500" }}>
                  (permanent file)
                </Box>
              </TaxyTooltip>
            )}
          </Box>

          <Box sx={{ display: "flex" }}>
            {/* MARK AS PERMANENT */}
            {isAccountant && (
              <TaxyTooltip title={file.isPermanent ? "Unmark as permanent" : "Mark as permanent"}>
                <Box>
                  <IconButton onClick={() => handleTogglePermanent(file)} disabled={processing}>
                    {file.isPermanent ? <LockIcon /> : <LockOpenIcon />}
                  </IconButton>
                </Box>
              </TaxyTooltip>
            )}

            {/* RENAME */}
            {isEditable ? (
              // permanent files can only be renamed by accountants
              file.isPermanent ? (
                isAccountant ? (
                  <TaxyTooltip title="Rename">
                    <Box>
                      <IconButton onClick={() => handleRenameFile(file)} disabled={processing}>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </TaxyTooltip>
                ) : (
                  <TaxyTooltip title="File has been marked as permanent by your accountant and cannot be be renamed">
                    <Box>
                      <IconButton disabled>
                        <EditIcon />
                      </IconButton>
                    </Box>
                  </TaxyTooltip>
                )
              ) : (
                <TaxyTooltip title="Rename">
                  <Box>
                    <IconButton onClick={() => handleRenameFile(file)} disabled={processing}>
                      <EditIcon />
                    </IconButton>
                  </Box>
                </TaxyTooltip>
              )
            ) : (
              <TaxyTooltip title="File cannot be renamed because the requirement is approved">
                <Box>
                  <IconButton disabled>
                    <EditIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            )}

            {/* DOWNLOAD */}
            {file.downloadURL ? (
              <TaxyTooltip title="Download">
                <Box>
                  <IconButton onClick={() => handleDownloadFile(file)} disabled={processing}>
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            ) : (
              <TaxyTooltip title="Download unavailable">
                <Box>
                  <IconButton disabled>
                    <DownloadIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            )}

            {/* PREVIEW */}
            {file.previewURL ? (
              <TaxyTooltip title="Preview">
                <Box>
                  <IconButton onClick={() => handlePreviewFile(file)} disabled={processing}>
                    <OpenIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            ) : (
              <TaxyTooltip title="Preview unavailable">
                <Box>
                  <IconButton disabled>
                    <OpenIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            )}

            {/* DELETE */}
            {isEditable ? (
              // permanent files can only be deleted by accountants
              file.isPermanent ? (
                isAccountant ? (
                  <TaxyTooltip title="Delete">
                    <Box>
                      <IconButton onClick={() => handleRemoveFile(file)} disabled={processing}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TaxyTooltip>
                ) : (
                  <TaxyTooltip title="File has been marked as permanent by your accountant and cannot be be deleted">
                    <Box>
                      <IconButton disabled>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TaxyTooltip>
                )
              ) : (
                <TaxyTooltip title="Delete">
                  <Box>
                    <IconButton onClick={() => handleRemoveFile(file)} disabled={processing}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TaxyTooltip>
              )
            ) : (
              <TaxyTooltip title="File cannot be deleted because the requirement is approved">
                <Box>
                  <IconButton disabled>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TaxyTooltip>
            )}
          </Box>
        </Box>
      ))}

      {selectedFile && (
        <RenameFileDialog
          open={renameFileDialogOpen}
          onClose={handleRenameFileDialogClose}
          file={selectedFile}
          rename={rename}
        />
      )}
    </Box>
  );
};

export default FileList;
