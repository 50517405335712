import Box from "@mui/material/Box";
import React from "react";
import { ReactComponent as IndividualIcon } from "./images/IndividualIcon.svg";
import { ReactComponent as CompanyIcon } from "./images/CompanyIcon.svg";
import { ReactComponent as TrustIcon } from "./images/TrustIcon.svg";
import { EntityType } from "../types";
import { EntityEnum } from "../enums";
import { get } from "lodash";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import convertServerTimestamp from "../utilities/convertServerTimestamp";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useApplicationState } from "../hooks/ApplicationState";
import StatusDot from "./client/StatusDot";
import TaxyTooltip from "./TaxyTooltip";

const ICONS = {
  [EntityEnum.Individual]: <IndividualIcon />,
  [EntityEnum.Company]: <CompanyIcon />,
  [EntityEnum.Trust]: <TrustIcon />,
};

const getIcon = (entity: EntityType) => {
  return get(ICONS, entity.entityType);
};

type EntityListProps = {
  entities: EntityType[];
  selectedEntity?: EntityType;
  onSelect: (entity: EntityType) => void;
  onEdit: (entity: EntityType) => void;
};

const EntityList = ({ entities, selectedEntity, onSelect, onEdit }: EntityListProps) => {
  const { isAccountant } = useApplicationState();
  return (
    <TableContainer component={Paper} square elevation={0}>
      <Table aria-label="Entity table" sx={{ ".MuiTableCell-root": { borderBottom: "none" } }} size="small">
        <TableBody>
          {entities.map((entity) => (
            <TableRow
              key={entity.entityId}
              selected={entity.entityId === selectedEntity?.entityId}
              sx={{ cursor: "pointer" }}
              onClick={() => onSelect(entity)}
              hover
            >
              <TableCell width="100%" sx={{ p: 0.5 }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", my: 1 }}>
                  <StatusDot requirementCounts={entity.requirementCounts} />
                  <Box sx={{ ml: 1, mr: 2 }}>{getIcon(entity)}</Box>

                  <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {entity.name}
                    </Typography>

                    <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 400 }}>
                      {`${entity.entityType} - ${
                        entity.dueDate
                          ? dayjs(convertServerTimestamp(entity.dueDate)).format("DD/MM/YYYY")
                          : "No due date"
                      }`}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              {isAccountant && (
                <TableCell sx={{ p: 0.5 }}>
                  <TaxyTooltip title="Edit entity">
                    <IconButton
                      size="small"
                      aria-label="Edit"
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(entity);
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TaxyTooltip>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EntityList;
