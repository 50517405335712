import Button from "@mui/material/Button";
import { RequirementStatusType, RequirementType } from "../../../types";
import { useApplicationState } from "../../../hooks/ApplicationState";
import ReplyIcon from "@mui/icons-material/ReplyOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { ReactNode } from "react";
import { darken } from "@mui/material/styles";

type ActionButtonProps = {
  label: string;
  loading: boolean;
  color: string;
  disabled?: boolean;
  icon: ReactNode;
  onClick: () => Promise<void>;
};

const ActionButton = ({ label, loading, color, icon, onClick, disabled = false }: ActionButtonProps) => {
  return (
    <Button
      variant="contained"
      endIcon={icon}
      sx={{
        backgroundColor: color,
        color: "text.primary",
        "&:hover": {
          backgroundColor: darken(color, 0.2),
        },
        ml: 1,
      }}
      disabled={loading || disabled}
      onClick={onClick}
      className="actionButton"
    >
      {label}
    </Button>
  );
};

type RequirementActionButtonsProps = {
  requirement: RequirementType;
  onUpdate: (status: RequirementStatusType) => Promise<void>;
  loading: boolean;
};

// TODO - remove custom colours on buttons and add to theme
const RequirementActionButtons = ({ requirement, loading, onUpdate }: RequirementActionButtonsProps) => {
  const { isAccountant } = useApplicationState();

  const sendToClient = async () => {
    await onUpdate("WITH_CLIENT");
  };

  const sendToAccountant = async () => {
    await onUpdate("WITH_ACCOUNTANT");
  };

  const approve = async () => {
    await onUpdate("COMPLETE");
  };

  const getButtons = () => {
    const buttons: { id: string; label: string; icon: ReactNode; color: string; onClick: () => Promise<void> }[] = [];

    // ACCOUNTANT BUTTONS
    if (isAccountant) {
      if (requirement.status !== "WITH_CLIENT") {
        buttons.push({
          id: "backToClient",
          label: "Back to client",
          icon: <ReplyIcon />,
          color: "#ccc",
          onClick: sendToClient,
        });
      }

      if (requirement.status === "COMPLETE") {
        buttons.push({
          id: "removeApproval",
          label: "Remove approval",
          icon: <ClearIcon />,
          color: "#ccc",
          onClick: sendToAccountant,
        });
      } else {
        buttons.push({
          id: "approve",
          label: "Approve",
          icon: <CheckIcon />,
          color: "#60DEC0",
          onClick: approve,
        });
      }
    }

    // CLIENT BUTTONS
    else {
      if (requirement.status === "WITH_CLIENT") {
        buttons.push({
          id: "returnToAccountant",
          label: "Return to accountant",
          icon: <ReplyIcon />,
          color: "#FA62AB",
          onClick: sendToAccountant,
        });
      }
    }

    return buttons;
  };

  return (
    <>
      {getButtons().map(({ id, label, color, icon, onClick }) => (
        <ActionButton key={id} label={label} loading={loading} color={color} icon={icon} onClick={onClick} />
      ))}
    </>
  );
};

export default RequirementActionButtons;
