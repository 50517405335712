import useInviteService from "../../hooks/InviteService";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";
import { Box, DialogContentText } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import { InviteType } from "../../types";

type ResendInviteDialogProps = {
  inviteId: string;
  open: boolean;
  onClose: (message?: string) => void;
};

const ResendInviteDialog = ({ inviteId, open, onClose }: ResendInviteDialogProps) => {
  const inviteService = useInviteService();
  const [invite, setInvite] = useState<InviteType>();

  const onConfirm = async () => {
    const message = await inviteService.resendInvite(inviteId);
    onClose(message);
  };

  const loadInvite = useCallback(async () => {
    const result = await inviteService.getInvite(inviteId);
    setInvite(result);
  }, [inviteService, inviteId]);

  useEffect(() => {
    void loadInvite();
  }, [loadInvite]);

  return (
    <ConfirmationDialog
      open={open}
      title="Resend invitation"
      content={
        <>
          <DialogContentText>Are you sure you want resend an invite to the following user?</DialogContentText>

          <Box sx={{ my: 4 }}>
            <Typography variant="body1">
              {invite?.invitee.firstName} {invite?.invitee.lastName}
            </Typography>
            <Typography variant="body1">{invite?.invitee.email}</Typography>
          </Box>
        </>
      }
      onClose={() => onClose()}
      confirmButtonLabel="Send"
      onConfirm={onConfirm}
      maxWidth="sm"
    />
  );
};

export default ResendInviteDialog;
