import React, { ReactElement } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PersonIcon from "@mui/icons-material/Person";
import { useApplicationState } from "./hooks/ApplicationState";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { join } from "lodash";
import { useNavigate } from "react-router-dom";

type DataItemProps = {
  icon: ReactElement;
  value: string;
};

const DataItem = ({ icon, value }: DataItemProps) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ color: "text.secondary" }}>{icon}</Box>
      <Typography variant="body1" sx={{ ml: 2 }}>
        {value}
      </Typography>
    </Box>
  );
};

const User = () => {
  const { user } = useApplicationState();
  const navigate = useNavigate();

  const logout = () => {
    navigate("/logout");
  };

  return (
    <Box>
      <Box sx={{ borderBottom: "1px solid", borderColor: "neutral.light", p: 2 }}>
        <Typography variant="h4">User</Typography>
      </Box>

      <Grid container spacing={2} p={2}>
        {user && (
          <>
            <Grid item xs={12}>
              <DataItem icon={<PersonIcon />} value={`${user.firstName} ${user.lastName}`} />
            </Grid>

            <Grid item xs={12}>
              <DataItem icon={<MailOutlineIcon />} value={user.email} />
            </Grid>

            <Grid item xs={12}>
              <DataItem icon={<VerifiedUserIcon />} value={join(user.roles, ", ")} />
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button variant="contained" startIcon={<LogoutIcon />} onClick={logout} size="small">
            Logout
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default User;
