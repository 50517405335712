import React, { ReactElement } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import InformationDialog from "../dialogs/InformationDialog";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

type KeyboardIconProps = {
  icon: ReactElement | string;
};

const KeyboardIcon = ({ icon }: KeyboardIconProps) => {
  return (
    <Avatar variant="rounded" sx={{ bgcolor: "grey.500" }}>
      {icon}
    </Avatar>
  );
};

type WorkListKeyboardShortcutsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const WorkListKeyboardShortcutsDialog = ({ open, onClose }: WorkListKeyboardShortcutsDialogProps) => {
  return (
    <InformationDialog
      open={open}
      title="Keyboard shortcuts"
      maxWidth="sm"
      content={
        <>
          <List subheader="You can use the following keyboard shortcuts to navigate the worklist:" sx={{ my: 2 }}>
            <ListItem>
              <ListItemAvatar>
                <KeyboardIcon icon={<ArrowDownwardIcon />} />
              </ListItemAvatar>
              <ListItemText primary="Down" secondary="Move down the list" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <KeyboardIcon icon={<ArrowUpwardIcon />} />
              </ListItemAvatar>
              <ListItemText primary="Up" secondary="Move up the list" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <KeyboardIcon icon={<KeyboardReturnIcon />} />
              </ListItemAvatar>
              <ListItemText primary="Enter / Return" secondary="Open the highlighted item" />
            </ListItem>
          </List>

          {false && (
            <List subheader="When viewing an item, you can action it with:" sx={{ my: 2 }}>
              <ListItem>
                <ListItemAvatar>
                  <KeyboardIcon icon="A" />
                </ListItemAvatar>
                <ListItemText primary="Shift + A" secondary="Approve the current item" />
              </ListItem>
              <ListItem>
                <ListItemAvatar>
                  <KeyboardIcon icon="B" />
                </ListItemAvatar>
                <ListItemText primary="Shift + B" secondary="Send the current item back to the client" />
              </ListItem>
            </List>
          )}
        </>
      }
      onClose={onClose}
    />
  );
};

export default WorkListKeyboardShortcutsDialog;
