import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useCatalogueService from "../../hooks/CatalogueService";
import SubmitButton from "../button/SubmitButton";

const addRequirementSchema = yup
  .object({
    requirementName: yup.string().trim().required("Requirement title is required"),
    requirementDescription: yup.string().trim(),
  })
  .required();

type AddRequirementType = yup.InferType<typeof addRequirementSchema>;

type AddCatalogueRequirementDialogProps = {
  organisationId: string;
  catalogueId: string;
  categoryId: string;
  open: boolean;
  onClose: (requirementAdded: boolean) => void;
};

const AddCatalogueRequirementDialog = ({
  organisationId,
  catalogueId,
  categoryId,
  open,
  onClose,
}: AddCatalogueRequirementDialogProps) => {
  const catalogueService = useCatalogueService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<AddRequirementType>({ resolver: yupResolver(addRequirementSchema) });

  const onSubmit = async (data: AddRequirementType) => {
    const { requirementName, requirementDescription } = data;
    await catalogueService.addRequirement(
      organisationId,
      catalogueId,
      categoryId,
      requirementName,
      requirementDescription ?? ""
    );
    handleClose(true);
  };

  const handleClose = (requirementAdded: boolean) => {
    reset();
    onClose(requirementAdded);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Add requirement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Title"
                error={!!errors.requirementName}
                helperText={errors.requirementName?.message}
                {...register("requirementName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                rows={8}
                fullWidth
                label="Description (optional)"
                error={!!errors.requirementDescription}
                helperText={errors.requirementDescription?.message}
                {...register("requirementDescription")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Add" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCatalogueRequirementDialog;
