type MaybeTimestamp = {
  seconds?: number;
  _seconds?: number;
  nanoseconds?: number;
  _nanoseconds?: number;
};

// Try to convert a server timestamp to unix timestamp, 0 otherwise
const convertServerTimestamp = <T extends MaybeTimestamp>(sts?: T) =>
  (sts?.seconds || sts?._seconds || 0) * 1000 +
  parseInt(`${(sts?.nanoseconds || sts?._nanoseconds || 0) / 1000000}`, 10);

export default convertServerTimestamp;
