import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CategoryType } from "../../../types";
import useEntityService from "../../../hooks/EntityService";
import { useForm } from "react-hook-form";
import SubmitButton from "../../button/SubmitButton";

type DeleteSubCategoryDialogProps = {
  open: boolean;
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategory: CategoryType;
  onClose: (subCategoryDeleted: boolean) => void;
};

const DeleteSubCategoryDialog = ({
  open,
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategory,
  onClose,
}: DeleteSubCategoryDialogProps) => {
  const entityService = useEntityService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    await entityService.deleteCategory(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategory.categoryId
    );
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Delete sub-category</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            You are about to delete the sub-category:&nbsp;
            <Box component="span" sx={{ fontWeight: "bold" }}>
              {subCategory.name}
            </Box>
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>This will also delete all requirements.</DialogContentText>
        </DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          <Button variant="outlined" color="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteSubCategoryDialog;
