import { collection, DocumentReference, orderBy, query } from "firebase/firestore";
import { useMemo } from "react";
import { CatalogueType, CategoryCatalogueType, CategoryType, RequirementType } from "../types";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";

export class CatalogueService {
  constructor(readonly firestoreService: FirestoreService, readonly httpFunctions: HttpFunctions) {}

  public async listCatalogues(organisationId: string): Promise<CatalogueType[]> {
    const collectionReference = this.firestoreService.getCataloguesCollection(organisationId);
    const querySnapshot = await this.firestoreService.getDocs(query(collectionReference, orderBy("name")));
    return querySnapshot.docs.map((cataloguesDocSnap) => cataloguesDocSnap.data() as CatalogueType);
  }

  public async getCatalogue(organisationId: string, catalogueId: string): Promise<CatalogueType | undefined> {
    const catalogueDocument = this.firestoreService.getCatalogueDocument(organisationId, catalogueId);
    const catalogueSnapshot = await this.firestoreService.getDocumentSnapshot(catalogueDocument);
    if (catalogueSnapshot.exists()) {
      const categories = await this.getCategories(catalogueDocument);
      return {
        ...catalogueSnapshot.data(),
        categories,
      } as CatalogueType;
    } else {
      return undefined;
    }
  }

  public async getCatalogueCategories(organisationId: string, catalogueId: string): Promise<CategoryType[]> {
    const categoriesCollection = this.firestoreService.getCatalogueCategoriesCollection(organisationId, catalogueId);
    const categoriesSnapshot = await this.firestoreService.getDocs(query(categoriesCollection, orderBy("order")));
    return categoriesSnapshot.docs.map((category) => category.data() as CategoryType);
  }

  // uses a backend function to calculate the 'order' value on the new category
  public async addCategory(organisationId: string, catalogueId: string, categoryName: string) {
    return this.httpFunctions.addCategoryToCatalogue({ organisationId, catalogueId, categoryName });
  }

  // uses a backend function to re-calculate the 'order' value on each category
  public async deleteCategory(organisationId: string, catalogueId: string, categoryId: string) {
    return this.httpFunctions.deleteCategoryFromCatalogue({ organisationId, catalogueId, categoryId });
  }

  public async editCategoryName(organisationId: string, catalogueId: string, categoryId: string, categoryName: string) {
    const documentReference = this.firestoreService.getCatalogueCategoryDocument(
      organisationId,
      catalogueId,
      categoryId
    );
    return this.firestoreService.updateDocument(documentReference, {
      name: categoryName,
    });
  }

  // uses a backend function to calculate the 'order' value on the new requirement
  public async addRequirement(
    organisationId: string,
    catalogueId: string,
    categoryId: string,
    requirementName: string,
    requirementDescription: string
  ) {
    return this.httpFunctions.addRequirementToCatalogue({
      organisationId,
      catalogueId,
      categoryId,
      requirementName,
      requirementDescription,
    });
  }

  // uses a backend function to re-calculate the 'order' value on the remaining requirements
  public async deleteRequirement(
    organisationId: string,
    catalogueId: string,
    categoryId: string,
    requirementId: string
  ) {
    return this.httpFunctions.deleteRequirementFromCatalogue({
      organisationId,
      catalogueId,
      categoryId,
      requirementId,
    });
  }

  public async editRequirement(
    organisationId: string,
    catalogueId: string,
    categoryId: string,
    requirementId: string,
    requirementName: string,
    requirementDescription: string
  ) {
    const documentReference = this.firestoreService.getCatalogueRequirementDocument(
      organisationId,
      catalogueId,
      categoryId,
      requirementId
    );
    return this.firestoreService.updateDocument(documentReference, {
      name: requirementName,
      description: requirementDescription,
    });
  }

  private async getCategories(catalogueDocument: DocumentReference): Promise<Record<string, CategoryCatalogueType>> {
    const categoriesCollection = collection(catalogueDocument, "categories");
    const categoriesSnapshot = await this.firestoreService.getDocs(query(categoriesCollection, orderBy("order")));

    const categories = await Promise.all(
      categoriesSnapshot.docs.map(async (category) => {
        const requirements = await this.getRequirements(category.ref);

        return {
          ...category.data(),
          requirements: requirements.reduce(
            (map, requirement) => ({ ...map, [requirement.requirementId]: requirement }),
            {}
          ),
        } as CategoryType;
      })
    );

    return categories.reduce((map, category) => ({ ...map, [category.categoryId]: category }), {});
  }

  private async getRequirements(categoryDocument: DocumentReference): Promise<RequirementType[]> {
    const requirementsCollection = collection(categoryDocument, "requirements");

    // NEED TO RUN A MIGRATION TO SET AN ORDER ON ALL CATALOGUES AND REQUIREMENTS DOCUMENTS
    // ALSO NEED TO MAKE SURE ANY NEW REQUIREMENTS ARE ADDED WITH AN ORDER SET

    const requirementsSnapshot = await this.firestoreService.getDocs(query(requirementsCollection, orderBy("order")));
    return requirementsSnapshot.docs.map((requirementDocSnap) => requirementDocSnap.data() as RequirementType);
  }
}

const useCatalogueService = (): CatalogueService => {
  const firestoreService = useFirestoreService();
  const httpFunctions = useHttpFunctions();
  return useMemo(() => new CatalogueService(firestoreService, httpFunctions), [firestoreService, httpFunctions]);
};

export default useCatalogueService;
