import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";

const TaxyTooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <Tooltip enterDelay={500} enterNextDelay={500} arrow {...rest}>
      {children}
    </Tooltip>
  );
};

export default TaxyTooltip;
