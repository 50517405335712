import { CatalogueType, CategoryCatalogueType } from "../types";

const findCategory = (
  categoryId: string,
  node: CatalogueType | CategoryCatalogueType,
  ancestors: Array<string> = []
): [category?: CategoryCatalogueType, ancestors?: Array<string>, parent?: CatalogueType | CategoryCatalogueType] => {
  if (!node.categories) {
    return [];
  }
  if (categoryId in node.categories) {
    return [node.categories[categoryId], ancestors, node];
  }
  return (
    Object.values(node.categories)
      .map((category) => findCategory(categoryId, category, [...ancestors, category.categoryId]))
      .find((result) => result[0] !== undefined) || []
  );
};

export default findCategory;
