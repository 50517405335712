import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

type SubmitButtonProps = {
  loading: boolean;
  label: string;
  disabled?: boolean;
};

const SubmitButton = ({ label, loading, disabled = false }: SubmitButtonProps) => {
  return (
    <Box position="relative" sx={{ ml: 1 }}>
      <Button disabled={loading || disabled} type="submit" color="primary" variant="contained">
        {label}
      </Button>

      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};

export default SubmitButton;
