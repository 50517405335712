import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Chart } from "chart.js/auto";
import { groupBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import theme from "../../../theme";
import { EntityWithOrganisationType } from "../../../types";
import convertServerTimestamp from "../../../utilities/convertServerTimestamp";

type ChartData = {
  year: string;
  month: string;
  newEntities: number;
  growth: number;
  totalEntities: number;
};

type EntitiesGrowthChartProps = {
  entities: EntityWithOrganisationType[];
};

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const EntitiesGrowthChart = ({ entities }: EntitiesGrowthChartProps) => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const [selectedYear, setSelectedYear] = useState<string>(new Date().getFullYear().toString());

  const handleYearChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value);
  };

  // Month on month growth formula
  const getMonthlyGrowth = (previousMonth: number, currentMonth: number) =>
    ((currentMonth - previousMonth) / (previousMonth || 1)) * 100;

  const chartData = useMemo<ChartData[]>(() => {
    // Convert each entity's timestamp and add year/month properties
    const entitiesWithDate = entities.map((entity) => {
      const timestamp = convertServerTimestamp(entity.createdTimestamp);
      const date = new Date(timestamp);
      return {
        ...entity,
        year: date.getFullYear().toString(),
        month: (date.getMonth() + 1).toString(),
      };
    });

    // Group entities by year and month
    const groupedByYear = groupBy(entitiesWithDate, "year");
    const data: ChartData[] = [];

    Object.keys(groupedByYear).forEach((year) => {
      const groupedByMonth = groupBy(groupedByYear[year], "month");
      let totalEntities = 0;

      Object.keys(groupedByMonth).forEach((month) => {
        const monthData = groupedByMonth[month]!;
        const newEntities = monthData.length;
        totalEntities += newEntities;

        const previousMonth = data[data.length - 1];
        const growth = previousMonth ? getMonthlyGrowth(previousMonth.totalEntities, totalEntities) : 0;

        data.push({
          year,
          month,
          newEntities,
          growth,
          totalEntities,
        });
      });
    });

    return data;
  }, [entities]);

  const recentGrowth = useMemo(() => {
    const currentYearData = chartData.filter((item) => item.year === selectedYear);
    if (currentYearData.length < 2) return { text: "", value: 0 };

    const previousMonth = currentYearData[currentYearData.length - 2]!;
    const currentMonth = currentYearData[currentYearData.length - 1]!;

    const growth = getMonthlyGrowth(previousMonth.totalEntities, currentMonth.totalEntities);
    const growthText = `Growth from ${monthNames[parseInt(previousMonth.month) - 1]} to ${
      monthNames[parseInt(currentMonth.month) - 1]
    }: ${growth.toFixed(2)}%`;

    return { text: growthText, value: growth };
  }, [chartData, selectedYear]);

  const uniqueYears = useMemo(() => Array.from(new Set(chartData.map(({ year }) => year))), [chartData]);

  useEffect(() => {
    const ctx = chartRef.current?.getContext("2d");
    if (!ctx) return;

    const filteredData = chartData.filter((item) => item.year === selectedYear);
    const labels = filteredData.map((item) => monthNames[parseInt(item.month, 10) - 1]);
    const totalEntitiesData = filteredData.map((item) => item.totalEntities);
    const newEntitiesData = filteredData.map((item) => item.newEntities);
    const growthData = filteredData.map((item) => item.growth);

    const chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            type: "line",
            label: "Growth (%)",
            data: growthData,
            yAxisID: "yGrowth",
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
          {
            type: "bar",
            label: "New entities",
            data: newEntitiesData,
            yAxisID: "yTotalEntities",
            backgroundColor: theme.palette.neutral.main,
            borderColor: theme.palette.neutral.main,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
          {
            type: "bar",
            label: "Total entities",
            data: totalEntitiesData,
            yAxisID: "yTotalEntities",
            backgroundColor: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            barPercentage: 0.5,
            categoryPercentage: 0.5,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          yTotalEntities: {
            type: "linear",
            position: "right",
            title: {
              display: true,
              text: "Total entities",
            },
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              stepSize: 5,
            },
          },
          yGrowth: {
            type: "linear",
            position: "left",
            title: {
              display: true,
              text: "Growth per month (%)",
            },
            grid: {
              drawOnChartArea: false,
            },
            ticks: {
              stepSize: 25,
            },
          },
        },
        plugins: {
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [chartData, selectedYear]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="h6">Growth per month</Typography>
            <Typography variant="subtitle1" display="flex" alignItems="center" gap={0.5}>
              {recentGrowth.text}
              {recentGrowth.value >= 0 ? <TrendingUpIcon color="success" /> : <TrendingDownIcon color="error" />}
            </Typography>
          </Box>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel id="year-select-label">Select Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={selectedYear}
              onChange={handleYearChange}
              label="Select Year"
            >
              {uniqueYears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mt={2}>
          <canvas ref={chartRef}></canvas>
        </Box>
      </Grid>
    </Grid>
  );
};

export default EntitiesGrowthChart;
