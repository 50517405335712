import { useMemo } from "react";
import { FirebaseStorage, getStorage, ref, StorageReference } from "firebase/storage";

export class FileStorageService {
  constructor(readonly storage: FirebaseStorage) {}

  public getUserUploadedFileRef(filename: string) {
    return ref(this.getUserUploadedFilesBucket(), filename);
  }

  private getUserUploadedFilesBucket(): StorageReference {
    return ref(this.storage, "useruploadedfiles");
  }
}

const useFileStorageService = () => {
  const storage = getStorage();
  return useMemo(() => new FileStorageService(storage), [storage]);
};

export default useFileStorageService;
