import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { getFunctions, httpsCallable } from "firebase/functions";
import CatalogueTree from "../CatalogueTree";
import {
  CatalogueType,
  CategoryCatalogueType,
  FirebaseFunctionResultType,
  RequirementCatalogueType as RequirementType,
} from "../../types";
import findCategory from "../../utilities/findCategory";
import { getApp } from "firebase/app";
import useCatalogueService from "../../hooks/CatalogueService";
import "./Catalogue.css";
import AddCatalogueCategoryDialog from "./AddCatalogueCategoryDialog";
import AddCatalogueRequirementDialog from "./AddCatalogueRequirementDialog";
import DeleteCatalogueRequirementDialog from "./DeleteCatalogueRequirementDialog";
import DeleteCatalogueCategoryDialog from "./DeleteCatalogueCategoryDialog";
import EditCatalogueCategoryDialog from "./EditCatalogueCategoryDialog";
import { useParams } from "react-router-dom";
import EditCatalogueRequirementDialog from "./EditCatalogueRequirementDialog";

const pathToAncestors = (organisationId: string, catalogueId: string, ancestors: Array<string>) =>
  `/organisations/${organisationId}/catalogues/${catalogueId}${ancestors
    ?.map((categoryId) => `/categories/${categoryId}`)
    .reduce((str, part) => `${str}${part}`, "")}`;

type CatalogueParams = {
  organisationId: string;
  catalogueId: string;
};

const Catalogue = () => {
  const { organisationId, catalogueId } = useParams<CatalogueParams>() as CatalogueParams;
  const catalogueService = useCatalogueService();

  const [catalogue, setCatalogue] = useState<CatalogueType>();
  const [selectedCategory, setSelectedCategory] = useState<CategoryCatalogueType>();
  const [selectedRequirement, setSelectedRequirement] = useState<RequirementType>();
  const [isAllExpanded, setIsAllExpanded] = useState({ expanded: false });
  const [categoryToRemove, setCategoryToRemove] = useState<{
    catalogue: CatalogueType;
    category: CategoryCatalogueType;
  }>();

  const [addCatalogueCategoryDialogOpen, setAddCatalogueCategoryDialogOpen] = useState<boolean>(false);
  const [deleteRequirementDialogOpen, setDeleteRequirementDialogOpen] = useState<boolean>(false);
  const [deleteCategoryDialogOpen, setDeleteCategoryDialogOpen] = useState<boolean>(false);
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState(false);
  const [editRequirementDialogOpen, setEditRequirementDialogOpen] = useState(false);
  const [addRequirementDialogOpen, setAddRequirementDialogOpen] = useState(false);

  const [requirementToRemove, setRequirementToRemove] = useState<{
    catalogue: CatalogueType;
    category: CategoryCatalogueType;
    requirement: RequirementType;
  }>();

  const loadCatalogue = useCallback(async () => {
    const results = await catalogueService.getCatalogue(organisationId, catalogueId);
    setCatalogue(results);
  }, [catalogueId, organisationId, catalogueService]);

  useEffect(() => {
    loadCatalogue();
  }, [loadCatalogue]);

  const addCategory = () => {
    setAddCatalogueCategoryDialogOpen(true);
  };

  const addCatalogueCategoryClose = (categoryAdded: boolean) => {
    setAddCatalogueCategoryDialogOpen(false);
    if (categoryAdded) {
      loadCatalogue();
    }
  };

  const editCategory = (category?: CategoryCatalogueType) => {
    setSelectedCategory(category);
    setEditCategoryDialogOpen(true);
  };

  const editCategoryClose = (categoryEdited: boolean) => {
    setEditCategoryDialogOpen(false);
    if (categoryEdited) {
      loadCatalogue();
    }
  };

  const editRequirement = (category: CategoryCatalogueType, requirement: RequirementType) => {
    setSelectedRequirement(requirement);
    setSelectedCategory(category);
    setEditRequirementDialogOpen(true);
  };

  const editRequirementClose = (requirementEdited: boolean) => {
    setEditRequirementDialogOpen(false);
    if (requirementEdited) {
      loadCatalogue();
    }
  };

  const removeCategory = (catalogue: CatalogueType, category: CategoryCatalogueType) => {
    setCategoryToRemove({ catalogue, category });
    setDeleteCategoryDialogOpen(true);
  };

  const removeCategoryClose = (categoryDeleted: boolean) => {
    setCategoryToRemove(undefined);
    setDeleteCategoryDialogOpen(false);
    if (categoryDeleted) {
      loadCatalogue();
    }
  };

  const addRequirement = (parentCategory: CategoryCatalogueType) => {
    setSelectedCategory(parentCategory);
    setAddRequirementDialogOpen(true);
  };

  const addRequirementClose = (requirementAdded: boolean) => {
    setAddRequirementDialogOpen(false);
    if (requirementAdded) {
      loadCatalogue();
    }
  };

  const removeRequirement = (
    catalogue: CatalogueType,
    category: CategoryCatalogueType,
    requirement: RequirementType
  ) => {
    setRequirementToRemove({ catalogue, category, requirement });
    setDeleteRequirementDialogOpen(true);
  };

  const removeRequirementClose = (requirementDeleted: boolean) => {
    setRequirementToRemove(undefined);
    setDeleteRequirementDialogOpen(false);
    if (requirementDeleted) {
      loadCatalogue();
    }
  };

  const [showError, setShowError] = useState<{ title: string; error: string; message: string; button?: string }>();
  const showErrorClose = () => {
    setShowError(undefined);
  };

  const orderRequirement = (
    category: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetRequirement: RequirementType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => {
    const order = async (categoryId: string, sourceRequirementId: string, targetRequirementId: string) => {
      if (!catalogue) {
        return;
      }
      const catalogueId = catalogue.catalogueId;
      // the category might be a nested one, look for it
      const [category, ancestors] = findCategory(categoryId, catalogue);
      if (!(category && ancestors)) return;

      if (category.requirements) {
        // local state updates
        const originalRequirements = JSON.stringify(category.requirements);
        const requirements = Object.values(category.requirements).sort((a, b) =>
          a.order !== undefined && b.order !== undefined
            ? a.order === b.order
              ? 0
              : a.order < b.order
              ? -1
              : 1
            : a.createdTimestamp === b.createdTimestamp
            ? 0
            : a.createdTimestamp < b.createdTimestamp
            ? -1
            : 1
        );
        const sourceIndex = requirements.findIndex((r) => r.requirementId === sourceRequirementId);
        let targetIndex = requirements.findIndex((r) => r.requirementId === targetRequirementId);
        if (moveBefore) {
          targetIndex -= 1;
        } else if (moveAfter) {
          targetIndex += 1;
        }
        requirements.splice(sourceIndex, 1);
        requirements.splice(targetIndex, 0, sourceRequirement);
        requirements.forEach((requirement, i) => {
          requirement.order = i;
        });
        setCatalogue({ ...catalogue });

        // db updates
        const functions = getFunctions(getApp(), "australia-southeast1");
        const path = [
          pathToAncestors(organisationId, catalogueId, ancestors),
          "categories",
          categoryId,
          "requirements",
        ];
        const { data } = await httpsCallable<unknown, FirebaseFunctionResultType>(
          functions,
          "orderRequirement"
        )({ organisationId, path, sourceRequirementId, targetRequirementId, moveBefore, moveAfter });
        if (!data.success) {
          console.error(data);
          setShowError({
            title: "Error saving new order",
            message: "Everything is back where it started",
            error: data.error || "Unknown Error",
          });
          category.requirements = JSON.parse(originalRequirements);
          setCatalogue({ ...catalogue });
        }
      }
    };
    order(category.categoryId, sourceRequirement.requirementId, targetRequirement.requirementId);
  };

  const moveRequirement = (
    sourceCategory: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetCategory: CategoryCatalogueType,
    targetRequirement?: RequirementType,
    moveAfter?: boolean
  ) => {
    const move = async (
      sourceCategoryId: string,
      sourceRequirementId: string,
      targetCategoryId: string,
      targetRequirementId?: string,
      moveAfter?: boolean
    ) => {
      if (!catalogue) {
        return;
      }
      const catalogueId = catalogue.catalogueId;
      // the category might be a nested one, look for it
      const [sourceCategory, sourceAncestors] = findCategory(sourceCategoryId, catalogue);
      const [targetCategory, targetAncestors] = findCategory(targetCategoryId, catalogue);
      if (!(sourceCategory && sourceAncestors && targetCategory && targetAncestors)) return;

      if (sourceCategory.requirements && targetCategory.requirements) {
        // local state updates
        const originalSourceRequirements = JSON.stringify(sourceCategory.requirements);
        const originalTargetRequirements = JSON.stringify(targetCategory.requirements);
        // remove from source
        delete sourceCategory.requirements[sourceRequirementId];
        // insert in target
        const requirements = Object.values(targetCategory.requirements).sort((a, b) =>
          a.order !== undefined && b.order !== undefined
            ? a.order === b.order
              ? 0
              : a.order < b.order
              ? -1
              : 1
            : a.createdTimestamp === b.createdTimestamp
            ? 0
            : a.createdTimestamp < b.createdTimestamp
            ? -1
            : 1
        );
        if (!targetRequirement) {
          requirements.push(sourceRequirement);
        } else {
          let targetIndex = requirements.findIndex((r) => r.requirementId === targetRequirementId);
          if (moveAfter) {
            targetIndex += 1;
          }
          requirements.splice(targetIndex, 0, sourceRequirement);
        }
        requirements.forEach((requirement, i) => {
          requirement.order = i;
        });
        targetCategory.requirements = requirements.reduce((map, req) => ({ ...map, [req.requirementId]: req }), {});
        setCatalogue({ ...catalogue });

        // db updates
        const functions = getFunctions(getApp(), "australia-southeast1");
        const sourcePath = [
          pathToAncestors(organisationId, catalogueId, sourceAncestors),
          "categories",
          sourceCategoryId,
          "requirements",
        ];
        const targetPath = [
          pathToAncestors(organisationId, catalogueId, targetAncestors),
          "categories",
          targetCategoryId,
          "requirements",
        ];
        const { data } = await httpsCallable<unknown, FirebaseFunctionResultType>(
          functions,
          "moveRequirement"
        )({ organisationId, sourcePath, sourceRequirementId, targetPath, targetRequirementId, moveAfter });
        if (!data.success) {
          console.error(data);
          setShowError({
            title: "Error saving new order",
            message: "Everything is back where it started",
            error: data.error || "Unknown Error",
          });
          sourceCategory.requirements = JSON.parse(originalSourceRequirements);
          targetCategory.requirements = JSON.parse(originalTargetRequirements);
          setCatalogue({ ...catalogue });
        }
      }
    };
    move(
      sourceCategory.categoryId,
      sourceRequirement.requirementId,
      targetCategory.categoryId,
      targetRequirement?.requirementId,
      moveAfter
    );
  };

  const orderCategory = (
    catalogue: CatalogueType,
    sourceCategory: CategoryCatalogueType,
    targetCategory: CategoryCatalogueType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => {
    const order = async (sourceCategoryId: string, targetCategoryId: string) => {
      if (!catalogue) {
        return;
      }
      const catalogueId = catalogue.catalogueId;
      // the category might be a nested one, look for it
      const [, ancestors, parent] = findCategory(sourceCategoryId, catalogue);
      if (!(ancestors && parent)) return;
      const path = [pathToAncestors(organisationId, catalogueId, ancestors), "categories"];

      if (parent.categories) {
        // local state updates
        const originalCategories = JSON.stringify(parent.categories);
        const categoriesArr = Object.values(parent.categories).sort((a, b) =>
          a.order !== undefined && b.order !== undefined
            ? a.order === b.order
              ? 0
              : a.order < b.order
              ? -1
              : 1
            : a.createdTimestamp === b.createdTimestamp
            ? 0
            : a.createdTimestamp < b.createdTimestamp
            ? -1
            : 1
        );
        const sourceIndex = categoriesArr.findIndex((r) => r.categoryId === sourceCategoryId);
        const targetIndex = categoriesArr.findIndex((r) => r.categoryId === targetCategoryId);
        categoriesArr.splice(sourceIndex, 1);
        categoriesArr.splice(targetIndex, 0, sourceCategory);
        categoriesArr.forEach((category, i) => {
          category.order = i;
        });
        setCatalogue({ ...catalogue });

        // db updates
        const functions = getFunctions(getApp(), "australia-southeast1");
        const { data } = await httpsCallable<unknown, FirebaseFunctionResultType>(
          functions,
          "orderCategory"
        )({ organisationId, path, sourceCategoryId, targetCategoryId });
        if (!data.success) {
          console.error(data);
          setShowError({
            title: "Error saving new order",
            message: "Everything is back where it started",
            error: data.error || "Unknown Error",
          });
          parent.categories = JSON.parse(originalCategories);
          setCatalogue({ ...catalogue });
        }
      }
    };
    order(sourceCategory.categoryId, targetCategory.categoryId);
  };

  //	Used to offset the height of the floating search box
  const [searchRefHeight, setSearchRefHeight] = useState<number>(56);
  const searchRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const el = searchRef.current;
    if (el) {
      setSearchRefHeight(el.clientHeight || 56);
    }
  }, [searchRefHeight, searchRef]);

  return (
    <Box component="section" className="Catalogue-container">
      <Box component="section" className="Catalogue-box">
        {catalogue && (
          <>
            <Box component="section" className="Catalogue-entities">
              <Box component="div" sx={{ mb: 1 }}>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddBoxIcon />}
                  onClick={() => {
                    setIsAllExpanded({ expanded: true });
                  }}
                >
                  Expand all
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<IndeterminateCheckBoxIcon />}
                  sx={{ ml: 1 }}
                  onClick={() => {
                    setIsAllExpanded({ expanded: false });
                  }}
                >
                  Collapse all
                </Button>
              </Box>

              <CatalogueTree
                catalogue={catalogue}
                orderCategory={orderCategory}
                requirementSelect={setSelectedRequirement}
                selectedRequirementId={selectedRequirement?.requirementId}
                categorySelect={setSelectedCategory}
                selectedCategoryId={selectedCategory?.categoryId}
                isAllExpanded={isAllExpanded}
                addCategory={addCategory}
                editCategory={editCategory}
                editRequirement={editRequirement}
                addRequirement={addRequirement}
                removeCategory={removeCategory}
                removeRequirement={removeRequirement}
                orderRequirement={orderRequirement}
                moveRequirement={moveRequirement}
              />
            </Box>
          </>
        )}
      </Box>

      <AddCatalogueCategoryDialog
        organisationId={organisationId}
        catalogueId={catalogueId}
        open={addCatalogueCategoryDialogOpen}
        onClose={addCatalogueCategoryClose}
      />

      {selectedCategory && (
        <EditCatalogueCategoryDialog
          organisationId={organisationId}
          catalogueId={catalogueId}
          category={selectedCategory}
          open={editCategoryDialogOpen}
          onClose={editCategoryClose}
        />
      )}

      {selectedCategory && selectedRequirement && (
        <EditCatalogueRequirementDialog
          organisationId={organisationId}
          catalogueId={catalogueId}
          categoryId={selectedCategory.categoryId}
          requirement={selectedRequirement}
          open={editRequirementDialogOpen}
          onClose={editRequirementClose}
        />
      )}

      {selectedCategory && (
        <AddCatalogueRequirementDialog
          organisationId={organisationId}
          catalogueId={catalogueId}
          categoryId={selectedCategory.categoryId}
          open={addRequirementDialogOpen}
          onClose={addRequirementClose}
        />
      )}

      {requirementToRemove && (
        <DeleteCatalogueRequirementDialog
          organisationId={organisationId}
          catalogueId={catalogueId}
          categoryId={requirementToRemove.category.categoryId}
          requirement={requirementToRemove.requirement}
          open={deleteRequirementDialogOpen}
          onClose={removeRequirementClose}
        />
      )}

      {categoryToRemove && (
        <DeleteCatalogueCategoryDialog
          organisationId={organisationId}
          catalogueId={catalogueId}
          category={categoryToRemove.category}
          open={deleteCategoryDialogOpen}
          onClose={removeCategoryClose}
        />
      )}

      {!!showError && (
        <Dialog open={!!showError} onClose={showErrorClose} disableRestoreFocus>
          <DialogTitle>{showError.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{showError.error}</DialogContentText>
            <DialogContentText>{showError.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={showErrorClose} type="submit" variant="contained">
              {showError.button || "Okay"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default Catalogue;
