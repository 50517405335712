import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import Box from "@mui/material/Box";
import { TableRowProps } from "@mui/material/TableRow/TableRow";
import { ReactNode } from "react";
import TaxyTooltip from "../components/TaxyTooltip";
import { DragType, SelectionModeType } from "../types";

type SortableTableRowProps = TableRowProps & {
  id: string;
  children: ReactNode;
  enabled?: boolean;
  selectionMode?: SelectionModeType;
  checked?: boolean;
  onCheck?: () => void;
};

const SortableTableRow = ({
  id,
  enabled = true,
  selectionMode = "dnd",
  checked,
  onCheck,
  children,
  ...tableRowProps
}: SortableTableRowProps) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
    data: {
      type: DragType.SORTABLE,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TableRow
      ref={setNodeRef}
      {...attributes}
      style={style}
      sx={{
        opacity: isDragging ? 0.5 : 1,
      }}
      {...tableRowProps}
    >
      <TableCell sx={{ p: 0 }}>
        {enabled && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {selectionMode === "dnd" ? (
              <TaxyTooltip title="Hold to drag and re-order">
                <DragIndicatorIcon {...listeners} sx={{ cursor: "pointer", color: "grey.500", mr: 0.5 }} />
              </TaxyTooltip>
            ) : (
              <TaxyTooltip title="Click to select item">
                <Checkbox
                  color="default"
                  checked={checked}
                  sx={{ padding: 0, mr: 0.5 }}
                  onChange={onCheck}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </TaxyTooltip>
            )}
          </Box>
        )}
      </TableCell>
      {children}
    </TableRow>
  );
};

export default SortableTableRow;
