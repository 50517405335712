import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { isEmpty, map } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import usePeriodService from "../../../hooks/PeriodService";
import { PeriodType } from "../../../types";
import useClientService from "../../../hooks/ClientService";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import useHttpFunctions from "../../../hooks/HttpFunctions";
import SubmitButton from "../../button/SubmitButton";
import FormHelperText from "@mui/material/FormHelperText";

interface AddPeriodDialogProps {
  organisationId: string;
  clientId: string;
  existingPeriods: PeriodType[];
  onClose: (newPeriod?: PeriodType) => void;
}

const formSchema = yup.object({
  name: yup.string().required(),
  makeActive: yup.boolean().required(),
  copyExisting: yup.boolean().required(),
  copyFromPeriodId: yup.string().when("copyExisting", ([copyExisting]) => {
    return copyExisting ? yup.string().required("A period to copy from is required") : yup.string().notRequired();
  }),
});

type FormType = yup.InferType<typeof formSchema>;

const AddPeriodDialog = ({ organisationId, clientId, existingPeriods, onClose }: AddPeriodDialogProps) => {
  const periodService = usePeriodService();
  const clientService = useClientService();
  const httpFunctions = useHttpFunctions();
  const existingPeriodNames = map(existingPeriods, (existingPeriod) => existingPeriod.name);
  const availablePeriods = periodService.getAvailablePeriods(existingPeriodNames);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: availablePeriods[0] || "",
      copyExisting: false,
      copyFromPeriodId: existingPeriods[0]?.periodId || "",
    },
  });

  const onSubmit = async (data: FormType) => {
    const { name, copyFromPeriodId, makeActive, copyExisting } = data;
    if (copyExisting && copyFromPeriodId) {
      const response = await httpFunctions.copyPeriod({
        organisationId,
        clientId,
        name,
        copyFromPeriodId,
        makeActive,
      });
      const { periodId } = response.data;
      const newPeriod = periodId ? await clientService.getPeriod(organisationId, clientId, periodId) : undefined;
      handleClose(newPeriod);
    } else {
      const newPeriod = await clientService.createPeriod(organisationId, clientId, name, makeActive);
      handleClose(newPeriod);
    }
  };

  const handleClose = (newPeriod?: PeriodType) => {
    onClose(newPeriod);
  };

  const copyExisting = watch("copyExisting");

  return (
    <Dialog open={true} onClose={() => handleClose()} disableRestoreFocus fullWidth>
      <DialogTitle>Add period</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ py: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {isEmpty(availablePeriods) ? (
                "No periods available"
              ) : (
                <TextField
                  id="name"
                  label="Period name"
                  select
                  fullWidth
                  defaultValue={availablePeriods[0] || ""}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  {...register("name")}
                >
                  {availablePeriods.map((period) => (
                    <MenuItem key={period} value={period}>
                      {period}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel label="Set as active period" control={<Checkbox {...register("makeActive")} />} />
              <FormHelperText>If selected, this period will become the active period for this client.</FormHelperText>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormControlLabel
                label="Copy entities, requirements etc from an existing period"
                control={<Checkbox {...register("copyExisting")} />}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="copyFrom"
                label="Copy from"
                select
                fullWidth
                disabled={!copyExisting}
                defaultValue={existingPeriods[0]?.periodId || ""}
                error={!!errors.copyFromPeriodId}
                helperText={errors.copyFromPeriodId?.message}
                {...register("copyFromPeriodId")}
              >
                {existingPeriods.map((period) => (
                  <MenuItem key={period.periodId} value={period.periodId}>
                    {period.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Add" disabled={isEmpty(availablePeriods)} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddPeriodDialog;
