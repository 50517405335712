import { ReactElement } from "react";
import Box from "@mui/material/Box";
import { useApplicationState } from "../hooks/ApplicationState";
import TaxyTooltip from "./TaxyTooltip";

type CopyToClipboardProps = {
  textToCopy: string;
  children: ReactElement;
};

const CopyToClipboard = ({ textToCopy, children }: CopyToClipboardProps) => {
  const { setSystemMessage } = useApplicationState();

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(textToCopy);
    setSystemMessage("Copied to clipboard");
  };

  return (
    <Box sx={{ cursor: "pointer", "&:hover": { opacity: 0.75 } }} onClick={copyToClipboard}>
      <TaxyTooltip title="Click to copy">{children}</TaxyTooltip>
    </Box>
  );
};

export default CopyToClipboard;
