import React from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import GuidedTour, { GuidedTourStep } from "./GuidedTour";
import Box from "@mui/material/Box";
import { RequirementStatusEnumClientDisplay, RequirementStatusEnumColour } from "../../enums/RequirementStatus";
import { useTheme } from "@mui/material/styles";
import { RequirementStatusType } from "../../types";

const StyledStatusLabel = ({ status }: { status: RequirementStatusType }) => {
  const theme = useTheme();
  return (
    <Box component="span" sx={{ fontWeight: 500, color: theme.palette[RequirementStatusEnumColour[status]].main }}>
      {RequirementStatusEnumClientDisplay[status]}
    </Box>
  );
};

const steps: GuidedTourStep[] = [
  {
    target: ".clientPage .clientName",
    title: "Client page",
    content: "Welcome to the client page. Here's a quick tour of some key features to get you started.",
    placement: "right-start",
  },
  {
    target: ".clientPage .period",
    title: "Period",
    content:
      "Choose which financial year you need to work on. Past years are shown here if you used Taxy in those years.",
    placement: "right-start",
  },
  {
    target: ".clientPage .activePeriod",
    title: "Active period",
    content: "The active period is the financial year for the tax return your accountant is currently working on.",
    placement: "right-start",
  },
  {
    target: ".clientPage .entity",
    title: "Entity",
    content: "An entity is the individual, company or trust you are submitting a tax return for.",
    placement: "right-start",
  },
  {
    target: ".clientPage .categories",
    title: "Categories",
    content:
      "Your accountant needs information from you to submit your tax return. This is split into the categories you see here which can vary according to the type of entity.",
    placement: "right-start",
  },
  {
    target: ".clientPage .requirements",
    title: "Requirements",
    content:
      "Each category has a number of questions or requirements for which you need to provide an answer or other types of information (receipts, invoices etc).",
    placement: "left-start",
  },
  {
    target: ".clientPage .requirementStatus",
    title: "Requirement status",
    content: (
      <Box>
        <p>The status tells you what needs to be done with each requirement.</p>
        <p>
          <StyledStatusLabel status="WITH_CLIENT" /> means your accountant is waiting for you to provide some
          information.
        </p>
        <p>
          <StyledStatusLabel status="WITH_ACCOUNTANT" /> means your accountant is reviewing the information you've
          provided.
        </p>
        <p>
          <StyledStatusLabel status="COMPLETE" /> means your accountant doesn't need any more information from you for
          this requirement or question.
        </p>
      </Box>
    ),
    placement: "left-start",
  },
];

const ClientPageTour = () => {
  const { isClient } = useApplicationState();
  return isClient ? <GuidedTour tourId="client-page" steps={steps} /> : null;
};

export default ClientPageTour;
