import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactComponent as IndividualIcon } from "../images/IndividualIcon.svg";
import { ReactComponent as CompanyIcon } from "../images/CompanyIcon.svg";
import { ReactComponent as TrustIcon } from "../images/TrustIcon.svg";
import { CatalogueType } from "../../types";
import useCatalogueService from "../../hooks/CatalogueService";
import { get } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

const ICONS = {
  Individual: <IndividualIcon />,
  Company: <CompanyIcon />,
  Trust: <TrustIcon />,
};

const getIcon = (id: string) => {
  return get(ICONS, id);
};

type CataloguesParams = {
  organisationId: string;
};

const Catalogues = () => {
  const { organisationId } = useParams<CataloguesParams>() as CataloguesParams;
  const navigate = useNavigate();
  const catalogueService = useCatalogueService();
  const [catalogues, setCatalogues] = useState<CatalogueType[]>([]);

  const loadCatalogues = useCallback(async () => {
    const results = await catalogueService.listCatalogues(organisationId);
    setCatalogues(results);
  }, [organisationId, catalogueService]);

  useEffect(() => {
    setCatalogues([]);
    loadCatalogues();
  }, [loadCatalogues]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          p: 2,
        }}
      >
        <Typography variant="h4">Catalogues</Typography>
      </Box>

      <Paper variant="outlined" square sx={{ p: 2 }}>
        <List>
          {catalogues.map((catalogue) => (
            <ListItem disablePadding key={catalogue.catalogueId}>
              <ListItemButton
                onClick={() => {
                  navigate(`/catalogues/${organisationId}/${catalogue.catalogueId}`);
                }}
              >
                <ListItemIcon>{getIcon(catalogue.catalogueId)}</ListItemIcon>
                <ListItemText primary={catalogue.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    </>
  );
};

export default Catalogues;
