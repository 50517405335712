import { Breakpoint } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { ReactElement } from "react";

type ConfirmationDialogProps = {
  open: boolean;
  title: string;
  content: ReactElement;
  onClose: () => void;
  maxWidth?: Breakpoint;
};

const InformationDialog = ({ open, title, content, onClose, maxWidth = "md" }: ConfirmationDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
    </Dialog>
  );
};

export default InformationDialog;
