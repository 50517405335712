import { ForwardedRef, MutableRefObject, useEffect, useRef } from "react";

function useCombinedRefs<T>(...refs: (ForwardedRef<T> | MutableRefObject<T>)[]) {
  const targetRef = useRef<T>();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (targetRef.current) {
        if (typeof ref === "function") {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      }
    });
  }, [refs]);

  return targetRef;
}

export default useCombinedRefs;
