import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { doc, DocumentData, getDoc, getFirestore } from "firebase/firestore";
import { useCallback, useState } from "react";

const AccessRules = () => {
  const db = getFirestore();

  const [documentPath, setDocumentPath] = useState<string>("");
  const [results, setResults] = useState<DocumentData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getDocument = useCallback(
    async (path: string) => {
      setResults(null);
      setError(null);

      try {
        const document = await getDoc(doc(db, path));
        if (document.exists()) {
          setResults(document.data());
        } else {
          setError("Document does not exist");
        }
      } catch (e: any) {
        setError(e.message || "An unknown error occurred");
      }
    },
    [db]
  );

  const handleGetDocument = () => {
    void getDocument(documentPath);
  };

  return (
    <Container maxWidth="md" sx={{ mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ my: 2 }}>
            Access rules test page
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Document path"
            fullWidth
            size="small"
            value={documentPath}
            onChange={(event) => {
              setDocumentPath(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 4 }}>
          <Button variant="outlined" onClick={() => handleGetDocument()}>
            Get document
          </Button>
        </Grid>

        {error && (
          <Grid item xs={12}>
            <Typography variant="body2" color="error.main">
              {error}
            </Typography>
          </Grid>
        )}

        {results && (
          <Grid item xs={12}>
            <pre>{JSON.stringify(results, null, 2)}</pre>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AccessRules;
