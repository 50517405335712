import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useOrganisationService from "../../hooks/OrganisationService";
import { OrganisationType } from "../../types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TeamUsers from "./TeamUsers";
import { includes } from "lodash";
import { useApplicationState } from "../../hooks/ApplicationState";

type ManageTeamParams = {
  organisationId: string;
};

const ManageTeam = () => {
  const { organisationId } = useParams<ManageTeamParams>() as ManageTeamParams;
  const { adminOrganisationIds } = useApplicationState();
  const organisationService = useOrganisationService();

  const [organisation, setOrganisation] = useState<OrganisationType>();

  const loadOrganisation = useCallback(async () => {
    if (includes(adminOrganisationIds, organisationId)) {
      const result = await organisationService.get(organisationId);
      setOrganisation(result);
    }
  }, [organisationId, adminOrganisationIds, organisationService]);

  useEffect(() => {
    void loadOrganisation();
  }, [loadOrganisation]);

  return organisation ? (
    <>
      <Grid
        container
        sx={{
          pt: 2,
          pb: 2,
          pl: 2,
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          minHeight: 140,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" color="primary.main">
            Team
          </Typography>
          <Typography variant="h4">{organisation.name}</Typography>
        </Grid>
      </Grid>

      <TeamUsers organisationId={organisationId} />
    </>
  ) : null;
};

export default ManageTeam;
