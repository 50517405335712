import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import useClientService from "../../hooks/ClientService";
import { ClientType } from "../../types";
import SubmitButton from "../button/SubmitButton";

interface DeleteClientDialogProps {
  client: ClientType;
  hasPeriods: boolean;
  onClose: (clientDeleted: boolean) => void;
}

const DeleteClientDialog = ({ client, hasPeriods, onClose }: DeleteClientDialogProps) => {
  const clientService = useClientService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    await clientService.deleteClient(client.organisationId, client.clientId);
    onClose(true);
  };

  return (
    <Dialog open onClose={() => onClose(false)} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Delete client</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText sx={{ my: 1 }}>
            {hasPeriods ? (
              `Remove all of the periods from this client to delete it`
            ) : (
              <>
                You are about to delete the client:&nbsp;
                <Box component="span" sx={{ fontWeight: "bold" }}>
                  {client.name}
                </Box>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" disabled={hasPeriods} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteClientDialog;
