import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { TableSortLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { includes, join, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useUserService from "../../../hooks/UserService";
import { UserType } from "../../../types";
import UserSearch from "./UserSearch";

type OrganisationUsersProps = {
  organisationId: string;
};

type SortBy = "firstName" | "lastName" | "email";
type SortDirection = "asc" | "desc";

const OrganisationUsers = ({ organisationId }: OrganisationUsersProps) => {
  const userService = useUserService();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [users, setUsers] = useState<UserType[]>([]);

  // users after applying search filters
  const [filteredUsers, setFilteredUsers] = useState<UserType[]>([]);

  // users after applying sort filters
  const [sortedUsers, setSortedUsers] = useState<UserType[]>([]);

  const isAdmin = useCallback(
    (user: UserType) => includes(user.adminOrganisationIds, organisationId),
    [organisationId]
  );

  const listUsers = useCallback(async () => {
    const results = await userService.listAccountantUsers([organisationId]);
    setUsers(results);
  }, [organisationId, userService]);

  useEffect(() => {
    void listUsers();
  }, [listUsers]);

  useEffect(() => {
    if (sortBy && includes(["firstName", "lastName", "email"], sortBy)) {
      setSortedUsers(orderBy(filteredUsers, (user) => user[sortBy], sortDirection));
    } else {
      setSortedUsers(filteredUsers);
    }
  }, [sortBy, sortDirection, filteredUsers]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchResults = (results: UserType[]) => {
    setFilteredUsers(results);
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Organisation users</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <UserSearch users={users} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Users table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortLabel field="firstName" label="First name" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="lastName" label="Last name" />
                  </TableCell>
                  <TableCell>
                    <SortLabel field="email" label="Email" />
                  </TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell>Organisation admin</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* USERS */}
                {sortedUsers.map((user) => (
                  <TableRow key={user.uid}>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{join(user.roles, ", ")}</TableCell>
                    <TableCell>{isAdmin(user) ? <CheckIcon color="success" /> : <CloseIcon color="error" />}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganisationUsers;
