import Box from "@mui/material/Box";
import React, { ReactNode } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";
import { DragType } from "../types";

type DraggableItemProps = {
  id: string;
  children: ReactNode;
  disableTransform: boolean;
};

const DraggableItem = ({ id, children, disableTransform }: DraggableItemProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data: {
      type: DragType.DRAGGABLE,
    },
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        transform: disableTransform ? undefined : CSS.Translate.toString(transform),
      }}
      {...listeners}
      {...attributes}
    >
      {children}
    </Box>
  );
};

export default DraggableItem;
