import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminIcon from "@mui/icons-material/AdminPanelSettings";
import ChecklistIcon from "@mui/icons-material/Checklist";
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MenuIcon from "@mui/icons-material/Menu";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import { size } from "lodash";
import React, { ReactElement, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TaxyLogo from "./components/images/taxy-logo.png";
import TaxyTooltip from "./components/TaxyTooltip";
import { useApplicationState } from "./hooks/ApplicationState";
import Loading from "./Loading";
import ApplicationRoutes from "./routes/ApplicationRoutes";

type MenuButtonProps = {
  label: string;
  icon: ReactElement;
  url: string;
  selected: boolean;
};

const MenuButton = ({ label, icon, url, selected }: MenuButtonProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <TaxyTooltip title={useMediaQuery(theme.breakpoints.down("md")) ? label : ""}>
      <Button
        startIcon={icon}
        color="inherit"
        size="large"
        sx={{
          ml: { sm: 0, md: 3 },
          color: selected ? theme.palette.primary.main : theme.palette.neutral.main,
          textTransform: "none",
        }}
        onClick={() => navigate(url)}
      >
        <Box component="span" sx={{ display: { xs: "none", md: "flex" } }}>
          {label}
        </Box>
      </Button>
    </TaxyTooltip>
  );
};

type NavigationItem = {
  label: string;
  icon: ReactElement;
  url: string;
  selected: boolean;
  condition: boolean;
};

const App = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    app,
    user,
    isAdmin,
    isAccountant,
    isClient,
    isOrganisationAdmin,
    isLoading,
    systemMessage,
    showRefreshMessage,
    setSystemMessage,
    clientIds,
  } = useApplicationState();

  const hideSnackbar = () => {
    setSystemMessage(undefined);
  };

  const isAssignedToMultipleClients = useMemo(() => size(clientIds) > 1, [clientIds]);

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(undefined);
  };

  const handleMenuClick = (url: string) => {
    navigate(url);
    handleMenuClose();
  };

  const menuItems = useMemo<NavigationItem[]>(
    () => [
      {
        label: "Clients",
        icon: <WorkOutlineIcon />,
        url: "/",
        selected: pathname === "/" || pathname.startsWith("/client"),
        condition: isAccountant || isAssignedToMultipleClients,
      },
      {
        label: "Worklist",
        icon: <ChecklistIcon />,
        url: "/worklist",
        selected: pathname.startsWith("/worklist"),
        condition: isAccountant || isClient,
      },
      {
        label: "Catalogues",
        icon: <ListAltIcon />,
        url: "/catalogues",
        selected: pathname.startsWith("/catalogue"),
        condition: isOrganisationAdmin,
      },
      {
        label: "My teams",
        icon: <GroupIcon />,
        url: "/manage",
        selected: pathname.startsWith("/manage"),
        condition: isOrganisationAdmin,
      },
      {
        label: "Admin",
        icon: <AdminIcon />,
        url: "/admin",
        selected: pathname.startsWith("/admin"),
        condition: isAdmin,
      },
    ],
    [pathname, isAccountant, isAdmin, isClient, isOrganisationAdmin, isAssignedToMultipleClients]
  );

  return (
    <Box>
      {user && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" color="secondary">
            <Toolbar variant="dense">
              {/* MENU */}
              <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                  <MenuIcon />
                </IconButton>
                <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={handleMenuClose}>
                  {menuItems.map(({ condition, url, icon, label }) =>
                    condition ? (
                      <MenuItem key={url} onClick={() => handleMenuClick(url)} dense>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{label}</ListItemText>
                      </MenuItem>
                    ) : null
                  )}
                </Menu>
              </Box>

              {/* LOGO */}
              <Link
                onClick={() => navigate("/")}
                sx={{
                  display: { xs: "none", sm: "flex" },
                  justifyItems: "center",
                  img: { maxHeight: "24px", cursor: "pointer" },
                  mr: 4,
                }}
              >
                <img src={TaxyLogo} alt="Taxy logo" />
              </Link>

              {/* NAVIGATION BUTTONS */}
              <Box sx={{ flexGrow: 1, direction: "row", display: { xs: "none", sm: "flex" } }}>
                {menuItems.map(({ condition, label, url, icon, selected }) =>
                  condition ? <MenuButton key={url} label={label} url={url} icon={icon} selected={selected} /> : null
                )}
              </Box>

              {/* MY ACCOUNT */}
              <Box sx={{ textAlign: "right", flexGrow: { xs: 1, md: 0 } }}>
                <TaxyTooltip title="My account">
                  <IconButton
                    size="small"
                    sx={{
                      ml: 3,
                      color: pathname.startsWith("/user") ? theme.palette.primary.main : theme.palette.neutral.main,
                    }}
                    onClick={() => navigate("/user")}
                  >
                    <AccountCircleIcon />
                  </IconButton>
                </TaxyTooltip>
              </Box>
            </Toolbar>

            <Loading loading={isLoading} />
          </AppBar>
        </Box>
      )}

      <Box sx={{ height: "100vh", pt: user ? 6 : 0 }}>
        {/* MAIN CONTENT */}
        <Box component="main" sx={{}}>
          {app && <ApplicationRoutes />}
        </Box>

        {/* SYSTEM MESSAGES */}
        {systemMessage && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={5000}
            open={!!systemMessage}
            onClose={hideSnackbar}
          >
            <Alert
              severity="success"
              onClose={hideSnackbar}
              sx={{ borderStyle: "solid", borderWidth: "1px", borderColor: "success", mt: 6, px: 2, py: 1 }}
            >
              {systemMessage}
            </Alert>
          </Snackbar>
        )}

        {/* NEW VERSION MESSAGE */}
        <Snackbar open={showRefreshMessage} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
          <Alert severity="info">
            Taxy has been updated. Please{" "}
            <Link onClick={() => window.location.reload()} sx={{ cursor: "pointer" }}>
              reload
            </Link>{" "}
            the page to enable the latest features.
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
};

export default App;
