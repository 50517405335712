import Box from "@mui/material/Box";
import React, { ReactNode, useState } from "react";
import { DragStartEvent, useDndMonitor, useDroppable } from "@dnd-kit/core";
import { get } from "lodash";
import { DragType } from "../types";

type DroppableProps = {
  id: string;
  children: ReactNode;
};

const Droppable = ({ id, children }: DroppableProps) => {
  const [isDrag, setIsDrag] = useState<boolean>(false);

  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  useDndMonitor({
    onDragStart(event: DragStartEvent) {
      const type = get(event.active.data.current, "type");
      setIsDrag(type === DragType.DRAGGABLE);
    },
    onDragEnd() {
      setIsDrag(false);
    },
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        color: isDrag && isOver ? "grey.400" : "default",
      }}
    >
      {children}
    </Box>
  );
};

export default Droppable;
