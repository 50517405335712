import Badge from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import { ClientStatusEnumColour, ClientStatusEnumDisplay } from "../../enums/ClientStatus";
import { ClientType } from "../../types";
import { useApplicationState } from "../../hooks/ApplicationState";
import TaxyTooltip from "../TaxyTooltip";

type ClientStatusChipProps = {
  client: ClientType;
};

const ClientStatusChip = ({ client }: ClientStatusChipProps) => {
  const { isAccountant } = useApplicationState();

  const statusChip = (
    <Chip
      size="small"
      sx={{ p: 1, fontWeight: 500 }}
      label={client.status ? ClientStatusEnumDisplay[client.status] : "Unknown"}
      color={ClientStatusEnumColour[client.status || "default"]}
    />
  );

  return isAccountant ? (
    <TaxyTooltip title={client.isUpdated ? "There are recently updated requirements" : ""}>
      <Badge
        color="warning"
        variant="dot"
        sx={{
          my: 0.5,
          ".MuiBadge-dot": {
            display: client.isUpdated ? "inherit" : "none",
          },
        }}
      >
        {statusChip}
      </Badge>
    </TaxyTooltip>
  ) : (
    <>{statusChip} </>
  );
};

export default ClientStatusChip;
