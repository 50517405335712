import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { arrayMove } from "@dnd-kit/sortable";
import AddIcon from "@mui/icons-material/Add";
import ChecklistIcon from "@mui/icons-material/Checklist";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { cloneDeep, find, findIndex, first, get, isEmpty, size, values } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";
import useClientService from "../../hooks/ClientService";
import useEntityService from "../../hooks/EntityService";
import useLocalStorageService from "../../hooks/LocalStorageService";
import {
  CategoryType,
  ClientType,
  DragType,
  EntityType,
  PeriodType,
  RequirementType,
  RequirementWithSubCategoryIdType,
  SelectionModeType,
  ShoeboxItem,
} from "../../types";
import CategoryList from "../CategoryList";
import RequirementDetailsDialog from "../dialogs/RequirementDetailsDialog";
import EntityList from "../EntityList";
import PeriodSelect from "../PeriodSelect";
import TaxyTooltip from "../TaxyTooltip";
import ClientPageTour from "../tour/ClientPageTour";
import ClientHeader from "./ClientHeader";
import AddCategoryDialog from "./dialogs/AddCategoryDialog";
import AddEntityDialog from "./dialogs/AddEntityDialog";
import AddPeriodDialog from "./dialogs/AddPeriodDialog";
import AddRequirementDialog from "./dialogs/AddRequirementDialog";
import DeleteCategoryDialog from "./dialogs/DeleteCategoryDialog";
import DeleteRequirementDialog from "./dialogs/DeleteRequirementDialog";
import DeleteRequirementsDialog from "./dialogs/DeleteRequirementsDialog";
import DeleteSubCategoryDialog from "./dialogs/DeleteSubCategoryDialog";
import EditCategoryDialog from "./dialogs/EditCategoryDialog";
import EditEntityDialog from "./dialogs/EditEntityDialog";
import EditRequirementDialog from "./dialogs/EditRequirementDialog";
import SetActivePeriodDialog from "./dialogs/SetActivePeriodDialog";
import GenerateWorkingPapersButton from "./GenerateWorkingPapersButton";
import RequirementsList from "./RequirementsList";
import Shoebox from "./Shoebox";
import CloseIcon from "@mui/icons-material/Close";

type ClientBodyProps = {
  organisationId: string;
  clientId: string;
};

const ClientBody = ({ organisationId, clientId }: ClientBodyProps) => {
  const { isAccountant } = useApplicationState();
  const navigate = useNavigate();
  const entityService = useEntityService();
  const clientService = useClientService();
  const localStorageService = useLocalStorageService();

  const [client, setClient] = useState<ClientType>();

  const [periods, setPeriods] = useState<PeriodType[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodType>();

  const [entities, setEntities] = useState<EntityType[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<EntityType>();

  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<CategoryType>();

  const [selectedRequirement, setSelectedRequirement] = useState<RequirementType>();
  const [selectedRequirementCategory, setSelectedRequirementCategory] = useState<CategoryType>();
  const [selectedRequirementSubCategory, setSelectedRequirementSubCategory] = useState<CategoryType>();

  const [selectionMode, setSelectionMode] = useState<SelectionModeType>("dnd");
  const [checkedRequirements, setCheckedRequirements] = useState<RequirementWithSubCategoryIdType[]>([]);

  const [requirementDetailsDialogOpen, setRequirementDetailsDialogOpen] = useState<boolean>(false);

  const [shoeboxItems, setShoeboxItems] = useState<ShoeboxItem[]>([]);
  const [shoeboxOpen, setShoeboxOpen] = useState<boolean>(false);
  const [dragType, setDragType] = useState<DragType>();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const loadClient = useCallback(async () => {
    const result = await clientService.getClient(organisationId, clientId);
    setClient(result);

    // not found
    if (!result) {
      navigate("/not-found");
    }
  }, [organisationId, clientId, clientService, navigate]);

  useEffect(() => {
    void loadClient();
  }, [loadClient]);

  const loadPeriods = useCallback(async () => {
    const results = await clientService.getPeriods(organisationId, clientId);
    setPeriods(results);

    const selectedPeriodId = localStorageService.getSelectedPeriodId(clientId);
    const period = find(results, (period) => period.periodId === selectedPeriodId) || first(results);
    setSelectedPeriod(period);
  }, [organisationId, clientId, clientService, localStorageService]);

  useEffect(() => {
    void loadPeriods();
  }, [loadPeriods]);

  const loadEntities = useCallback(async () => {
    if (selectedPeriod?.periodId) {
      const results = await entityService.getEntities(organisationId, clientId, selectedPeriod.periodId);
      setEntities(results);
    }
  }, [organisationId, clientId, selectedPeriod?.periodId, entityService]);

  useEffect(() => {
    void loadEntities();
  }, [loadEntities]);

  useEffect(() => {
    if (selectedEntity?.entityId) {
      localStorageService.setSelectedEntityId(clientId, selectedEntity.entityId);
      const entity = find(entities, (entity) => entity.entityId === selectedEntity.entityId);
      setSelectedEntity(entity);
    } else {
      const selectedEntityId = localStorageService.getSelectedEntityId(clientId);
      const entity = find(entities, (entity) => entity.entityId === selectedEntityId) || first(entities);
      setSelectedEntity(entity);
    }
  }, [clientId, entities, selectedEntity?.entityId, localStorageService]);

  const loadCategories = useCallback(async () => {
    if (selectedPeriod?.periodId && selectedEntity?.entityId) {
      const results = await entityService.getCategories(
        organisationId,
        clientId,
        selectedPeriod.periodId,
        selectedEntity.entityId
      );
      setCategories(results);
    }
  }, [organisationId, clientId, selectedPeriod?.periodId, selectedEntity?.entityId, entityService]);

  useEffect(() => {
    void loadCategories();
  }, [loadCategories]);

  const selectRequirementFromCategory = useCallback((category: CategoryType, requirementId: string) => {
    let requirement = find(category.requirements, { requirementId });
    let requirementSubCategory;
    if (!requirement) {
      for (const subCategory of category.categories) {
        requirement = find(subCategory.requirements, { requirementId });
        if (requirement) {
          requirementSubCategory = subCategory;
          break;
        }
      }
    }
    setSelectedRequirement(requirement);
    setSelectedRequirementCategory(category);
    setSelectedRequirementSubCategory(requirementSubCategory);
  }, []);

  useEffect(() => {
    if (selectedCategory?.categoryId) {
      localStorageService.setSelectedCategoryId(clientId, selectedCategory.categoryId);
      const category = find(categories, (category) => category.categoryId === selectedCategory.categoryId);
      setSelectedCategory(category);

      if (category) {
        if (selectedRequirement?.requirementId) {
          localStorageService.setSelectedRequirementId(clientId, selectedRequirement.requirementId);
          selectRequirementFromCategory(category, selectedRequirement.requirementId);
        } else {
          setCheckedRequirements([]);
          const selectedRequirementId = localStorageService.getSelectedRequirementId(clientId);
          if (selectedRequirementId) {
            selectRequirementFromCategory(category, selectedRequirementId);
          }
        }
      }
    } else {
      const selectedCategoryId = localStorageService.getSelectedCategoryId(clientId);
      const category = find(categories, (category) => category.categoryId === selectedCategoryId) || first(categories);
      setSelectedCategory(category);
      setSelectedRequirement(undefined);
      setSelectedRequirementCategory(undefined);
      setSelectedRequirementSubCategory(undefined);
    }
  }, [
    categories,
    clientId,
    localStorageService,
    selectRequirementFromCategory,
    selectedCategory?.categoryId,
    selectedRequirement?.requirementId,
  ]);

  useEffect(() => {
    if (selectedPeriod?.periodId) {
      return entityService.watchEntities(organisationId, clientId, selectedPeriod?.periodId, () => {
        void loadEntities();
      });
    }
  }, [entityService, loadEntities, organisationId, clientId, selectedPeriod?.periodId]);

  const loadShoeboxItems = useCallback(async () => {
    const results = await clientService.getShoeboxItems(organisationId, clientId);
    setShoeboxItems(results);
    if (isEmpty(results)) {
      setShoeboxOpen(false);
    }
  }, [clientService, organisationId, clientId]);

  useEffect(() => {
    void loadShoeboxItems();
  }, [loadShoeboxItems]);

  const onChangePeriod = async (period: PeriodType) => {
    localStorageService.setSelectedPeriodId(clientId, period.periodId);
    setSelectedPeriod(period);
  };

  const onOpenRequirement = (requirement: RequirementType, category: CategoryType, subCategory?: CategoryType) => {
    setSelectedRequirement(requirement);
    setSelectedRequirementCategory(category);
    setSelectedRequirementSubCategory(subCategory);
    setRequirementDetailsDialogOpen(true);
  };

  const onCheckRequirement = (requirement: RequirementType, subCategoryId?: string): void => {
    setCheckedRequirements((prev) => {
      const exists = prev.some((req) => req.requirementId === requirement.requirementId);
      return exists
        ? prev.filter((req) => req.requirementId !== requirement.requirementId)
        : [...prev, { ...requirement, subCategoryId }];
    });
  };

  // MENUS
  const [menuRequirement, setMenuRequirement] = useState<{
    category: CategoryType;
    subCategory?: CategoryType;
    requirement: RequirementType;
  }>();
  const [anchorElRequirement, setAnchorElRequirement] = useState<HTMLElement>();

  const [menuCategory, setMenuCategory] = useState<CategoryType>();
  const [anchorElCategory, setAnchorElCategory] = useState<HTMLElement>();

  // EDIT REQUIREMENT
  const [editRequirementDialogOpen, setEditRequirementDialogOpen] = useState<boolean>(false);
  const [requirementToEdit, setRequirementToEdit] = useState<{
    category: CategoryType;
    subCategory?: CategoryType;
    requirement: RequirementType;
  }>();

  // ADD PERIOD
  const [addPeriodDialogOpen, setAddPeriodDialogOpen] = useState<boolean>(false);

  const addPeriod = () => {
    setAddPeriodDialogOpen(true);
  };

  const addPeriodClose = async (newPeriod?: PeriodType) => {
    setAddPeriodDialogOpen(false);
    if (newPeriod) {
      await loadPeriods();
      void loadClient();
    }
    if (newPeriod) {
      void onChangePeriod(newPeriod);
    }
  };

  // SET ACTIVE PERIOD
  const [activePeriodDialogOpen, setActivePeriodDialogOpen] = useState<boolean>(false);

  const setActivePeriod = () => {
    setActivePeriodDialogOpen(true);
  };

  const setActivePeriodClose = (periodUpdated: boolean) => {
    setActivePeriodDialogOpen(false);
    if (periodUpdated) {
      void loadClient();
    }
  };

  // ADD ENTITY
  const [addEntityDialogOpen, setAddEntityDialogOpen] = useState<boolean>(false);

  const addEntity = () => {
    setAddEntityDialogOpen(true);
  };

  const addEntityClose = (entityAdded: boolean) => {
    setAddEntityDialogOpen(false);
    if (entityAdded) {
      void loadEntities();
    }
  };

  // EDIT ENTITY
  const [entityToEdit, setEntityToEdit] = useState<EntityType>();
  const [editEntityDialogOpen, setEditEntityDialogOpen] = useState<boolean>(false);

  const editEntity = (entity: EntityType) => {
    setEntityToEdit(entity);
    setEditEntityDialogOpen(true);
  };

  const editEntityClose = (entityEdited: boolean) => {
    setEntityToEdit(undefined);
    setEditEntityDialogOpen(false);
    if (entityEdited) {
      void loadEntities();
    }
  };

  // ADD CATEGORY
  const [addCategoryDialogOpen, setAddCategoryDialogOpen] = useState<boolean>(false);
  const [addCategoryParentCategory, setAddCategoryParentCategory] = useState<CategoryType>();

  const addCategory = (parentCategory?: CategoryType) => {
    setAddCategoryParentCategory(parentCategory);
    setAddCategoryDialogOpen(true);
  };

  const addCategoryClose = (categoryAdded: boolean) => {
    setAddCategoryParentCategory(undefined);
    setAddCategoryDialogOpen(false);
    if (categoryAdded) {
      void loadCategories();
    }
  };

  // EDIT CATEGORY
  const [categoryToEdit, setCategoryToEdit] = useState<{
    category: CategoryType;
    subCategory?: CategoryType;
  }>();
  const [editCategoryDialogOpen, setEditCategoryDialogOpen] = useState<boolean>(false);

  const editCategory = (category: CategoryType, subCategory?: CategoryType) => {
    setCategoryToEdit({ category, subCategory });
    setEditCategoryDialogOpen(true);
  };

  const editCategoryClose = (categoryEdited: boolean) => {
    setCategoryToEdit(undefined);
    setEditCategoryDialogOpen(false);
    if (categoryEdited) {
      void loadCategories();
    }
  };

  // DELETE CATEGORY
  const [categoryToDelete, setCategoryToDelete] = useState<CategoryType>();
  const [deleteCategoryDialogOpen, setDeleteCategoryDialogOpen] = useState<boolean>(false);

  const deleteCategory = (category: CategoryType) => {
    setCategoryToDelete(category);
    setDeleteCategoryDialogOpen(true);
  };

  const deleteCategoryClose = (categoryDeleted: boolean) => {
    setCategoryToDelete(undefined);
    setDeleteCategoryDialogOpen(false);
    if (categoryDeleted) {
      void loadCategories();
    }
  };

  // DELETE SUB-CATEGORY
  const [subCategoryToDelete, setSubCategoryToDelete] = useState<{
    category: CategoryType;
    subCategory: CategoryType;
  }>();
  const [deleteSubCategoryDialogOpen, setDeleteSubCategoryDialogOpen] = useState<boolean>(false);

  const deleteSubCategory = (category: CategoryType, subCategory: CategoryType) => {
    setSubCategoryToDelete({ category, subCategory });
    setDeleteSubCategoryDialogOpen(true);
  };

  const deleteSubCategoryClose = (subCategoryDeleted: boolean) => {
    setDeleteSubCategoryDialogOpen(false);
    setSubCategoryToDelete(undefined);
    if (subCategoryDeleted) {
      void loadCategories();
    }
  };

  // ADD REQUIREMENT
  const [addRequirementDialogOpen, setAddRequirementDialogOpen] = useState<boolean>(false);
  const [addRequirementParent, setAddRequirementParent] = useState<CategoryType>();

  const addRequirement = (category: CategoryType) => {
    setAddRequirementParent(category);
    setAddRequirementDialogOpen(true);
  };

  const addRequirementClose = (requirementAdded: boolean) => {
    setAddRequirementParent(undefined);
    setAddRequirementDialogOpen(false);
    if (requirementAdded) {
      void loadCategories();
    }
  };

  // EDIT REQUIREMENT
  const editRequirement = (
    category: CategoryType,
    subCategory: CategoryType | undefined,
    requirement: RequirementType
  ) => {
    setRequirementToEdit({ category, subCategory, requirement });
    setEditRequirementDialogOpen(true);
  };

  const editRequirementClose = (requirementEdited: boolean) => {
    setRequirementToEdit(undefined);
    setEditRequirementDialogOpen(false);
    if (requirementEdited) {
      void loadCategories();
    }
  };

  // DELETE REQUIREMENT
  const [deleteRequirementDialogOpen, setDeleteRequirementDialogOpen] = useState<boolean>(false);
  const [requirementToDelete, setRequirementToDelete] = useState<{
    category: CategoryType;
    subCategory?: CategoryType;
    requirement: RequirementType;
  }>();

  const deleteRequirement = (
    category: CategoryType,
    subCategory: CategoryType | undefined,
    requirement: RequirementType
  ) => {
    setRequirementToDelete({ category, subCategory, requirement });
    setDeleteRequirementDialogOpen(true);
  };

  const deleteRequirementClose = (requirementDeleted: boolean) => {
    setRequirementToDelete(undefined);
    setDeleteRequirementDialogOpen(false);
    if (requirementDeleted) {
      void loadCategories();
    }
  };

  // DELETE REQUIREMENTS
  const [deleteRequirementsDialogOpen, setDeleteRequirementsDialogOpen] = useState<boolean>(false);
  const [requirementsToDelete, setRequirementsToDelete] = useState<{
    category: CategoryType;
    requirements: RequirementWithSubCategoryIdType[];
  }>();

  const deleteRequirements = (category: CategoryType, requirements: RequirementWithSubCategoryIdType[]) => {
    setRequirementsToDelete({ category, requirements });
    setDeleteRequirementsDialogOpen(true);
  };

  const deleteRequirementsClose = (requirementsDeleted: boolean) => {
    setRequirementsToDelete(undefined);
    setDeleteRequirementsDialogOpen(false);
    if (requirementsDeleted) {
      void loadCategories();
    }
  };

  // reset the selected requirements whenever the selection mode changes
  useEffect(() => {
    if (selectionMode === "dnd") {
      setCheckedRequirements([]);
    }
  }, [selectionMode]);

  // revert back to dnd selection mode on page state updates
  useEffect(() => {
    setSelectionMode("dnd");
  }, [
    selectedCategory,
    selectedRequirement,
    selectedRequirementCategory,
    selectedRequirementSubCategory,
    addPeriodDialogOpen,
    activePeriodDialogOpen,
    addEntityDialogOpen,
    editEntityDialogOpen,
    addCategoryDialogOpen,
    editCategoryDialogOpen,
    deleteCategoryDialogOpen,
    deleteSubCategoryDialogOpen,
    addRequirementDialogOpen,
    editRequirementDialogOpen,
    deleteRequirementDialogOpen,
    shoeboxOpen,
    dragType,
  ]);

  // MOVE A CATEGORY UP/DOWN THE LIST
  const handleCategoryReorder = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && over.id !== active.id) {
      if (selectedPeriod && selectedEntity) {
        const oldIndex = findIndex(categories, { id: active.id as string });
        const newIndex = findIndex(categories, { id: over.id as string });

        // immediately update the UI state to avoid a flicker while the move happens
        setCategories((previousCategories) => {
          return arrayMove(previousCategories, oldIndex, newIndex);
        });

        // do the actual move
        await entityService.moveCategory(
          organisationId,
          clientId,
          selectedPeriod.periodId,
          selectedEntity.entityId,
          oldIndex,
          newIndex
        );

        // trigger a re-load
        void loadCategories();
      }
    }
  };

  // MOVE A REQUIREMENT UP/DOWN THE LIST
  const onRequirementReorder = async (
    categoryId: string,
    subCategoryId: string | undefined,
    oldIndex: number,
    newIndex: number
  ) => {
    if (selectedPeriod && selectedEntity) {
      // immediately update the UI state to avoid a flicker while the move happens
      const newSelectedCategory = cloneDeep(selectedCategory);
      if (newSelectedCategory) {
        if (subCategoryId) {
          const subCategory = find(newSelectedCategory.categories, (category) => category.categoryId === subCategoryId);
          if (subCategory) {
            subCategory.requirements = arrayMove(subCategory.requirements, oldIndex, newIndex);
          }
        } else {
          newSelectedCategory.requirements = arrayMove(newSelectedCategory.requirements, oldIndex, newIndex);
        }
        setSelectedCategory(newSelectedCategory);
      }

      // do the actual move
      await entityService.moveRequirement(
        organisationId,
        clientId,
        selectedPeriod.periodId,
        selectedEntity.entityId,
        categoryId,
        subCategoryId,
        oldIndex,
        newIndex
      );

      // trigger a re-load
      void loadCategories();
    }
  };

  return client ? (
    <Box
      className="clientPage"
      sx={{ position: "relative", mb: shoeboxOpen ? "50vh" : 0, pb: shoeboxOpen ? "50vh" : 0 }}
    >
      {!isEmpty(selectedCategory?.requirements) && <ClientPageTour />}

      <ClientHeader client={client} showManageLink={true} />

      <Box
        sx={{
          position: "absolute",
          right: 16,
          top: 16,
        }}
      >
        <TaxyTooltip
          title={
            isEmpty(shoeboxItems) ? "You don't currently have any shoebox items" : "Click to show/hide your shoebox"
          }
        >
          <Badge
            badgeContent={size(shoeboxItems)}
            color="secondary"
            sx={{
              my: 0.5,
              ".MuiBadge-badge": {
                backgroundColor: "grey.400",
              },
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={() => setShoeboxOpen(!shoeboxOpen)}
              disabled={isEmpty(shoeboxItems)}
              startIcon={<Inventory2OutlinedIcon />}
            >
              Shoebox
            </Button>
          </Badge>
        </TaxyTooltip>
      </Box>

      <Grid container>
        {/* PERIOD / ENTITY PANEL */}
        <Grid
          item
          xs={12}
          lg={3}
          xl={2}
          sx={{
            p: 1.5,
            borderRight: "1px solid",
            borderColor: "neutral.light",
          }}
        >
          <Box className="period">
            <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                Period
              </Typography>
              {isAccountant && (
                <Button variant="text" endIcon={<AddIcon fontSize="inherit" />} size="small" onClick={addPeriod}>
                  Add period
                </Button>
              )}
            </Box>

            <Box sx={{ my: 2 }}>
              <Box>
                <PeriodSelect
                  periods={periods}
                  selectedId={selectedPeriod?.periodId}
                  activePeriodId={client.activePeriodId}
                  onSelect={(period) => onChangePeriod(period)}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", mt: 1 }}
            className="activePeriod"
          >
            <Typography variant="body2" color="text.secondary">
              Active period:
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 500, mx: 1 }} color="text.secondary">
              {client.activePeriodName ? client.activePeriodName : "Not set"}
            </Typography>
            {isAccountant && (
              <TaxyTooltip title="Set active period">
                <IconButton size="small" onClick={setActivePeriod}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </TaxyTooltip>
            )}
          </Box>

          <Box className="entity">
            <Box sx={{ display: "flex", flexDirection: "row", mt: 6, mb: 2, alignItems: "center" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                Entity
              </Typography>
              {isAccountant && (
                <Button variant="text" endIcon={<AddIcon />} size="small" onClick={addEntity}>
                  Add entity
                </Button>
              )}
            </Box>

            <EntityList
              entities={entities}
              selectedEntity={selectedEntity}
              onSelect={setSelectedEntity}
              onEdit={editEntity}
            />
          </Box>
        </Grid>

        {/* CATEGORIES PANEL */}
        <Grid
          item
          xs={12}
          lg={3}
          xl={2}
          sx={{
            borderRight: "1px solid",
            borderColor: "neutral.light",
            p: 1.5,
          }}
        >
          <Box className="categories">
            <Box sx={{ display: "flex", flexDirection: "row", mb: 2, alignItems: "center" }}>
              <Typography variant="body1" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                Categories
              </Typography>
              {selectedPeriod && selectedEntity && isAccountant && (
                <Button
                  variant="text"
                  endIcon={<AddIcon fontSize="inherit" />}
                  size="small"
                  onClick={() => addCategory()}
                >
                  Add category
                </Button>
              )}
            </Box>

            <CategoryList
              categories={categories}
              selectedCategory={selectedCategory}
              onSelect={(category) => setSelectedCategory(category)}
              onDragEnd={handleCategoryReorder}
            />
          </Box>

          {selectedPeriod && selectedEntity && isAccountant && (
            <Box sx={{ mt: 4 }}>
              <GenerateWorkingPapersButton
                organisationId={organisationId}
                clientId={clientId}
                periodId={selectedPeriod.periodId}
                entityId={selectedEntity.entityId}
              />
            </Box>
          )}
        </Grid>

        {/* REQUIREMENTS PANEL */}
        <Grid item xs={12} lg={6} xl={8} sx={{ p: 1.5 }}>
          <Box className="requirements">
            {/* DndContext for both the shoebox and the requirements sort */}
            <DndContext
              sensors={sensors}
              onDragStart={(event) => setDragType(get(event.active.data.current, "type"))}
              onDragEnd={() => setDragType(undefined)}
              autoScroll={true}
              modifiers={dragType === DragType.DRAGGABLE ? [] : [restrictToParentElement]}
            >
              <Box sx={{ display: "flex", flexDirection: "row", mb: 3, alignItems: "center" }}>
                <Typography variant="body1" sx={{ fontWeight: "bold", flexGrow: 1 }}>
                  Requirements
                </Typography>
                {isAccountant && selectedCategory && (
                  <>
                    {selectionMode === "multi" && (
                      <>
                        <Button
                          variant="outlined"
                          color="primary"
                          endIcon={<DeleteSweepIcon fontSize="inherit" />}
                          size="small"
                          onClick={() => deleteRequirements(selectedCategory, checkedRequirements)}
                          disabled={checkedRequirements.length === 0}
                          sx={{ mr: 1 }}
                        >
                          Delete requirements
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          endIcon={<CloseIcon fontSize="inherit" />}
                          size="small"
                          onClick={() => setSelectionMode("dnd")}
                          sx={{ mr: 2 }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}

                    <Button
                      variant="text"
                      endIcon={<AddIcon fontSize="inherit" />}
                      size="small"
                      onClick={() => addRequirement(selectedCategory)}
                    >
                      Add requirement
                    </Button>
                  </>
                )}
              </Box>

              {selectedPeriod && selectedCategory && selectedEntity && (
                <>
                  <Box sx={{ mb: 2 }}>
                    {/* CATEGORY ACTIONS */}

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                        pb: 1,
                        borderBottom: "1px solid",
                        borderColor: "grey.400",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold", flex: 1 }}>
                        {selectedCategory.name}
                      </Typography>

                      {isAccountant && (
                        <IconButton
                          sx={{ ml: 0.5 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setMenuCategory(selectedCategory);
                            setAnchorElCategory(e.currentTarget);
                          }}
                        >
                          <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Box>

                    {/* REQUIREMENTS */}
                    <RequirementsList
                      organisationId={organisationId}
                      clientId={clientId}
                      periodId={selectedPeriod.periodId}
                      entityId={selectedEntity.entityId}
                      categoryId={selectedCategory.categoryId}
                      requirements={selectedCategory.requirements}
                      selectedRequirementId={selectedRequirement?.requirementId}
                      selectionMode={selectionMode}
                      shoeboxItems={shoeboxItems}
                      checkedRequirements={checkedRequirements}
                      onCheckRequirements={onCheckRequirement}
                      onOpenRequirement={(requirement: RequirementType) => {
                        onOpenRequirement(requirement, selectedCategory);
                      }}
                      onRequirementUpdated={loadCategories}
                      onReorder={(oldIndex, newIndex) =>
                        onRequirementReorder(selectedCategory.categoryId, undefined, oldIndex, newIndex)
                      }
                      onKebab={(currentTarget, requirement) => {
                        setMenuRequirement({ category: selectedCategory, requirement });
                        setAnchorElRequirement(currentTarget);
                      }}
                    />
                  </Box>

                  <Box>
                    {/* SUB-CATEGORIES */}
                    {selectedCategory.categories.map((subCategory) => (
                      <Box key={subCategory.categoryId} sx={{ mb: "1rem" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 1,
                            pb: 1,
                            borderBottom: "1px solid",
                            borderColor: "grey.400",
                          }}
                        >
                          <Box sx={{ display: "flex", flexDirection: "row", flexGrow: 1, alignItems: "center" }}>
                            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                              {subCategory.name}
                            </Typography>
                          </Box>

                          {isAccountant && (
                            <>
                              <TaxyTooltip title="Edit sub-category">
                                <IconButton
                                  aria-label="Edit sub-category"
                                  sx={{ ml: 0.5 }}
                                  size="medium"
                                  onClick={() => editCategory(selectedCategory, subCategory)}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </TaxyTooltip>

                              <TaxyTooltip title="Delete sub-category">
                                <IconButton
                                  aria-label="Delete"
                                  sx={{ ml: 0.5 }}
                                  size="medium"
                                  onClick={() => deleteSubCategory(selectedCategory, subCategory)}
                                >
                                  <DeleteIcon fontSize="inherit" />
                                </IconButton>
                              </TaxyTooltip>
                            </>
                          )}
                        </Box>

                        {/* SUB-CATEGORY REQUIREMENTS */}
                        <RequirementsList
                          organisationId={organisationId}
                          clientId={clientId}
                          periodId={selectedPeriod.periodId}
                          entityId={selectedEntity.entityId}
                          categoryId={selectedCategory.categoryId}
                          subCategoryId={subCategory.categoryId}
                          requirements={subCategory.requirements}
                          selectedRequirementId={selectedRequirement?.requirementId}
                          selectionMode={selectionMode}
                          shoeboxItems={shoeboxItems}
                          checkedRequirements={checkedRequirements}
                          onCheckRequirements={onCheckRequirement}
                          onOpenRequirement={(requirement: RequirementType) => {
                            onOpenRequirement(requirement, selectedCategory, subCategory);
                          }}
                          onRequirementUpdated={loadCategories}
                          onReorder={(oldIndex, newIndex) =>
                            onRequirementReorder(
                              selectedCategory.categoryId,
                              subCategory.categoryId,
                              oldIndex,
                              newIndex
                            )
                          }
                          onKebab={(currentTarget, requirement) => {
                            setMenuRequirement({ category: selectedCategory, subCategory, requirement });
                            setAnchorElRequirement(currentTarget);
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </>
              )}

              <Shoebox
                organisationId={organisationId}
                clientId={clientId}
                shoeboxItems={shoeboxItems}
                open={shoeboxOpen}
                onShoeboxUpdated={loadShoeboxItems}
              />
            </DndContext>
          </Box>
        </Grid>

        {/* REQUIREMENT MENU */}
        {isAccountant && (
          <Menu
            anchorEl={anchorElRequirement}
            open={!!anchorElRequirement}
            onClose={() => setAnchorElRequirement(undefined)}
          >
            <MenuItem
              dense
              onClick={() => {
                setAnchorElRequirement(undefined);
                if (menuRequirement) {
                  const { category, subCategory, requirement } = menuRequirement || {};
                  editRequirement(category, subCategory, requirement);
                }
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit requirement</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                setAnchorElRequirement(undefined);
                if (menuRequirement) {
                  const { category, subCategory, requirement } = menuRequirement;
                  deleteRequirement(category, subCategory, requirement);
                }
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete requirement</ListItemText>
            </MenuItem>
          </Menu>
        )}

        {/* CATEGORY MENU */}
        {isAccountant && (
          <Menu anchorEl={anchorElCategory} open={!!anchorElCategory} onClose={() => setAnchorElCategory(undefined)}>
            <MenuItem
              dense
              onClick={() => {
                setAnchorElCategory(undefined);
                if (menuCategory) {
                  addCategory(menuCategory);
                }
              }}
            >
              <ListItemIcon>
                <AddIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add sub-category</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                setAnchorElCategory(undefined);
                if (menuCategory) {
                  editCategory(menuCategory);
                }
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit category</ListItemText>
            </MenuItem>

            <MenuItem
              dense
              onClick={() => {
                setAnchorElCategory(undefined);
                if (menuCategory) {
                  deleteCategory(menuCategory);
                }
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete category</ListItemText>
            </MenuItem>

            {selectionMode === "dnd" && (
              <MenuItem
                dense
                onClick={() => {
                  setAnchorElCategory(undefined);
                  if (menuCategory) {
                    setSelectionMode("multi");
                  }
                }}
              >
                <ListItemIcon>
                  <ChecklistIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Select requirements</ListItemText>
              </MenuItem>
            )}
          </Menu>
        )}

        {/* DIALOGS */}
        {isAccountant && !!selectedPeriod && (
          <>
            <AddEntityDialog
              organisationId={organisationId}
              clientId={clientId}
              periodId={selectedPeriod.periodId}
              open={addEntityDialogOpen}
              onClose={addEntityClose}
            />

            {editEntityDialogOpen && entityToEdit && (
              <EditEntityDialog
                organisationId={organisationId}
                clientId={clientId}
                periodId={selectedPeriod.periodId}
                entity={entityToEdit}
                onClose={editEntityClose}
              />
            )}

            {activePeriodDialogOpen && (
              <SetActivePeriodDialog client={client} periods={periods} onClose={setActivePeriodClose} />
            )}

            {addPeriodDialogOpen && (
              <AddPeriodDialog
                organisationId={client.organisationId}
                clientId={client.clientId}
                existingPeriods={values(periods)}
                onClose={addPeriodClose}
              />
            )}

            {!!selectedEntity && (
              <>
                <AddCategoryDialog
                  organisationId={organisationId}
                  clientId={client.clientId}
                  periodId={selectedPeriod.periodId}
                  entityId={selectedEntity.entityId}
                  catalogueId={selectedEntity.entityType}
                  parentCategoryId={addCategoryParentCategory?.categoryId}
                  open={addCategoryDialogOpen}
                  onClose={addCategoryClose}
                />

                {!!categoryToEdit && (
                  <EditCategoryDialog
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    category={categoryToEdit.category}
                    subCategory={categoryToEdit.subCategory}
                    open={editCategoryDialogOpen}
                    onClose={editCategoryClose}
                  />
                )}

                {!!categoryToDelete && (
                  <DeleteCategoryDialog
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    category={categoryToDelete}
                    open={deleteCategoryDialogOpen}
                    onClose={deleteCategoryClose}
                  />
                )}

                {!!subCategoryToDelete && (
                  <DeleteSubCategoryDialog
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    categoryId={subCategoryToDelete.category.categoryId}
                    subCategory={subCategoryToDelete.subCategory}
                    open={deleteSubCategoryDialogOpen}
                    onClose={deleteSubCategoryClose}
                  />
                )}

                {!!addRequirementParent && (
                  <AddRequirementDialog
                    open={addRequirementDialogOpen}
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    category={addRequirementParent}
                    onClose={addRequirementClose}
                  />
                )}

                {!!requirementToDelete && (
                  <DeleteRequirementDialog
                    open={deleteRequirementDialogOpen}
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    categoryId={requirementToDelete.category.categoryId}
                    subCategoryId={requirementToDelete.subCategory?.categoryId}
                    requirement={requirementToDelete.requirement}
                    onClose={deleteRequirementClose}
                  />
                )}

                {!!requirementsToDelete && (
                  <DeleteRequirementsDialog
                    open={deleteRequirementsDialogOpen}
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    categoryId={requirementsToDelete.category.categoryId}
                    requirements={requirementsToDelete.requirements}
                    onClose={deleteRequirementsClose}
                  />
                )}

                {!!requirementToEdit && (
                  <EditRequirementDialog
                    organisationId={organisationId}
                    clientId={clientId}
                    periodId={selectedPeriod.periodId}
                    entityId={selectedEntity.entityId}
                    categoryId={requirementToEdit.category.categoryId}
                    subCategoryId={requirementToEdit.subCategory?.categoryId}
                    requirement={requirementToEdit.requirement}
                    open={editRequirementDialogOpen}
                    onClose={editRequirementClose}
                  />
                )}
              </>
            )}
          </>
        )}

        {selectedPeriod && selectedEntity && selectedCategory && selectedRequirement && selectedRequirementCategory && (
          <RequirementDetailsDialog
            organisationId={organisationId}
            client={client}
            period={selectedPeriod}
            entity={selectedEntity}
            category={selectedRequirementCategory}
            subCategory={selectedRequirementSubCategory}
            requirement={selectedRequirement}
            open={requirementDetailsDialogOpen}
            onClose={(itemUpdated) => {
              setRequirementDetailsDialogOpen(false);
              if (itemUpdated) {
                void loadCategories();
              }
            }}
            onRequirementUpdated={loadCategories}
          />
        )}
      </Grid>
    </Box>
  ) : null;
};

export default ClientBody;
