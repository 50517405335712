import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useForm } from "react-hook-form";
import { ClientType, UserType } from "../../types";
import SubmitButton from "../button/SubmitButton";
import { Grid, MenuItem, TextField } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { compile } from "handlebars";
import dayjs from "dayjs";
import convertServerTimestamp from "../../utilities/convertServerTimestamp";
import { useApplicationState } from "../../hooks/ApplicationState";
import useHttpFunctions from "../../hooks/HttpFunctions";
import useUserService from "../../hooks/UserService";
import DialogContentText from "@mui/material/DialogContentText";
import { isEmpty } from "lodash";

const formSchema = yup
  .object({
    recipient: yup.string().required("Recipient is required"),
    message: yup.string().max(1000, "Message must be 1000 characters or less").trim().required("Message is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface RemindClientDialogProps {
  client: ClientType;
  onClose: () => void;
}

const MESSAGE_TEMPLATE = `Dear {{clientName}},

I hope you had a nice weekend.

I just wanted to follow up on the information we need to complete your {{activePeriodName}} tax return.

Your return is due for lodgement {{lodgementDate}} and therefore we need you to upload your tax information and answer the questions in Taxy {{dueDate}}.

Please feel free to give me a call with any questions.

Kind regards,
{{accountantFirstName}} {{accountantLastName}}`;

const RemindClientDialog = ({ client, onClose }: RemindClientDialogProps) => {
  const { user, setSystemMessage } = useApplicationState();
  const userService = useUserService();
  const httpFunctions = useHttpFunctions();

  const [users, setUsers] = useState<UserType[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    control,
    setValue,
    setError,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      message: "",
      recipient: "",
    },
  });

  const onSubmit = async (data: FormType) => {
    const { recipient, message } = data;
    try {
      const response = await httpFunctions.remindClient({
        to: [recipient],
        message,
        accountantName: user ? `${user.firstName} ${user.lastName}` : "",
      });
      if (response.data.success) {
        setSystemMessage(response.data.message);
        reset();
        onClose();
      }
    } catch (ex: any) {
      const message = "There was an error sending the reminder message";
      setError("message", { type: "custom", message });
    }
  };

  const loadClientUsers = useCallback(async () => {
    const results = await userService.listClientUsers(client.organisationId, client.clientId);
    setUsers(results);
  }, [userService, client]);

  useEffect(() => {
    void loadClientUsers();
  }, [loadClientUsers]);

  useEffect(() => {
    const firstRecipient = users[0]?.email;
    if (firstRecipient) {
      setValue("recipient", firstRecipient);
    }
  }, [users, setValue]);

  useEffect(() => {
    const template = compile(MESSAGE_TEMPLATE);
    const messageText = template({
      clientName: client.name || "client",
      activePeriodName: client.activePeriodName || "upcoming",
      lodgementDate: client.nextDueDate
        ? `on ${dayjs(convertServerTimestamp(client.nextDueDate)).format("DD/MM/YYYY")}`
        : "soon",
      dueDate: client.nextDueDate
        ? `before ${dayjs(convertServerTimestamp(client.nextDueDate)).subtract(1, "month").format("DD/MM/YYYY")}`
        : "ASAP",
      accountantFirstName: user?.firstName,
      accountantLastName: user?.lastName,
    });
    setValue("message", messageText);
  }, [setValue, user, client]);

  return (
    <Dialog onClose={() => onClose()} disableRestoreFocus fullWidth maxWidth="md" open={true}>
      <DialogTitle>Remind client</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText sx={{ mb: 4 }}>
            Select a client email to send the reminder to and optionally edit the message before sending.
          </DialogContentText>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                name="recipient"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Recipient"
                    select
                    error={!!errors.recipient}
                    helperText={errors.recipient?.message}
                  >
                    {isEmpty(users) ? (
                      <MenuItem disabled>No users exist for this client</MenuItem>
                    ) : (
                      users.map((user) => (
                        <MenuItem key={user.uid} value={user.email}>
                          {user.email}
                        </MenuItem>
                      ))
                    )}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={14}
                autoFocus
                error={!!errors.message}
                helperText={errors.message?.message}
                {...register("message")}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => onClose()}>
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Send" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RemindClientDialog;
