import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

type EditCommentDialogProps = {
  open: boolean;
  comment: string;
  onClose: () => void;
  onSubmit: (comment: string) => void;
};

const EditCommentDialog = ({ open, comment, onClose, onSubmit }: EditCommentDialogProps) => {
  const [updatedComment, setUpdatedComment] = useState<string>("");

  useEffect(() => {
    setUpdatedComment(comment);
  }, [comment]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUpdatedComment(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit comment</DialogTitle>
      <DialogContent>
        <TextField
          label="Comment"
          variant="outlined"
          value={updatedComment}
          fullWidth
          multiline
          rows={8}
          onChange={handleChange}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(updatedComment)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommentDialog;
