import { useMemo } from "react";
import { UserType } from "../types";
import { getDoc, query, where } from "firebase/firestore";
import ServiceError from "./ServiceError";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import { RoleType } from "@taxy/common";
import { chain, includes } from "lodash";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";
import {
  Auth,
  confirmPasswordReset,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const ACCOUNTANT_ROLE: RoleType = "ACCOUNTANT";

export class UserService {
  constructor(
    readonly firestoreService: FirestoreService,
    readonly auth: Auth,
    readonly httpFunctions: HttpFunctions
  ) {}

  public async signIn(email: string, password: string) {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  public async signOut() {
    return signOut(this.auth);
  }

  public async getUser(uid: string): Promise<UserType | undefined> {
    try {
      const documentReference = this.firestoreService.getUserDocument(uid);
      const documentSnapshot = await getDoc(documentReference);
      return documentSnapshot.exists() ? (documentSnapshot.data() as UserType) : undefined;
    } catch (e) {
      console.error(e);
      throw new ServiceError(`Unable to get user ${uid}`);
    }
  }

  public async listClientUsers(organisationId: string, clientId: string): Promise<UserType[]> {
    const users = await this.listOrganisationUsers([organisationId]);
    return chain(users)
      .filter((user) => includes(user.clientIds, clientId))
      .orderBy((user) => user.firstName, "asc")
      .value();
  }

  public async listAccountantUsers(organisationIds: string[]): Promise<UserType[]> {
    const users = await this.listOrganisationUsers(organisationIds);
    return chain(users)
      .filter((user) => includes(user.roles, ACCOUNTANT_ROLE))
      .orderBy((user) => user.firstName, "asc")
      .value();
  }

  /**
   * Returns users for any of the given organisations. Firestore queries can only have one "array-contains-any" clause
   * so this function can be used to get all organisation users and the list can then be filtered further in memory
   * if necessary.
   */
  public async listOrganisationUsers(organisationIds: string[]): Promise<UserType[]> {
    const usersCollection = this.firestoreService.getUsersCollection();
    const snapshots = await this.firestoreService.getDocs(
      query(usersCollection, where("organisationIds", "array-contains-any", organisationIds))
    );
    return snapshots.docs.map((snapshot) => snapshot.data() as UserType);
  }

  public async registerNewUser(
    firstName: string,
    lastName: string,
    email: string,
    organisationName: string,
    password: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.registerNewUser({
      firstName,
      lastName,
      email,
      organisationName,
      password,
    });
    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async registerInvitedUser(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    inviteCode: string
  ): Promise<string | undefined> {
    const { data } = await this.httpFunctions.registerInvitedUser({
      firstName,
      lastName,
      email,
      password,
      inviteCode,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async requestPasswordReset(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  public async resetPassword(oobCode: string, newPassword: string) {
    return confirmPasswordReset(this.auth, oobCode, newPassword);
  }

  public async requestEmailVerification() {
    const loggedInUser = this.auth.currentUser;
    if (loggedInUser) {
      return sendEmailVerification(loggedInUser);
    } else {
      throw new ServiceError("Cannot send email verification, the user is not logged in");
    }
  }
}

const useUserService = () => {
  const firestoreService = useFirestoreService();
  const auth = getAuth();
  const httpFunctions = useHttpFunctions();

  return useMemo(() => {
    return new UserService(firestoreService, auth, httpFunctions);
  }, [firestoreService, auth, httpFunctions]);
};

export default useUserService;
