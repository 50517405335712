import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const TermsAndConditionsPage = () => (
  <Container component="main" maxWidth="md" sx={{ my: 8 }}>
    <Typography variant="h4">Terms and conditions</Typography>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et vulputate felis, nec malesuada risus. Sed nec
      libero at nisi euismod lacinia. Ut lobortis neque ut orci tristique euismod. Sed bibendum tincidunt condimentum.
      Nunc accumsan efficitur diam cursus finibus. Aenean non libero interdum, semper dolor rhoncus, blandit dui. Proin
      eget eros in purus vulputate ullamcorper. Vestibulum orci leo, lobortis ac pretium sit amet, semper euismod leo.
      Vivamus at lorem id purus placerat luctus at id purus.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et vulputate felis, nec malesuada risus. Sed nec
      libero at nisi euismod lacinia. Ut lobortis neque ut orci tristique euismod. Sed bibendum tincidunt condimentum.
      Nunc accumsan efficitur diam cursus finibus. Aenean non libero interdum, semper dolor rhoncus, blandit dui. Proin
      eget eros in purus vulputate ullamcorper. Vestibulum orci leo, lobortis ac pretium sit amet, semper euismod leo.
      Vivamus at lorem id purus placerat luctus at id purus.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et vulputate felis, nec malesuada risus. Sed nec
      libero at nisi euismod lacinia. Ut lobortis neque ut orci tristique euismod. Sed bibendum tincidunt condimentum.
      Nunc accumsan efficitur diam cursus finibus. Aenean non libero interdum, semper dolor rhoncus, blandit dui. Proin
      eget eros in purus vulputate ullamcorper. Vestibulum orci leo, lobortis ac pretium sit amet, semper euismod leo.
      Vivamus at lorem id purus placerat luctus at id purus.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent et vulputate felis, nec malesuada risus. Sed nec
      libero at nisi euismod lacinia. Ut lobortis neque ut orci tristique euismod. Sed bibendum tincidunt condimentum.
      Nunc accumsan efficitur diam cursus finibus. Aenean non libero interdum, semper dolor rhoncus, blandit dui. Proin
      eget eros in purus vulputate ullamcorper. Vestibulum orci leo, lobortis ac pretium sit amet, semper euismod leo.
      Vivamus at lorem id purus placerat luctus at id purus.
    </p>
  </Container>
);

export default TermsAndConditionsPage;
