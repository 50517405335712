import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { OrganisationType } from "../../types";
import { useApplicationState } from "../../hooks/ApplicationState";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import GroupIcon from "@mui/icons-material/Group";
import useOrganisationService from "../../hooks/OrganisationService";

type TeamListProps = {
  onClick: (organisation: OrganisationType) => void;
};

const TeamList = ({ onClick }: TeamListProps) => {
  const { adminOrganisationIds } = useApplicationState();
  const organisationService = useOrganisationService();

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  const loadOrganisations = useCallback(async () => {
    const results = await Promise.all(
      adminOrganisationIds.map((organisationId) => organisationService.get(organisationId))
    );
    setOrganisations(results);
  }, [adminOrganisationIds, organisationService]);

  useEffect(() => {
    loadOrganisations();
  }, [loadOrganisations]);

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" square sx={{ p: 2 }}>
        <List>
          {organisations.map((organisation) => (
            <ListItem disablePadding key={organisation.organisationId}>
              <ListItemButton onClick={() => onClick(organisation)}>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={organisation.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Paper>
    </Grid>
  );
};

export default TeamList;
