import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import useUploadFile from "../hooks/useUploadFile";
import { FileMetadataType } from "../types";
import useFileStorageService from "../hooks/FileStorageService";
import useClientService from "../hooks/ClientService";
import useRequirementService from "../hooks/RequirementService";

const DROPZONE_DEFAULT_TEXT = "Drop files here or click to select files";

type UploadFileProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId?: string;
  requirementId: string;
  uploadCallback: () => void;
};

const UploadFile = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirementId,
  uploadCallback,
}: UploadFileProps) => {
  const fileStorageService = useFileStorageService();
  const clientService = useClientService();
  const requirementService = useRequirementService();
  const [uploadFile, uploading, snapshot, error] = useUploadFile();

  const [dropzoneText, setDropzoneText] = useState<string>(DROPZONE_DEFAULT_TEXT);
  const [totalFiles, setTotalFiles] = useState<number>();
  const [completedFiles, setCompletedFiles] = useState<number>();

  useEffect(() => {
    if (totalFiles !== undefined && completedFiles !== undefined && snapshot) {
      const percentage = Math.round(
        ((completedFiles + snapshot.bytesTransferred / snapshot.totalBytes) / totalFiles) * 100
      );
      setDropzoneText(`Uploading (${percentage}%)`);
    } else {
      setDropzoneText(DROPZONE_DEFAULT_TEXT);
    }
  }, [completedFiles, snapshot, totalFiles]);

  const doUpload = useCallback(
    async (storageLocation: string, metadata: FileMetadataType) => {
      // upload the file to the client
      const file = await clientService.createFile(organisationId, clientId, periodId, storageLocation, metadata);

      // add the file to the requirement
      await requirementService.addFile(
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId,
        subCategoryId,
        requirementId,
        file
      );

      void uploadCallback();
    },
    [
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId,
      clientService,
      requirementService,
      uploadCallback,
    ]
  );

  const upload = useCallback(
    async (file: File) => {
      const originalName = file.name || "unknown.file";
      const ref = fileStorageService.getUserUploadedFileRef(uuidv4());
      const metadata = {
        contentType: file.type,
        customMetadata: {
          lastModified: file.lastModified.toString(),
          name: originalName,
          size: file.size.toString(),
        },
      };

      const result = await uploadFile(ref, file, metadata);
      if (result?.ref) {
        await doUpload(result.ref.fullPath, metadata);
        setCompletedFiles((completedFiles) => (completedFiles || 0) + 1);
      }
    },
    [fileStorageService, doUpload, uploadFile]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setTotalFiles(acceptedFiles.length);
      setCompletedFiles(0);

      await Promise.all(acceptedFiles.map((file) => upload(file)));

      setTotalFiles(undefined);
      setCompletedFiles(undefined);
    },
    [upload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, disabled: uploading });

  return (
    <>
      {error && <strong>Error: {error.message}</strong>}

      <Box
        {...getRootProps()}
        sx={{
          display: "block",
          borderStyle: "dashed",
          borderColor: isDragActive ? "grey.600" : "grey.400",
          color: isDragActive ? "grey.600" : "grey.400",
          borderWidth: 2,
          cursor: "pointer",
          p: 1,
          my: 1,
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        <p>{dropzoneText}</p>
      </Box>
    </>
  );
};

export default UploadFile;
