import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom"; // Import useSearchParams
import useOrganisationService from "../../../hooks/OrganisationService";
import { OrganisationType } from "../../../types";
import AdminLayout from "../AdminLayout";
import OrganisationClients from "./OrganisationClients";
import OrganisationUsers from "./OrganisationUsers";

type OrganisationParams = {
  organisationId: string;
};

type TabType = "users" | "clients";

const Organisation = () => {
  const { organisationId } = useParams<OrganisationParams>() as OrganisationParams;
  const organisationService = useOrganisationService();

  const [organisation, setOrganisation] = useState<OrganisationType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = (searchParams.get("tab") as TabType) || "users";

  const handleTabChange = (_event: React.SyntheticEvent, tab: TabType) => {
    setSearchParams({ tab });
  };

  const loadOrganisation = useCallback(async () => {
    const result = await organisationService.get(organisationId);
    setOrganisation(result);
  }, [organisationService, organisationId]);

  useEffect(() => {
    void loadOrganisation();
  }, [loadOrganisation]);

  return organisation ? (
    <AdminLayout
      header={
        <Grid item xs={12}>
          <Typography variant="h6" color="primary.main">
            Organisation
          </Typography>
          <Typography variant="h4">{organisation.name}</Typography>
        </Grid>
      }
    >
      <Box sx={{ width: "100%" }}>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} aria-label="Organisation tabs">
              <Tab label="Users" value="users" />
              <Tab label="Clients" value="clients" />
            </TabList>
          </Box>
          <TabPanel value="users">
            <OrganisationUsers organisationId={organisationId} />
          </TabPanel>
          <TabPanel value="clients">
            <OrganisationClients organisationId={organisationId} />
          </TabPanel>
        </TabContext>
      </Box>
    </AdminLayout>
  ) : null;
};

export default Organisation;
