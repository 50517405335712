import Copyright from "../Copyright";
import { ReactNode } from "react";
import Container from "@mui/material/Container";
import TaxyLogo from "../images/taxy-logo.png";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

type AuthPageProps = {
  content: ReactNode;
  footer?: ReactNode;
};

// A base page for auth-related pages (login, reset password etc) so they all look consistent
const BaseAuthPage = ({ content, footer }: AuthPageProps) => {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
        }}
        id="auth-box"
      >
        <Grid container id="auth-grid-container-1">
          <Grid item xs={12}>
            <a href="/">
              <Box
                component={Paper}
                elevation={8}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "80px",
                  backgroundColor: "secondary.main",
                  p: 1.5,
                  mb: 3,
                }}
              >
                <img src={TaxyLogo} alt="Taxy logo" />
              </Box>
            </a>
          </Grid>
        </Grid>

        {content}

        {footer && (
          <Grid container sx={{ mt: 4 }}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="body2" color="text.secondary">
                {footer}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Copyright sx={{ mt: 4 }} />
      </Box>
    </Container>
  );
};

export default BaseAuthPage;
