import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import useClientService from "../../hooks/ClientService";
import useRequirementService from "../../hooks/RequirementService";
import {
  CategoryType,
  ClientType,
  EntityType,
  FileType,
  PeriodType,
  RequirementStatusType,
  RequirementType,
} from "../../types";
import Comments from "../client/requirement/Comments";
import RequirementAccountantNotes from "../client/requirement/RequirementAccountantNotes";
import RequirementActionButtons from "../client/requirement/RequirementActionButtons";
import RequirementFiles from "../client/RequirementFiles";
import RequirementStatusChip from "../client/RequirementStatusChip";
import CopyToClipboard from "../CopyToClipboard";
import DebugInfo from "../debug/DebugInfo";
import RequirementDetailsTour from "../tour/RequirementDetailsTour";

type RequirementDetailsDialogProps = {
  organisationId: string;
  client: ClientType;
  period: PeriodType;
  entity: EntityType;
  category: CategoryType;
  subCategory?: CategoryType;
  requirement: RequirementType;
  open: boolean;
  onClose: (itemUpdated: boolean) => void;
  onRequirementUpdated: () => void;
};

const RequirementDetailsDialog = ({
  organisationId,
  client,
  period,
  entity,
  category,
  subCategory,
  requirement,
  open,
  onClose,
  onRequirementUpdated,
}: RequirementDetailsDialogProps) => {
  const { user, isDev, isAccountant } = useApplicationState();
  const clientService = useClientService();
  const requirementService = useRequirementService();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>("");
  const [isCommentsFieldVisible, setIsCommentsFieldVisible] = useState<boolean>(false);
  const [filesModified, setFilesModified] = useState<boolean>(false);
  const [requirementPath, setRequirementPath] = useState<string>("");
  const [files, setFiles] = useState<FileType[]>([]);

  const loadFiles = useCallback(async () => {
    const results = await clientService.getFiles(organisationId, client.clientId, period.periodId);
    setFiles(results);
  }, [clientService, organisationId, client.clientId, period.periodId]);

  useEffect(() => {
    void loadFiles();
  }, [loadFiles]);

  useEffect(() => {
    return clientService.watchFiles(organisationId, client.clientId, period.periodId, () => {
      void loadFiles();
    });
  }, [organisationId, client.clientId, period.periodId, clientService, loadFiles]);

  useEffect(() => {
    setFilesModified(false);
  }, [requirement.requirementId]);

  useEffect(() => {
    setIsCommentsFieldVisible(isAccountant ? true : requirement.status === "WITH_CLIENT");
  }, [requirement.status, isAccountant]);

  useEffect(() => {
    const path = requirementService.getRequirementPath(
      organisationId,
      client.clientId,
      period.periodId,
      entity.entityId,
      category.categoryId,
      subCategory?.categoryId,
      requirement.requirementId
    );
    setRequirementPath(path);
  }, [
    requirementService,
    organisationId,
    client.clientId,
    period.periodId,
    entity.entityId,
    category.categoryId,
    subCategory?.categoryId,
    requirement.requirementId,
  ]);

  const handleCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCommentValue((event.target as HTMLInputElement).value);
  };

  const updateRequirement = async (status: RequirementStatusType) => {
    setIsLoading(true);
    try {
      await requirementService.updateStatus(
        organisationId,
        client.clientId,
        period.periodId,
        entity.entityId,
        category.categoryId,
        subCategory?.categoryId,
        requirement.requirementId,
        status,
        commentValue,
        user?.email
      );
      setCommentValue("");
      onClose(true);
    } finally {
      setIsLoading(false);
    }
  };

  function handleCancel() {
    setCommentValue("");
    onClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableRestoreFocus
      fullWidth
      maxWidth="xl"
      scroll="paper"
      sx={{ m: 4 }}
      PaperProps={{ sx: { height: "100vh" } }}
      className="requirementDetails"
    >
      {requirement.status === "WITH_CLIENT" && <RequirementDetailsTour />}

      <DialogTitle>
        <Box sx={{ display: "flex" }}>
          <Box className="title" sx={{ display: "flex" }}>
            {requirement?.name}
            <Box sx={{ ml: 2, mr: 6 }}>
              <RequirementStatusChip requirement={requirement} />
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <IconButton
        aria-label="cancel"
        onClick={handleCancel}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "grey.500",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Grid container spacing={2}>
          {requirement.description && (
            <Grid item xs={12}>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {requirement.description}
                </Typography>
              </Box>
            </Grid>
          )}

          {isAccountant && (
            <Grid item xs={12}>
              <RequirementAccountantNotes
                id={{
                  organisationId,
                  clientId: client.clientId,
                  periodId: period.periodId,
                  entityId: entity.entityId,
                  categoryId: category.categoryId,
                  subCategoryId: subCategory?.categoryId,
                  requirementId: requirement.requirementId,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Box className="files">
              <RequirementFiles
                organisationId={organisationId}
                clientId={client.clientId}
                periodId={period.periodId}
                entityId={entity.entityId}
                categoryId={category.categoryId}
                subCategoryId={subCategory?.categoryId}
                requirement={requirement}
                files={files}
                onFileChange={() => {
                  setFilesModified(true);
                  onRequirementUpdated();
                }}
              />
            </Box>

            {/* REMINDER MESSAGE FOR CLIENTS TO RETURN THE REQUIREMENT TO THE ACCOUNTANT */}
            {!isAccountant && requirement.status === "WITH_CLIENT" && filesModified && (
              <Alert severity="info" sx={{ my: 2 }}>
                Once you have uploaded your files and added an optional response click "Return to Accountant"
              </Alert>
            )}
          </Grid>

          {isCommentsFieldVisible && (
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Box className="response">
                <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
                  Response
                </Typography>
                <TextField
                  label="Your response and notes"
                  placeholder={isAccountant ? "Optional response" : "Optional response or note for your accountant"}
                  variant="outlined"
                  multiline
                  fullWidth
                  minRows={3}
                  autoFocus
                  value={commentValue}
                  onChange={handleCommentChange}
                  disabled={isLoading}
                />
              </Box>
            </Grid>
          )}

          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              Response history
            </Typography>
            <Comments
              organisationId={organisationId}
              clientId={client.clientId}
              periodId={period.periodId}
              entityId={entity.entityId}
              categoryId={category.categoryId}
              subCategoryId={subCategory?.categoryId}
              requirement={requirement}
              onCommentUpdated={onRequirementUpdated}
            />
          </Grid>
        </Grid>

        {isDev && (
          <Box sx={{ mt: 2 }}>
            <DebugInfo>
              <Typography variant="subtitle2">Requirement path:</Typography>
              <CopyToClipboard textToCopy={requirementPath}>
                <Typography variant="body2" sx={{ wordWrap: "break-word", mt: 1 }}>
                  {requirementPath}
                </Typography>
              </CopyToClipboard>
            </DebugInfo>
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ m: 2 }}>
        <Grid item xs={12} textAlign="right">
          <Button onClick={handleCancel} variant="outlined" color="secondary" endIcon={<CloseIcon />}>
            Cancel
          </Button>
          <RequirementActionButtons requirement={requirement} onUpdate={updateRequirement} loading={isLoading} />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default RequirementDetailsDialog;
