import { useMemo } from "react";
import { doc, Firestore, getDoc, getFirestore } from "firebase/firestore";
import { OrganisationType } from "../types";
import ServiceError from "./ServiceError";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";

export class OrganisationService {
  constructor(readonly db: Firestore, readonly httpFunctions: HttpFunctions) {}

  public async get(organisationId: string) {
    try {
      const documentRef = doc(this.db, "organisations", organisationId);
      const documentSnapshot = await getDoc(documentRef);
      return {
        ...documentSnapshot.data(),
        organisationId: documentSnapshot.id,
      } as OrganisationType;
    } catch (e) {
      console.error(e);
      throw new ServiceError(`Unable to get organisation ${organisationId}`);
    }
  }

  public async addUserToOrganisation(
    organisationId: string,
    isAdmin: boolean,
    firstName: string,
    lastName: string,
    email: string
  ) {
    const { data } = await this.httpFunctions.addUserToOrganisation({
      organisationId,
      isAdmin,
      firstName,
      lastName,
      email,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async removeUserFromOrganisation(organisationId: string, userId: string) {
    const { data } = await this.httpFunctions.removeUserFromOrganisation({
      organisationId,
      userId,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async updateOrganisationAdminUser(organisationId: string, userId: string, isAdmin: boolean) {
    const { data } = await this.httpFunctions.updateOrganisationAdminUser({
      organisationId,
      userId,
      isAdmin,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }
}

const useOrganisationService = () => {
  const db = getFirestore();
  const httpFunctions = useHttpFunctions();
  return useMemo(() => new OrganisationService(db, httpFunctions), [db, httpFunctions]);
};

export default useOrganisationService;
