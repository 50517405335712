import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import useCatalogueService from "../../hooks/CatalogueService";
import SubmitButton from "../button/SubmitButton";
import { RequirementCatalogueType } from "../../types";
import DialogContentText from "@mui/material/DialogContentText";

type DeleteCatalogueRequirementDialogProps = {
  organisationId: string;
  catalogueId: string;
  categoryId: string;
  requirement: RequirementCatalogueType;
  open: boolean;
  onClose: (requirementDeleted: boolean) => void;
};

const DeleteCatalogueRequirementDialog = ({
  organisationId,
  catalogueId,
  categoryId,
  requirement,
  open,
  onClose,
}: DeleteCatalogueRequirementDialogProps) => {
  const catalogueService = useCatalogueService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    await catalogueService.deleteRequirement(organisationId, catalogueId, categoryId, requirement.requirementId);
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Delete requirement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>{`Delete the requirement "${requirement.name}"?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteCatalogueRequirementDialog;
