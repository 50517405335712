import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { ClientType, PeriodType, UserType } from "../../../types";
import EditIcon from "@mui/icons-material/Edit";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import TodayIcon from "@mui/icons-material/Today";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import useUserService from "../../../hooks/UserService";
import Box from "@mui/material/Box";
import ClientStatusChip from "../ClientStatusChip";
import FormattedTimestamp from "../../FormattedTimestamp";
import IconButton from "@mui/material/IconButton";
import AssignAccountantDialog from "../dialogs/AssignAccountantDialog";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormattedUserName from "../../FormattedUserName";
import SetActivePeriodDialog from "../dialogs/SetActivePeriodDialog";
import useClientService from "../../../hooks/ClientService";
import TaxyTooltip from "../../TaxyTooltip";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CopyToClipboard from "../../CopyToClipboard";
import Typography from "@mui/material/Typography";

type ClientUsersProps = {
  client: ClientType;
  onClientUpdate: () => void;
};

const ClientSummary = ({ client, onClientUpdate }: ClientUsersProps) => {
  const userService = useUserService();
  const clientService = useClientService();

  const [accountants, setAccountants] = useState<UserType[]>([]);
  const [accountant, setAccountant] = useState<UserType>();
  const [periods, setPeriods] = useState<PeriodType[]>([]);
  const [assignAccountantDialogOpen, setAssignAccountantDialogOpen] = useState<boolean>(false);
  const [activePeriodDialogOpen, setActivePeriodDialogOpen] = useState<boolean>(false);

  const loadUser = useCallback(
    async (uid?: string) => {
      const result = uid ? await userService.getUser(uid) : undefined;
      setAccountant(result);
    },
    [userService]
  );

  const loadPeriods = useCallback(
    async (clientId: string) => {
      const results = await clientService.getPeriods(client.organisationId, clientId);
      setPeriods(results);
    },
    [clientService, client.organisationId]
  );

  useEffect(() => {
    if (client.manager) {
      void loadUser(client.manager);
    }
  }, [client.manager, loadUser]);

  useEffect(() => {
    void loadPeriods(client.clientId);
  }, [client.clientId, loadPeriods]);

  const handleAssignAccountantDialogOpen = () => {
    setAssignAccountantDialogOpen(true);
  };

  const handleAssignAccountantDialogClose = (accountantAssigned: boolean) => {
    setAssignAccountantDialogOpen(false);
    if (accountantAssigned) {
      onClientUpdate();
    }
  };

  const handleActivePeriodDialogOpen = () => {
    setActivePeriodDialogOpen(true);
  };

  const handleActivePeriodDialogClose = (periodUpdated: boolean) => {
    setActivePeriodDialogOpen(false);
    if (periodUpdated) {
      onClientUpdate();
    }
  };

  const loadAccountantUsers = useCallback(async () => {
    const results = await userService.listAccountantUsers([client.organisationId]);
    setAccountants(results);
  }, [client.organisationId, userService]);

  useEffect(() => {
    void loadAccountantUsers();
  }, [loadAccountantUsers]);

  return (
    <Grid container>
      <Grid item sm={6}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText
              primary="Active period"
              secondary={
                <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                  <span>{client.activePeriodName}</span>
                  <TaxyTooltip title="Set active period">
                    <IconButton sx={{ ml: 1 }} onClick={() => handleActivePeriodDialogOpen()} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TaxyTooltip>
                </Box>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="Manager"
              secondary={
                <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                  <FormattedUserName user={accountant} defaultValue="Not assigned" />
                  <TaxyTooltip title="Assign manager">
                    <IconButton sx={{ ml: 1 }} onClick={() => handleAssignAccountantDialogOpen()} size="small">
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </TaxyTooltip>
                </Box>
              }
            />
          </ListItem>

          {client.shoeboxEmail && (
            <ListItem>
              <ListItemIcon>
                <MailOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Shoebox email address"
                disableTypography
                secondary={
                  <Box component="span" sx={{ display: "flex", alignItems: "center" }}>
                    <CopyToClipboard textToCopy={client.shoeboxEmail}>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {client.shoeboxEmail}
                      </Typography>
                    </CopyToClipboard>
                  </Box>
                }
              />
            </ListItem>
          )}
        </List>
      </Grid>

      <Grid item sm={6}>
        <List>
          <ListItem>
            <ListItemIcon>
              <TodayIcon />
            </ListItemIcon>
            <ListItemText
              primary="Next due date"
              secondary={<FormattedTimestamp timestamp={client.nextDueDate} defaultValue="Not set" />}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TaskAltIcon />
            </ListItemIcon>
            <ListItemText
              disableTypography
              primary="Status"
              secondary={
                <Box>
                  <ClientStatusChip client={client} />
                </Box>
              }
            />
          </ListItem>
        </List>
      </Grid>

      {assignAccountantDialogOpen && (
        <AssignAccountantDialog client={client} accountants={accountants} onClose={handleAssignAccountantDialogClose} />
      )}

      {activePeriodDialogOpen && (
        <SetActivePeriodDialog client={client} periods={periods} onClose={handleActivePeriodDialogClose} />
      )}
    </Grid>
  );
};

export default ClientSummary;
