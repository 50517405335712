import Box from "@mui/material/Box";

type ItemCountProps = {
  icon: string;
  alt: string;
  count?: number;
};

const ItemCount = ({ icon, alt, count }: ItemCountProps) => {
  return (
    <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
      <img src={icon} alt={alt} />
      <Box sx={{ fontWeight: 500, mx: 1 }}>{count || 0}</Box>
    </Box>
  );
};

export default ItemCount;
