import React, { useState } from "react";
import Button from "@mui/material/Button";
import { ReactComponent as PdfIcon } from "../images/PdfIcon.svg";
import useHttpFunctions from "../../hooks/HttpFunctions";
import CircularProgress from "@mui/material/CircularProgress";

type GenerateWorkingPapersButtonParams = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
};

const GenerateWorkingPapersButton = ({
  organisationId,
  clientId,
  periodId,
  entityId,
}: GenerateWorkingPapersButtonParams) => {
  const { workingPapers } = useHttpFunctions();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const generateWorkingPapers = async () => {
    setIsSubmitting(true);
    try {
      const response = await workingPapers({
        organisationId,
        clientId,
        periodId,
        entityId,
      });
      const { success, signedUrl } = response.data;
      if (success) {
        const newWindow = window.open(signedUrl, "_blank", "noopener,noreferrer");
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Button
      variant="text"
      color="secondary"
      size="small"
      onClick={generateWorkingPapers}
      disabled={isSubmitting}
      startIcon={isSubmitting ? <CircularProgress size={24} /> : <PdfIcon />}
    >
      Generate Working Papers
    </Button>
  );
};

export default GenerateWorkingPapersButton;
