import React from "react";
import { useApplicationState } from "../../hooks/ApplicationState";
import GuidedTour, { GuidedTourStep } from "./GuidedTour";
import Box from "@mui/material/Box";

const steps: GuidedTourStep[] = [
  {
    target: ".requirementDetails .title",
    title: "Requirement",
    content:
      "This is the question your accountant needs you to answer. Sometimes you need to attach a document, other times you may only need to answer Yes/No.",
    placement: "bottom-start",
  },
  {
    target: ".requirementDetails .files",
    title: "Files",
    content:
      "Attach any files you need to provide here. Drag them from another window or select them from a folder. After you upload a file you'll see a list of attachments where you can rename, preview, download or delete them.",
    placement: "bottom-start",
  },
  {
    target: ".requirementDetails .response",
    title: "Response",
    content:
      "You can type your answer or response in here, or even a question for your accountant to clarify something.",
    placement: "bottom-start",
  },
  {
    target: ".requirementDetails .actionButton",
    title: "Action",
    content: (
      <p>
        Once you've finished with the question, click the{" "}
        <Box component="span" sx={{ fontWeight: 500 }}>
          RETURN TO ACCOUNTANT
        </Box>{" "}
        button so your accountant knows you've finished and they can work on the information you've provided.
      </p>
    ),
    placement: "top-start",
  },
];

const RequirementDetailsTour = () => {
  const { isClient } = useApplicationState();
  return isClient ? <GuidedTour tourId="requirement-details" steps={steps} /> : null;
};

export default RequirementDetailsTour;
