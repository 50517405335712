import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { find, isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ClientType, UserType } from "../../../types";
import useClientService from "../../../hooks/ClientService";

interface AssignAccountantDialogProps {
  client: ClientType;
  accountants: UserType[];
  onClose: (accountantAssigned: boolean) => void;
}

const formSchema = yup.object({
  uid: yup.string().required(),
});

type FormType = yup.InferType<typeof formSchema>;

const AssignAccountantDialog = ({ client, accountants, onClose }: AssignAccountantDialogProps) => {
  const clientService = useClientService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: { uid: find(accountants, (accountant) => accountant.uid === client.manager)?.uid || "" },
  });

  const onSubmit = async (data: FormType) => {
    const { uid } = data;
    const user = find(accountants, (accountant) => accountant.uid === uid);
    if (!user) {
      setError("uid", { type: "custom", message: "Invalid user" });
    } else {
      await clientService.assignManager(client.organisationId, client.clientId, user);
    }

    handleClose(true);
  };

  const handleClose = (periodAdded: boolean) => {
    onClose(periodAdded);
  };

  return (
    <Dialog open onClose={() => handleClose(false)} disableRestoreFocus fullWidth>
      <DialogTitle>Assign accountant</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ py: 2 }}>
          <Box>
            {isEmpty(accountants) ? (
              "No users available"
            ) : (
              <TextField
                id="name"
                label="Accountant"
                select
                fullWidth
                defaultValue={find(accountants, (accountant) => accountant.uid === client.manager)?.uid || ""}
                error={!!errors.uid}
                helperText={errors.uid?.message}
                {...register("uid")}
              >
                {accountants.map((accountant) => (
                  <MenuItem key={accountant.uid} value={accountant.uid}>
                    <div>
                      {accountant.firstName} {accountant.lastName}
                      &nbsp;
                      <Box component="span" sx={{ color: "text.secondary" }}>
                        - {accountant.email}
                      </Box>
                    </div>
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting || isEmpty(accountants)}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignAccountantDialog;
