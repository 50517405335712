import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import useCatalogueService from "../../hooks/CatalogueService";
import SubmitButton from "../button/SubmitButton";
import { CategoryCatalogueType } from "../../types";
import DialogContentText from "@mui/material/DialogContentText";
import { size } from "lodash";

type DeleteCatalogueCategoryDialogProps = {
  organisationId: string;
  catalogueId: string;
  category: CategoryCatalogueType;
  open: boolean;
  onClose: (categoryDeleted: boolean) => void;
};

const DeleteCatalogueCategoryDialog = ({
  organisationId,
  catalogueId,
  category,
  open,
  onClose,
}: DeleteCatalogueCategoryDialogProps) => {
  const catalogueService = useCatalogueService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    await catalogueService.deleteCategory(organisationId, catalogueId, category.categoryId);
    onClose(true);
  };

  const canRemoveCategory = () => {
    return size(category.requirements) === 0;
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>{canRemoveCategory() ? "Delete category" : "Cannot delete category"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            {canRemoveCategory()
              ? `Delete the category "${category.name}"?`
              : `Remove all of the requirements from the category "${category.name}" to delete it.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" disabled={!canRemoveCategory()} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteCatalogueCategoryDialog;
