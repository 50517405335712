import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";
import SubmitButton from "../button/SubmitButton";
import useUserService from "../../hooks/UserService";
import BaseAuthPage from "./BaseAuthPage";

const formSchema = yup
  .object({
    email: yup.string().trim().email("Email must be a valid email address").required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const LoginPage = () => {
  const { userId } = useApplicationState();
  const userService = useUserService();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { email, password } = data;
    try {
      await userService.signIn(email, password);
    } catch (e: any) {
      const message = "There was an error logging in. Check your email address and password and try again.";
      setError("password", {
        type: "custom",
        message,
      });
    }
  };

  useEffect(() => {
    if (userId) {
      const redirectTo = location?.state?.redirectTo || "/";
      navigate(redirectTo);
    }
  }, [userId, navigate, location]);

  return (
    <BaseAuthPage
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6">Login</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Email address"
                autoComplete="username"
                autoFocus
                error={errors?.email?.message != null}
                helperText={errors.email?.message}
                {...register("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                error={errors?.password?.message != null}
                helperText={errors.password?.message}
                {...register("password")}
              />
            </Grid>

            <Grid item xs={12} textAlign="center">
              <SubmitButton loading={isSubmitting} label="Login" />
            </Grid>
          </Grid>
        </form>
      }
      footer={
        <>
          Forgot your password? Click <Link to="/reset-password">here</Link> to reset it.
        </>
      }
    />
  );
};

export default LoginPage;
