import { Box, IconButton } from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import { ReactNode, useState } from "react";

type DebugInfoProps = {
  children: ReactNode;
};

const DebugInfo = ({ children }: DebugInfoProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return (
    <Box>
      <IconButton aria-label="delete" size="small" onClick={toggle} sx={{ opacity: 0.5, mb: 1, p: 0 }}>
        <BugReportIcon fontSize="inherit" />
      </IconButton>
      {visible ? <Box>{children}</Box> : null}
    </Box>
  );
};

export default DebugInfo;
