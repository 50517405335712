import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useCatalogueService from "../../hooks/CatalogueService";
import { RequirementCatalogueType } from "../../types";
import SubmitButton from "../button/SubmitButton";

const formSchema = yup
  .object({
    requirementName: yup.string().trim().required("Requirement title is required"),
    requirementDescription: yup.string().trim(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type EditCatalogueRequirementDialogProps = {
  organisationId: string;
  catalogueId: string;
  categoryId: string;
  requirement: RequirementCatalogueType;
  open: boolean;
  onClose: (requirementUpdated: boolean) => void;
};

const EditCatalogueRequirementDialog = ({
  organisationId,
  catalogueId,
  categoryId,
  requirement,
  open,
  onClose,
}: EditCatalogueRequirementDialogProps) => {
  const catalogueService = useCatalogueService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data: FormType) => {
    const { requirementName, requirementDescription } = data;
    await catalogueService.editRequirement(
      organisationId,
      catalogueId,
      categoryId,
      requirement.requirementId,
      requirementName,
      requirementDescription ?? ""
    );
    handleClose(true);
  };

  const handleClose = (requirementUpdated: boolean) => {
    reset({
      requirementName: requirementUpdated ? getValues("requirementName") : requirement.name,
      requirementDescription: requirementUpdated ? getValues("requirementDescription") : requirement.description,
    });
    onClose(requirementUpdated);
  };

  useEffect(() => {
    setValue("requirementName", requirement.name);
    setValue("requirementDescription", requirement.description);
  }, [requirement.name, requirement.description, setValue]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Edit requirement</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Title"
                error={!!errors.requirementName}
                helperText={errors.requirementName?.message}
                {...register("requirementName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                multiline
                rows={8}
                fullWidth
                label="Description (optional)"
                error={!!errors.requirementDescription}
                helperText={errors.requirementDescription?.message}
                {...register("requirementDescription")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCatalogueRequirementDialog;
