import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BaseAuthPage from "./BaseAuthPage";
import SubmitButton from "../button/SubmitButton";
import { useApplicationState } from "../../hooks/ApplicationState";
import useUserService from "../../hooks/UserService";
import { Link } from "react-router-dom";
import React from "react";

const formSchema = yup
  .object({
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const ResetPasswordPage = () => {
  const { setSystemMessage } = useApplicationState();
  const userService = useUserService();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { email } = data;
    try {
      await userService.requestPasswordReset(email);
      setSystemMessage("Check your email for instructions on how to reset your password.");
      reset();
    } catch (e: any) {
      const message = "An unexpected error occurred. Please reload the page and try again.";
      setError("email", { type: "custom", message });
    }
  };

  return (
    <BaseAuthPage
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6">Reset password</Typography>
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Typography variant="body2" color="text.primary">
                Forgot your password? Enter your email below and we'll send you an email with instructions on how to
                reset it.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="email"
                label="Email address"
                autoComplete="username"
                autoFocus
                error={errors?.email?.message != null}
                helperText={errors.email?.message}
                {...register("email")}
              />
            </Grid>

            <Grid item xs={12} textAlign="center">
              <SubmitButton loading={isSubmitting} label="Reset password" />
            </Grid>
          </Grid>
        </form>
      }
      footer={
        <>
          Already have an account? Click <Link to="/login">here</Link> to login.
        </>
      }
    />
  );
};

export default ResetPasswordPage;
