import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { DndContext, PointerSensor, useDraggable, useDroppable, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";

type DraggableProps = {
  id: string;
};

const Draggable = ({ id }: DraggableProps) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        p: 4,
        mx: 1,
        textAlign: "center",
        backgroundColor: "grey.200",
        transform: CSS.Translate.toString(transform),
        border: "solid 1px black",
      }}
      {...listeners}
      {...attributes}
    >
      Draggable - {id}
    </Box>
  );
};

type DroppableProps = {
  id: string;
};

const Droppable = ({ id }: DroppableProps) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <Box
      ref={setNodeRef}
      sx={{
        border: "solid 2px black",
        backgroundColor: isOver ? "grey.600" : "grey.500",
        p: 4,
        my: 4,
        textAlign: "center",
      }}
    >
      Droppable - {id}
    </Box>
  );
};

const DragAndDropPage = () => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 5,
      },
    })
  );

  const [eventDescription, setEventDescription] = useState<string>();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setEventDescription(active && over ? `Dropped ${active.id} on ${over.id}` : "Invalid drag");
  };

  return (
    <Container maxWidth="sm" sx={{ mb: 4 }}>
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <Box sx={{ display: "flex", mt: 4, mb: 8 }}>
          <Draggable id="draggable1" />
          <Draggable id="draggable2" />
          <Draggable id="draggable3" />
        </Box>

        <Droppable id="droppable1" />
        <Droppable id="droppable2" />
        <Droppable id="droppable3" />
        <Droppable id="droppable4" />
        <Droppable id="droppable5" />
      </DndContext>

      <Box component="pre">{eventDescription}</Box>
    </Container>
  );
};

export default DragAndDropPage;
