import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import SubmitButton from "../../button/SubmitButton";
import useEntityService from "../../../hooks/EntityService";
import { CategoryType } from "../../../types";

interface DeleteCategoryDialogProps {
  open: boolean;
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  category: CategoryType;
  onClose: (categoryDeleted: boolean) => void;
}

const DeleteCategoryDialog = ({
  open,
  organisationId,
  clientId,
  periodId,
  entityId,
  category,
  onClose,
}: DeleteCategoryDialogProps) => {
  const entityService = useEntityService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    await entityService.deleteCategory(organisationId, clientId, periodId, entityId, category.categoryId);
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>Delete category</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            You are about to delete the category:&nbsp;
            <Box component="span" sx={{ fontWeight: "bold" }}>
              {category.name}
            </Box>
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>
            This will also delete all requirements and sub-categories.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => onClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteCategoryDialog;
