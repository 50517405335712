import Box from "@mui/material/Box";
import React from "react";
import { CategoryType } from "../types";
import { flatten, map, size } from "lodash";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { useApplicationState } from "../hooks/ApplicationState";
import VerticalSortContainer from "../dnd/VerticalSortContainer";
import SortableTableRow from "../dnd/SortableTableRow";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import StatusDot from "./client/StatusDot";

type CategoryListProps = {
  categories: CategoryType[];
  selectedCategory?: CategoryType;
  onSelect: (category: CategoryType) => void;
  onDragEnd: (event: DragEndEvent) => void;
};

const CategoryList = ({ categories, selectedCategory, onSelect, onDragEnd }: CategoryListProps) => {
  const { isAccountant } = useApplicationState();

  const getAllRequirements = (category: CategoryType) => {
    return flatten([...category.requirements, ...map(category.categories, (subCategory) => subCategory.requirements)]);
  };

  return (
    <VerticalSortContainer onDragEnd={onDragEnd} items={categories}>
      <TableContainer component={Paper} square elevation={0}>
        <Table aria-label="Category table" sx={{ ".MuiTableCell-root": { borderBottom: "none" } }} size="small">
          <TableBody>
            {categories.map((category) => (
              <SortableTableRow
                key={category.id}
                id={category.id}
                enabled={isAccountant}
                sx={{ cursor: "pointer" }}
                hover
                selected={category.categoryId === selectedCategory?.categoryId}
                onClick={() => onSelect(category)}
              >
                <TableCell width="100%" sx={{ p: 0.5 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", my: 1 }}>
                    <StatusDot requirements={getAllRequirements(category)} />

                    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, ml: 1 }}>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {category.name}
                      </Typography>

                      <Typography variant="subtitle2" color="text.secondary" sx={{ fontWeight: 400 }}>
                        {`${size(getAllRequirements(category))} requirements`}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
              </SortableTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </VerticalSortContainer>
  );
};

export default CategoryList;
