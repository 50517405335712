import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import useRequirementService from "../../../hooks/RequirementService";
import { RequirementId } from "../../../types";
import TaxyTooltip from "../../TaxyTooltip";

type RequirementAccountantNotesProps = {
  id: RequirementId;
};

const RequirementAccountantNotes = ({ id }: RequirementAccountantNotesProps) => {
  const { organisationId, clientId, periodId, entityId, categoryId, subCategoryId, requirementId } = id;

  const requirementService = useRequirementService();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [editedValue, setEditedValue] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setEditedValue(event.target.value);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    await requirementService.setAccountantNote(id, editedValue);
    setValue(editedValue);
    setEditMode(false);
    setIsSubmitting(false);
  };

  const onCancel = () => {
    setEditedValue(value);
    setEditMode(false);
  };

  const loadRequirement = useCallback(async () => {
    const result = await requirementService.getRequirement({
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirementId,
    });
    const accountantNotes = result?.accountantNotes || "";
    setValue(accountantNotes);
    setEditedValue(accountantNotes);
    setEditMode(false);
  }, [organisationId, clientId, periodId, entityId, categoryId, subCategoryId, requirementId, requirementService]);

  useEffect(() => {
    loadRequirement();
  }, [loadRequirement]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          Notes
        </Typography>
        <Box>
          {editMode ? (
            <Box sx={{ ml: 2 }}>
              <TaxyTooltip title="Save note">
                <IconButton onClick={onSubmit} disabled={isSubmitting}>
                  {isSubmitting ? <CircularProgress size={24} /> : <CheckIcon />}
                </IconButton>
              </TaxyTooltip>
              <TaxyTooltip title="Cancel">
                <IconButton onClick={onCancel} disabled={isSubmitting}>
                  <CloseIcon />
                </IconButton>
              </TaxyTooltip>
            </Box>
          ) : (
            <TaxyTooltip title="Edit notes">
              <IconButton onClick={() => setEditMode(true)} sx={{ ml: 2 }}>
                <EditIcon />
              </IconButton>
            </TaxyTooltip>
          )}
        </Box>
      </Box>

      <Box>
        {editMode ? (
          <TextField
            autoFocus
            id="notes"
            placeholder="Notes about this requirement (not shown to client)"
            fullWidth
            multiline
            size="small"
            minRows="3"
            disabled={isSubmitting}
            value={editedValue}
            onChange={onChange}
          />
        ) : (
          <Typography variant="body2" sx={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
            {value}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default RequirementAccountantNotes;
