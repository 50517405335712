import { Box, DialogContentText, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { CommentType, RequirementType } from "../../../types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ConfirmationDialog from "../../dialogs/ConfirmationDialog";
import EditCommentDialog from "./EditCommentDialog";
import moment from "moment";
import { orderBy, size } from "lodash";
import useRequirementService from "../../../hooks/RequirementService";
import { useApplicationState } from "../../../hooks/ApplicationState";

const formatDate = (timestamp: number): string => {
  return moment(timestamp, "X").format("ddd DD/MM/YYYY h:mm:ssa");
};

type CommentsProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId?: string;
  requirement: RequirementType;
  onCommentUpdated: () => void;
};

const Comments = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirement,
  onCommentUpdated,
}: CommentsProps) => {
  const { user } = useApplicationState();
  const requirementService = useRequirementService();

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeletemodal] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const [commentId, setCommentId] = useState<string>("");

  const isEditable = useCallback(
    (comment: CommentType) => {
      return requirement.status !== "COMPLETE" && comment.createdBy === user?.email;
    },
    [requirement.status, user?.email]
  );

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditCommentClick = () => {
    setOpenEditModal(true);
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setOpenDeletemodal(false);
  };

  const handleSubmitUpdatedComment = (updatedComment: string) => {
    void handleUpdateComment(updatedComment, commentId);
    handleClose();
  };

  const handleSubmitDeleteComment = async () => {
    void handleDeleteComment(commentId);
    handleClose();
  };

  const handleUpdateComment = async (updatedComment: string, commentId: string) => {
    await requirementService.updateComment(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirement.requirementId,
      commentId,
      updatedComment
    );

    onCommentUpdated();
  };

  const handleDeleteComment = async (commentId: string) => {
    await requirementService.deleteComment(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      requirement.requirementId,
      commentId
    );

    onCommentUpdated();
  };

  return requirement ? (
    <Box sx={{ mt: 2 }}>
      {orderBy(requirement.comments, (comment) => comment.createdAtTimestamp, "desc").map((comment, index) => (
        <Box key={comment.createdAtTimestamp} sx={{ py: 1 }}>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  {comment.createdBy}
                </Typography>
                <Typography variant="caption">{formatDate(comment.createdAtTimestamp)}</Typography>
              </Box>
              {isEditable(comment) && (
                <Box>
                  <IconButton
                    size="small"
                    aria-label="More options"
                    onClick={(e) => {
                      handleMenuClick(e);
                      setComment(comment.comment);
                      setCommentId(comment.id);
                    }}
                    data-key={comment.id}
                  >
                    <MoreVertIcon fontSize="inherit" />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
          <Typography variant="body1" sx={{ mt: 0.5, whiteSpace: "pre-wrap" }}>
            {comment.comment}
            {comment.edited && (
              <Box component="span" sx={{ opacity: 0.5 }}>
                &nbsp;(edited)
              </Box>
            )}
          </Typography>
          {index + 1 < size(requirement.comments) && <Divider sx={{ pt: 1 }} />}
        </Box>
      ))}

      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(undefined)}>
        <MenuItem
          onClick={() => {
            handleEditCommentClick();
            setAnchorEl(undefined);
          }}
        >
          Edit comment
        </MenuItem>

        <MenuItem
          onClick={() => {
            setAnchorEl(undefined);
            setOpenDeletemodal(true);
          }}
        >
          Delete comment
        </MenuItem>
      </Menu>

      <EditCommentDialog
        open={openEditModal}
        comment={comment}
        onClose={handleClose}
        onSubmit={handleSubmitUpdatedComment}
      />

      <ConfirmationDialog
        open={openDeleteModal}
        title="Delete comment"
        content={
          <>
            <DialogContentText>Delete the following comment?</DialogContentText>
            <Box sx={{ borderLeft: "solid 2px #ccc", py: 1, px: 2, mt: 2, whiteSpace: "pre-wrap" }}>
              <Typography variant="body2">{comment}</Typography>
            </Box>
          </>
        }
        confirmButtonLabel="Delete"
        onClose={handleClose}
        onConfirm={handleSubmitDeleteComment}
        maxWidth="sm"
      />
    </Box>
  ) : null;
};

export default Comments;
