import Avatar from "@mui/material/Avatar";
import { ClientType } from "../../types";

type ClientAvatarProps = {
  client: ClientType;
};

//	Ref: https://stackoverflow.com/a/16348977
const stringToColour = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  //	Ref: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
  const amount = -50;
  return (
    "#" +
    colour
      .replace(/^#/, "")
      .replace(/../g, (colour) =>
        ("0" + Math.min(255, Math.max(0, parseInt(colour, 16) + amount)).toString(16)).substr(-2)
      )
  );
};

const getClientInitials = (client: ClientType) => {
  const { name } = client;
  const nameParts = name.split(" ");
  return `${nameParts[0]?.[0]?.toLocaleUpperCase() || ""}${nameParts[1]?.[0]?.toLocaleUpperCase() || ""}`;
};

const ClientAvatar = ({ client }: ClientAvatarProps) => {
  return (
    <Avatar
      sx={{
        bgcolor: stringToColour(client.name),
        mr: 2,
        width: 32,
        height: 32,
        fontSize: "1rem",
      }}
    >
      {getClientInitials(client)}
    </Avatar>
  );
};

export default ClientAvatar;
