export enum RequirementStatusEnum {
  WITH_CLIENT = "WITH_CLIENT",
  WITH_ACCOUNTANT = "WITH_ACCOUNTANT",
  COMPLETE = "COMPLETE",
}

export const RequirementStatusEnumAgentDisplay = {
  [RequirementStatusEnum.WITH_ACCOUNTANT]: "Review",
  [RequirementStatusEnum.WITH_CLIENT]: "With client",
  [RequirementStatusEnum.COMPLETE]: "Ready",
};

export const RequirementStatusEnumClientDisplay = {
  [RequirementStatusEnum.WITH_ACCOUNTANT]: "With accountant",
  [RequirementStatusEnum.WITH_CLIENT]: "Needs your input",
  [RequirementStatusEnum.COMPLETE]: "Ready",
};

// these are theme colours or custom colours defined in theme.tsx
export const RequirementStatusEnumColour = {
  [RequirementStatusEnum.WITH_CLIENT]: "statusWaiting",
  [RequirementStatusEnum.WITH_ACCOUNTANT]: "statusReview",
  [RequirementStatusEnum.COMPLETE]: "statusCompleted",
  default: "default",
} as const;
