import { useMemo } from "react";

export class LocalStorageService {
  public getSelectedPeriodId(clientId: string): string | null {
    const key = this.getSelectedPeriodIdKey(clientId);
    return localStorage.getItem(key);
  }

  public setSelectedPeriodId(clientId: string, periodId: string): void {
    const key = this.getSelectedPeriodIdKey(clientId);
    localStorage.setItem(key, periodId);
  }

  private getSelectedPeriodIdKey(clientId: string): string {
    return `periodId-${clientId}`;
  }

  public getSelectedEntityId(clientId: string): string | null {
    const key = this.getSelectedEntityIdKey(clientId);
    return localStorage.getItem(key);
  }

  public setSelectedEntityId(clientId: string, entityId: string): void {
    const key = this.getSelectedEntityIdKey(clientId);
    localStorage.setItem(key, entityId);
  }

  private getSelectedEntityIdKey(clientId: string): string {
    return `entityId-${clientId}`;
  }

  public getSelectedCategoryId(clientId: string): string | null {
    const key = this.getSelectedCategoryIdKey(clientId);
    return localStorage.getItem(key);
  }

  public setSelectedCategoryId(clientId: string, categoryId: string): void {
    const key = this.getSelectedCategoryIdKey(clientId);
    localStorage.setItem(key, categoryId);
  }

  private getSelectedCategoryIdKey(clientId: string): string {
    return `category-${clientId}`;
  }

  public getSelectedRequirementId(clientId: string): string | null {
    const key = this.getSelectedRequirementIdKey(clientId);
    return localStorage.getItem(key);
  }

  public setSelectedRequirementId(clientId: string, requirementId: string): void {
    const key = this.getSelectedRequirementIdKey(clientId);
    localStorage.setItem(key, requirementId);
  }

  private getSelectedRequirementIdKey(clientId: string): string {
    return `requirement-${clientId}`;
  }
}

const useLocalStorageService = () => {
  return useMemo(() => new LocalStorageService(), []);
};

export default useLocalStorageService;
