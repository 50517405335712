import React, { SyntheticEvent, useEffect, useRef, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import { Box, IconButton } from "@mui/material";
import { CreateNewFolder } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  CatalogueType,
  CategoryCatalogueType as CategoryType,
  RequirementCatalogueType as RequirementType,
} from "../types";
import CategoryTree from "./CategoryTree";
import StyledTreeItem, { CloseSquare, MinusSquare, PlusSquare } from "./StyledTreeItem";
import TaxyTooltip from "./TaxyTooltip";

interface CatalogueTreeProps {
  catalogue: CatalogueType;
  requirementSelect: (requirement: RequirementType) => void;
  selectedRequirementId?: string;
  categorySelect: (category: CategoryType) => void;
  selectedCategoryId?: string;
  isAllExpanded?: { expanded: boolean };
  addCategory: () => void;
  editCategory: (category?: CategoryType) => void;
  editRequirement: (category: CategoryType, requirement: RequirementType) => void;
  addRequirement: (category: CategoryType) => void;
  removeCategory: (catalogue: CatalogueType, category: CategoryType) => void;
  isExpanded?: boolean;
  removeRequirement: (catalogue: CatalogueType, category: CategoryType, requirement: RequirementType) => void;
  orderRequirement: (
    category: CategoryType,
    sourceRequirement: RequirementType,
    targetRequirement: RequirementType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
  moveRequirement: (
    sourceCategory: CategoryType,
    sourceRequirement: RequirementType,
    targetCategory: CategoryType,
    targetRequirement?: RequirementType,
    moveAfter?: boolean
  ) => void;
  orderCategory: (
    catalogue: CatalogueType,
    sourceCategory: CategoryType,
    targetCategory: CategoryType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
}

const CatalogueTree: React.FC<CatalogueTreeProps> = (props) => {
  const {
    catalogue,
    categorySelect,
    selectedCategoryId,
    requirementSelect,
    selectedRequirementId,
    addCategory,
    editCategory,
    editRequirement,
    addRequirement,
    removeCategory,
    isAllExpanded,
    removeRequirement,
    orderRequirement,
    moveRequirement,
    orderCategory,
  } = props;
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);

  const catalogueId = catalogue?.catalogueId;

  useEffect(() => {
    setExpandedNodes([catalogueId]);
  }, [catalogueId]);

  const fieldRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({ block: "start" });
    }
  }, [fieldRef]);

  return !catalogue ? null : (
    <DndProvider backend={HTML5Backend}>
      <TreeView
        aria-label="customized"
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        expanded={expandedNodes}
        onNodeSelect={(e: SyntheticEvent<Element, Event>, selectNode: string) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onNodeToggle={(e: SyntheticEvent<Element, Event>, toggledArray: string[]) => {
          setExpandedNodes(toggledArray);
        }}
        sx={{ minHeight: 24, flexGrow: 1, minWidth: 180, overflowY: "auto" }}
      >
        <StyledTreeItem
          key={catalogueId}
          nodeId={catalogueId}
          label={
            <>
              <PersonIcon fontSize="small" sx={{ marginRight: 0.5 }} />
              <Box
                component="span"
                onClick={(e: { stopPropagation: () => void }) => {
                  e.stopPropagation();
                }}
              >
                {catalogue.name}
              </Box>
              <TaxyTooltip title="Add category">
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    addCategory();
                  }}
                >
                  <CreateNewFolder />
                </IconButton>
              </TaxyTooltip>
            </>
          }
        >
          <CategoryTree
            parent={catalogue}
            orderRequirement={orderRequirement}
            moveRequirement={(sourceEntity, targetEntity, ...rest) => moveRequirement(...rest)}
            orderCategory={(...args) => orderCategory(catalogue, ...args)}
            categories={catalogue.categories || {}}
            categorySelect={categorySelect}
            selectedCategoryId={selectedCategoryId}
            requirementSelect={requirementSelect}
            selectedRequirementId={selectedRequirementId}
            isAllExpanded={isAllExpanded}
            editCategory={(category) => editCategory(category)}
            editRequirement={(category, requirement) => editRequirement(category, requirement)}
            addRequirement={(category) => addRequirement(category)}
            removeCategory={(category) => removeCategory(catalogue, category)}
            removeRequirement={(...args) => removeRequirement(catalogue, ...args)}
          />
        </StyledTreeItem>
      </TreeView>
    </DndProvider>
  );
};

export default CatalogueTree;
