import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import React, { useCallback, useEffect, useState } from "react";
import {
  CategoryType,
  ClientType,
  EntityType,
  OrganisationType,
  PeriodType,
  RequirementCounts,
  RequirementStatusType,
  RequirementType,
  UserType,
} from "../../types";
import { chain, filter, find, forEach, get } from "lodash";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import {
  RequirementStatusEnum,
  RequirementStatusEnumAgentDisplay,
  RequirementStatusEnumColour,
} from "../../enums/RequirementStatus";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import useClientService from "../../hooks/ClientService";
import useEntityService from "../../hooks/EntityService";
import Badge from "@mui/material/Badge";
import TaxyTooltip from "../TaxyTooltip";
import { useApplicationState } from "../../hooks/ApplicationState";

type WorkListItemType = {
  organisation: OrganisationType;
  client: ClientType;
  period: PeriodType;
  manager?: UserType;
  entity: EntityType;
  category: CategoryType;
  subCategory?: CategoryType;
  requirement: RequirementType;
};

type AutoCompleteOption = {
  label: string;
  id?: string;
};

interface WorkListSearchProps {
  organisations: OrganisationType[];
  clients: ClientType[];
  accountants: UserType[];
  reloadCount: number;
  onSearchResults: (workListItems: WorkListItemType[]) => void;
}

const WorkListSearch = ({ organisations, clients, accountants, reloadCount, onSearchResults }: WorkListSearchProps) => {
  const { isAccountant } = useApplicationState();
  const clientService = useClientService();
  const entityService = useEntityService();

  // the default requirement status to show (note: this is different for clients and accountants)
  const [defaultStatus, setDefaultStatus] = useState<RequirementStatusType>();

  // options for the client auto-complete
  const [clientOptions, setClientOptions] = useState<AutoCompleteOption[]>([]);

  // options for the manager auto-complete
  const [accountantOptions, setAccountantOptions] = useState<AutoCompleteOption[]>([]);

  const [requirementCounts, setRequirementCounts] = useState<RequirementCounts>();

  // filter state
  const [selectedManager, setSelectedManager] = useState<AutoCompleteOption | null>(null);
  const [selectedClient, setSelectedClient] = useState<AutoCompleteOption | null>(null);
  const [selectedStatus, setSelectedStatus] = useState<RequirementStatusType>();

  useEffect(() => {
    const newDefaultStatus = isAccountant ? "WITH_ACCOUNTANT" : "WITH_CLIENT";
    setDefaultStatus(newDefaultStatus);
    setSelectedStatus(newDefaultStatus);
  }, [isAccountant]);

  useEffect(() => {
    if (selectedClient) {
      const client = find(clients, (client) => client.clientId === selectedClient.id);
      if (client) {
        const { organisationId, clientId } = client;
        return clientService.watchClient(organisationId, clientId, (updatedClient) => {
          setRequirementCounts(updatedClient.requirementCounts);
        });
      }
    }
  }, [clients, selectedClient, clientService]);

  const SearchChip = ({ status }: { status: RequirementStatusType }) => {
    return (
      <Box sx={{ mr: 2 }}>
        <Badge
          badgeContent={`${get(requirementCounts, status, "")}`}
          color="secondary"
          sx={{
            ".MuiBadge-badge": {
              backgroundColor: "grey.400",
            },
          }}
        >
          <Chip
            size="medium"
            label={RequirementStatusEnumAgentDisplay[status]}
            color={selectedStatus === status ? RequirementStatusEnumColour[status] : "neutral"}
            onClick={() => setSelectedStatus(status)}
          />
        </Badge>
      </Box>
    );
  };

  const loadWorkListItems = useCallback(async () => {
    const results: WorkListItemType[] = [];

    // load entities/categories/requirements for all clients
    await Promise.all(
      chain(clients)
        .filter((client) => (selectedClient ? client.clientId === selectedClient.id : true))
        .filter((client) => (selectedManager ? client.manager === selectedManager.id : true))
        .map(async (client) => {
          const { organisationId, clientId, activePeriodId } = client;

          // find the manager for this client
          const manager = find(accountants, (user) => user.uid === client.manager);

          const organisation = find(organisations, (organisation) => organisation.organisationId === organisationId);
          if (!organisation) {
            // there should always be a corresponding organisation
            return;
          }

          if (activePeriodId) {
            // only fetch entities for clients which have requirements in the given status
            const [entities, period] = await Promise.all([
              entityService.getEntities(organisationId, clientId, activePeriodId),
              clientService.getPeriod(organisationId, clientId, activePeriodId),
            ]);

            if (period) {
              await Promise.all(
                entities.map(async (entity) => {
                  const categoryResults = await entityService.getCategories(
                    organisationId,
                    clientId,
                    activePeriodId,
                    entity.entityId
                  );

                  // categories
                  forEach(categoryResults, async (category) => {
                    const requirements = filter(
                      category.requirements,
                      (requirement) => requirement.status === selectedStatus
                    );
                    results.push(
                      ...requirements.map((requirement) => {
                        return {
                          organisation,
                          client,
                          period,
                          manager,
                          entity,
                          category,
                          requirement,
                        };
                      })
                    );

                    // sub-categories
                    forEach(category.categories, (subCategory) => {
                      const subRequirements = filter(
                        subCategory.requirements,
                        (requirement) => requirement.status === selectedStatus
                      );
                      results.push(
                        ...subRequirements.map((requirement) => {
                          return {
                            organisation,
                            client,
                            period,
                            manager,
                            entity,
                            category,
                            subCategory,
                            requirement,
                          };
                        })
                      );
                    });
                  });
                })
              );
            }
          }
        })
        .value()
    );

    onSearchResults(results);
  }, [
    clients,
    organisations,
    accountants,
    clientService,
    entityService,
    selectedManager,
    selectedClient,
    selectedStatus,
    onSearchResults,
  ]);

  // force a reload the worklist items when reloadCount is updated
  useEffect(() => {
    void loadWorkListItems();
  }, [loadWorkListItems, reloadCount]);

  useEffect(() => {
    setClientOptions(
      chain(clients)
        .orderBy((client) => client.name)
        .map((client) => ({ label: client.name, id: client.clientId }))
        .value()
    );
  }, [clients]);

  useEffect(() => {
    setAccountantOptions(
      chain(accountants)
        .orderBy((accountant) => accountant.firstName)
        .map((accountant) => ({
          label: `${accountant.firstName} ${accountant.lastName} (${accountant.email})`,
          id: accountant.uid,
        }))
        .value()
    );
  }, [accountants]);

  const handleSelectClient = (client: AutoCompleteOption | null) => {
    setSelectedClient(client);
    if (!client) {
      setSelectedStatus(defaultStatus);
    }
  };

  const handleSelectManager = (manager: AutoCompleteOption | null) => {
    setSelectedManager(manager);
  };

  const handleClearFilters = () => {
    setSelectedClient(null);
    setSelectedManager(null);
    setSelectedStatus(defaultStatus);
  };

  return isAccountant ? (
    <Grid container spacing={2}>
      <Grid item sm={4} xs={12}>
        <TaxyTooltip title={selectedClient ? "Filters cannot be combined" : ""}>
          <Autocomplete
            size="small"
            disablePortal
            disabled={!!selectedClient}
            autoFocus
            clearOnEscape
            options={accountantOptions}
            value={selectedManager}
            onChange={(event, value) => handleSelectManager(value)}
            renderInput={(params) => <TextField {...params} label="Filter by manager" />}
          />
        </TaxyTooltip>
      </Grid>

      <Grid item sm={4} xs={12}>
        <TaxyTooltip title={selectedManager ? "Filters cannot be combined" : ""}>
          <Autocomplete
            size="small"
            disablePortal
            disabled={!!selectedManager}
            autoFocus
            fullWidth
            clearOnEscape
            options={clientOptions}
            value={selectedClient}
            onChange={(event, value) => handleSelectClient(value)}
            renderInput={(params) => <TextField {...params} label="Filter by client" />}
          />
        </TaxyTooltip>
      </Grid>

      {selectedClient && (
        <Grid item sm={4} xs={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <SearchChip status={RequirementStatusEnum.WITH_ACCOUNTANT} />
            <SearchChip status={RequirementStatusEnum.WITH_CLIENT} />
            <SearchChip status={RequirementStatusEnum.COMPLETE} />
          </Box>
        </Grid>
      )}

      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Button variant="outlined" size="small" startIcon={<ClearIcon />} onClick={handleClearFilters}>
            Clear filters
          </Button>
        </Box>
      </Grid>
    </Grid>
  ) : null;
};

export default WorkListSearch;
