import { TreeItemProps } from "@mui/lab";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { DraggableEnum } from "../enums";
import useCombinedRefs from "../hooks/useCombinedRefs";
import {
  CatalogueType,
  CategoryCatalogueType,
  EntityType,
  isCatalogueType,
  isCategoryType,
  RequirementCatalogueType,
  RequirementType as RequirementOrigType,
} from "../types";
import findCategory from "../utilities/findCategory";

import { isRequirementDnDItemType, RequirementDnDItemType } from "./RequirementTreeItem";
import StyledTreeItem from "./StyledTreeItem";

type RequirementType = RequirementOrigType | RequirementCatalogueType;

export interface CategoryDnDItemType {
  itemIndex: number;
  entity?: EntityType;
  parent: CategoryCatalogueType | CatalogueType;
  category: CategoryCatalogueType;
}

export const isCategoryDnDItemType = (obj: any): obj is CategoryDnDItemType => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  return "entity" in obj && "parent" in obj && "category" in obj;
};

interface CategoryTreeItemPropsType extends TreeItemProps {
  entity?: EntityType;
  parent: CategoryCatalogueType | CatalogueType;
  category: CategoryCatalogueType;
  itemIndex: number;
  lastItem: boolean;
  orderCategory: (
    sourceCategory: CategoryCatalogueType,
    targetCategory: CategoryCatalogueType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
  moveRequirement: (
    sourceEntity: EntityType | undefined,
    targetEntity: EntityType | undefined,
    sourceCategory: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetCategory: CategoryCatalogueType,
    targetRequirement?: RequirementType,
    moveAfter?: boolean
  ) => void;
}

const CategoryTreeItem = forwardRef<HTMLElement, CategoryTreeItemPropsType>(
  ({ entity, parent, category, itemIndex, lastItem, orderCategory, moveRequirement, ...props }, ref) => {
    const isSameParent = useCallback(
      (item: CategoryDnDItemType) =>
        (isCategoryType(parent) && isCategoryType(item.parent) && parent.categoryId === item.parent.categoryId) ||
        (isCatalogueType(parent) && isCatalogueType(item.parent) && parent.catalogueId === item.parent.catalogueId),
      [parent]
    );
    const isNotAncestor = useCallback(
      (item: CategoryDnDItemType) => {
        const [foundCategory] = findCategory(category.categoryId, item.category);
        return !foundCategory;
      },
      [category.categoryId]
    );
    const isChild = useCallback(
      (item: CategoryDnDItemType) => {
        return isCategoryType(item.parent) && item.parent.categoryId === category.categoryId;
      },
      [category.categoryId]
    );

    const [{ isDragging }, drag] = useDrag<CategoryDnDItemType, unknown, { isDragging: boolean }>({
      type: DraggableEnum.Category,
      item: {
        itemIndex,
        entity,
        parent,
        category,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [hoverTop, setHoverTop] = useState(false);
    const [hoverMiddle, setHoverMiddle] = useState(false);
    const [hoverBottom, setHoverBottom] = useState(false);
    const [moveInside, setMoveInside] = useState(false);
    const [moveBefore, setMoveBefore] = useState(false);
    const [moveAfter, setMoveAfter] = useState(false);

    const [{ isOver, item, canDrop }, drop] = useDrop({
      accept: [DraggableEnum.Category, DraggableEnum.Requirement],
      canDrop: (item: CategoryDnDItemType | RequirementDnDItemType) => {
        if (isCategoryDnDItemType(item)) {
          return category.categoryId !== item.category.categoryId && isNotAncestor(item);
        }
        if (isRequirementDnDItemType(item)) {
          return category.categoryId !== item.category.categoryId;
        }
        return false;
      },
      hover: (item, monitor) => {
        if (combinedRefs.current && isCategoryDnDItemType(item)) {
          const hoverBoundingRect = combinedRefs.current.getBoundingClientRect();
          const maxHeight = 34; // keep drop target zones a reasonable size for open categories
          const itemHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
          const headerHeight = Math.min(itemHeight, maxHeight);
          const hoverDiff = headerHeight * 0.4;
          const hoverTopY = hoverDiff;
          const hoverBottomY = itemHeight - hoverDiff;
          const clientOffset = monitor.getClientOffset();
          if (clientOffset) {
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            setHoverTop(hoverClientY <= hoverTopY);
            setHoverMiddle(hoverClientY > hoverTopY && hoverClientY < hoverBottomY);
            setHoverBottom(hoverClientY >= hoverBottomY && hoverClientY <= itemHeight); // to the end of the "header" part
          }
        }
      },
      drop(item: CategoryDnDItemType | RequirementDnDItemType, monitor) {
        if (monitor.didDrop() || !monitor.isOver({ shallow: true })) {
          // another target handled the drop, or this target was not directly hovered
          return;
        }
        if (isCategoryDnDItemType(item)) {
          if (isSameParent(item) && !moveInside) {
            if (moveBefore || moveAfter) {
              orderCategory(
                item.category,
                category,
                item.itemIndex < itemIndex && moveBefore,
                item.itemIndex > itemIndex && moveAfter
              );
            }
          }
        } else if (isRequirementDnDItemType(item)) {
          moveRequirement(item.entity, entity, item.category, item.requirement, category, undefined, true);
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
      }),
    });

    useEffect(() => {
      setMoveInside(false);
      setMoveBefore(false);
      setMoveAfter(false);
      if (item && canDrop && isCategoryDnDItemType(item)) {
        if (!(isSameParent(item) && itemIndex - item.itemIndex === 1)) {
          setMoveBefore(hoverTop);
        }
        if (!(isSameParent(item) && item.itemIndex - itemIndex === 1)) {
          setMoveAfter(hoverBottom);
        }
      }
    }, [canDrop, hoverBottom, hoverMiddle, hoverTop, isChild, isSameParent, item, itemIndex]);

    const innerRef = useRef<HTMLElement>(null);
    const combinedRefs = useCombinedRefs(ref, innerRef);

    const refCallback = useCallback(
      (element: HTMLElement) => {
        combinedRefs.current = element;
        element?.addEventListener("focusin", (e) => {
          // see https://github.com/mui-org/material-ui/issues/29518
          e.stopImmediatePropagation();
        });
        drop(drag(combinedRefs));
      },
      [combinedRefs, drag, drop]
    );

    let border = {};
    if (item) {
      if (isCategoryDnDItemType(item)) {
        if (moveInside) {
          border = { border: "1px solid red" };
        } else if (moveBefore) {
          border = { borderTop: "1px solid red" };
        } else if (moveAfter) {
          border = { borderBottom: "1px solid red" };
        }
      } else if (isRequirementDnDItemType(item)) {
        border = { border: "1px solid red" };
      }
    }

    return (
      <StyledTreeItem
        ref={refCallback}
        {...props}
        style={{
          ...props.style,
          opacity: isDragging ? 0.2 : 1,
          ...(isOver && canDrop ? border : {}),
        }}
      />
    );
  }
);

export default CategoryTreeItem;
