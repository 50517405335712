import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { chain, debounce, toLower } from "lodash";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { UserType } from "../../../types";

interface UsersSearchProps {
  users: UserType[];
  onSearchResults: (results: UserType[]) => void;
}

const UserSearch = ({ onSearchResults, users }: UsersSearchProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<UserType[]>();

  // Manage the value of the search field separately from the actual search term to allow for debounce
  const [searchFieldValue, setSearchFieldValue] = useState<string>("");

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSearchFieldValue(event.target.value);
  };

  const doSearch = useCallback((users: UserType[], name: string) => {
    const searchResults = chain(users)
      .filter((user) =>
        name
          ? toLower(user.firstName).includes(toLower(name)) ||
            toLower(user.lastName).includes(toLower(name)) ||
            toLower(user.email).includes(toLower(name))
          : true
      )
      .orderBy([(user) => user.firstName, (user) => user.lastName])
      .value();
    setSearchResults(searchResults);
  }, []);

  const handleClear = () => {
    setSearchFieldValue("");
    setSearchTerm("");
  };

  const debouncedSetSearchTerm = useMemo(
    () =>
      debounce((newSearchTerm: string) => {
        setSearchTerm(newSearchTerm);
      }, 150),
    []
  );

  useEffect(() => {
    debouncedSetSearchTerm(searchFieldValue);
  }, [searchFieldValue, debouncedSetSearchTerm]);

  useEffect(() => {
    doSearch(users, searchTerm);
  }, [searchTerm, doSearch, users]);

  useEffect(() => {
    if (searchResults) {
      onSearchResults(searchResults);
    }
  }, [searchResults, onSearchResults]);

  return (
    <Box sx={{ display: "flex" }}>
      {/* SEARCH FIELD */}
      <TextField
        sx={{ flexGrow: 1 }}
        value={searchFieldValue}
        autoFocus
        placeholder="Search"
        size="small"
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClear} size="small">
                <CloseIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default UserSearch;
