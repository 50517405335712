import { useDndMonitor } from "@dnd-kit/core";
import { DragEndEvent } from "@dnd-kit/core/dist/types";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import { find, findIndex, get, has, size } from "lodash";
import { useCallback } from "react";
import Droppable from "../../dnd/Droppable";
import SortableTableRow from "../../dnd/SortableTableRow";
import VerticalSortContainer from "../../dnd/VerticalSortContainer";
import { useApplicationState } from "../../hooks/ApplicationState";
import useHttpFunctions from "../../hooks/HttpFunctions";
import useShoeboxService from "../../hooks/ShoeboxService";
import {
  DragType,
  RequirementType,
  RequirementWithSubCategoryIdType,
  SelectionModeType,
  ShoeboxItem,
} from "../../types";
import getFirstNonBlankLine from "../../utilities/getFirstNonBlankLine";
import FilesIcon from "../images/FilesIcon.svg";
import MessagesIcon from "../images/MessagesIcon.svg";
import ItemCount from "./ItemCount";
import RequirementStatusChip from "./RequirementStatusChip";
import StatusDot from "./StatusDot";

type RequirementsListProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  subCategoryId?: string;
  requirements?: RequirementType[];
  checkedRequirements: RequirementWithSubCategoryIdType[];
  onKebab: (currentTarget: HTMLElement, requirement: RequirementType) => void;
  onCheckRequirements: (requirement: RequirementType, subCategoryId?: string) => void;
  onOpenRequirement: (requirement: RequirementType) => void;
  onRequirementUpdated: () => void;
  onReorder: (oldIndex: number, newIndex: number) => void;
  shoeboxItems: ShoeboxItem[];
  selectedRequirementId?: string;
  selectionMode: SelectionModeType;
};

const RequirementsList = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  subCategoryId,
  requirements,
  onKebab,
  onCheckRequirements,
  onOpenRequirement,
  onRequirementUpdated,
  onReorder,
  shoeboxItems,
  selectedRequirementId,
  checkedRequirements,
  selectionMode = "dnd",
}: RequirementsListProps) => {
  const { isAccountant } = useApplicationState();
  const shoeboxService = useShoeboxService();
  const { dropShoeboxFile } = useHttpFunctions();

  const handleDropShoeboxFile = useCallback(
    async (
      organisationId: string,
      clientId: string,
      periodId: string,
      entityId: string,
      categoryId: string,
      subCategoryId: string | undefined,
      requirementId: string,
      shoeboxId: string,
      fileId: string
    ) => {
      await dropShoeboxFile({
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId,
        subCategoryId,
        requirementId,
        shoeboxId,
        fileId,
      });
      onRequirementUpdated();
    },
    [onRequirementUpdated, dropShoeboxFile]
  );

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const type = get(active.data.current, "type");

      if (type === DragType.SORTABLE) {
        if (over && over.id !== active.id) {
          const oldIndex = findIndex(requirements, { id: active.id as string });
          const newIndex = findIndex(requirements, { id: over.id as string });

          // check both requirements are in the list (because this handler may fire for multiple lists of requirements in the context)
          if (oldIndex !== -1 && newIndex !== -1) {
            onReorder(oldIndex, newIndex);
          }
        }
      } else if (type === DragType.DRAGGABLE) {
        if (over && over.id) {
          const requirementId = over?.id as string;
          const requirement = find<RequirementType>(requirements, { id: requirementId });
          if (requirement) {
            const [shoeboxId, fileId] = shoeboxService.parseShoeboxDragId(active.id as string);
            if (shoeboxId && fileId) {
              const shoeboxItem = find<ShoeboxItem>(shoeboxItems, { id: shoeboxId });
              if (shoeboxItem && has(shoeboxItem.files, fileId)) {
                void handleDropShoeboxFile(
                  organisationId,
                  clientId,
                  periodId,
                  entityId,
                  categoryId,
                  subCategoryId,
                  requirementId,
                  shoeboxId,
                  fileId
                );
              }
            }
          }
        }
      }
    },
    [
      requirements,
      shoeboxItems,
      onReorder,
      shoeboxService,
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      subCategoryId,
      handleDropShoeboxFile,
    ]
  );

  useDndMonitor({
    onDragEnd,
  });

  return requirements ? (
    // NOTE: this sort container is already in a DndContext from the shoebox
    <VerticalSortContainer items={requirements} includeDndContext={false}>
      <TableContainer component={Paper} square elevation={0}>
        <Table aria-label="Requirements table" sx={{ ".MuiTableCell-root": { borderBottom: "none" } }} size="medium">
          <TableBody>
            {requirements.map((requirement) => (
              <SortableTableRow
                key={requirement.id}
                id={requirement.id}
                enabled={isAccountant}
                sx={{ cursor: "pointer" }}
                hover
                selectionMode={selectionMode}
                selected={requirement.requirementId === selectedRequirementId}
                checked={checkedRequirements.some((req) => req.requirementId === requirement.requirementId)}
                onCheck={() => onCheckRequirements(requirement, subCategoryId)}
                onClick={() => onOpenRequirement(requirement)}
              >
                <TableCell sx={{ px: 0 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <StatusDot requirement={requirement} showTooltip={false} />
                  </Box>
                </TableCell>

                <TableCell width="80%" sx={{ p: 0.5 }}>
                  <Droppable id={requirement.id}>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {requirement.name}
                    </Typography>
                    {!!requirement.description && (
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{
                          fontWeight: 400,
                          maxWidth: {
                            xs: "40ch",
                            md: "60ch",
                            xl: "80ch",
                          },
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {getFirstNonBlankLine(requirement.description)}
                      </Typography>
                    )}
                  </Droppable>
                </TableCell>

                <TableCell sx={{ p: 0.5 }}>
                  <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <ItemCount icon={MessagesIcon} alt="Comments" count={size(requirement.comments)} />
                    <ItemCount icon={FilesIcon} alt="Files" count={size(requirement.fileIds)} />
                  </Box>
                </TableCell>

                <TableCell width="20%" sx={{ p: 0.5 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      mx: 1,
                      my: 2,
                    }}
                  >
                    <RequirementStatusChip requirement={requirement} />

                    {isAccountant && (
                      <Box>
                        <IconButton
                          size="small"
                          aria-label="More options"
                          sx={{ ml: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onKebab(e.currentTarget, requirement);
                          }}
                        >
                          <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
              </SortableTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </VerticalSortContainer>
  ) : null;
};

export default RequirementsList;
