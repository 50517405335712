import Typography, { TypographyProps } from "@mui/material/Typography";

const Copyright = (props: TypographyProps) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Copyright &copy; Taxy {new Date().getFullYear()}
    </Typography>
  );
};

export default Copyright;
