import { get, last, split, toLower } from "lodash";
import React, { ReactNode } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import VideoIcon from "@mui/icons-material/VideoFile";
import FileIcon from "@mui/icons-material/InsertDriveFile";

type FileTypeIconProps = {
  filename: string;
};

const FileTypeIcon = ({ filename }: FileTypeIconProps) => {
  const icons: Record<string, ReactNode> = {
    pdf: <PictureAsPdfIcon />,
    png: <ImageIcon />,
    jpg: <ImageIcon />,
    jpeg: <ImageIcon />,
    heic: <ImageIcon />,
    mp4: <VideoIcon />,
  };
  const parts = split(filename, ".");
  const extension = toLower(last(parts));
  const icon = get(icons, extension, <FileIcon />);
  return <>{icon}</>;
};

export default FileTypeIcon;
