import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FileType } from "../../types";
import SubmitButton from "../button/SubmitButton";
import getFileBasename from "../../utilities/getFileBasename";
import getFileExtension from "../../utilities/getFileExtension";

const formSchema = yup
  .object({
    name: yup.string().required("Name is required").trim(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type RenameFileDialogProps = {
  open: boolean;
  onClose: () => void;
  file: FileType;
  rename: (file: FileType, fileName: string) => Promise<void>;
};

const RenameFileDialog = ({ open, file, rename, onClose }: RenameFileDialogProps) => {
  const [extension, setExtension] = useState<string>("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  useEffect(() => {
    setExtension(getFileExtension(file.name));
    setValue("name", getFileBasename(file.name));
  }, [file.name, setValue]);

  const onSubmit = async ({ name }: FormType) => {
    await rename(file, name);
    handleClose();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth="sm">
      <DialogTitle>
        <span>Rename file</span>
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="start">.{extension}</InputAdornment>,
                }}
                {...register("name")}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose()} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RenameFileDialog;
