import { useMemo } from "react";
import { InviteType } from "../types";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import { query, where } from "firebase/firestore";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";
import ServiceError from "./ServiceError";

export class InviteService {
  constructor(readonly firestoreService: FirestoreService, readonly httpFunctions: HttpFunctions) {}

  public async getInvite(inviteId: string): Promise<InviteType> {
    const documentReference = this.firestoreService.getInviteDocument(inviteId);
    const documentSnapshot = await this.firestoreService.getDocumentSnapshot(documentReference);
    return {
      id: documentSnapshot.id,
      ...documentSnapshot.data(),
    } as InviteType;
  }

  public async getClientInvites(organisationId: string, clientId: string): Promise<InviteType[]> {
    const invitesCollection = this.firestoreService.getInvitesCollection();
    const invitesQuery = query(
      invitesCollection,
      where("organisationId", "==", organisationId),
      where("clientId", "==", clientId),
      where("status", "!=", "ACCEPTED")
    );

    const querySnapshot = await this.firestoreService.getDocs(invitesQuery);
    return querySnapshot.docs.map((documentSnapshot) => {
      return {
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      } as InviteType;
    });
  }

  public async getAccountantInvites(organisationId: string): Promise<InviteType[]> {
    const invitesCollection = this.firestoreService.getInvitesCollection();
    const invitesQuery = query(
      invitesCollection,
      where("organisationId", "==", organisationId),
      where("role", "==", "ACCOUNTANT"),
      where("status", "!=", "ACCEPTED")
    );

    const querySnapshot = await this.firestoreService.getDocs(invitesQuery);
    return querySnapshot.docs.map((documentSnapshot) => {
      return {
        id: documentSnapshot.id,
        ...documentSnapshot.data(),
      } as InviteType;
    });
  }

  public async resendInvite(inviteId: string): Promise<string | undefined> {
    const { data } = await this.httpFunctions.resendInvite({ inviteId });
    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }
}

const useInviteService = () => {
  const firestoreService = useFirestoreService();
  const httpFunctions = useHttpFunctions();
  return useMemo(() => {
    return new InviteService(firestoreService, httpFunctions);
  }, [firestoreService, httpFunctions]);
};

export default useInviteService;
