import { TreeItemProps } from "@mui/lab";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import { DraggableEnum } from "../enums";
import useCombinedRefs from "../hooks/useCombinedRefs";
import {
  CategoryCatalogueType,
  EntityType,
  RequirementCatalogueType,
  RequirementType as RequirementOrigType,
} from "../types";

import StyledTreeItem from "./StyledTreeItem";

type RequirementType = RequirementOrigType | RequirementCatalogueType;

export interface RequirementDnDItemType {
  itemIndex: number;
  entity?: EntityType;
  category: CategoryCatalogueType;
  requirement: RequirementType;
}

export const isRequirementDnDItemType = (obj: any): obj is RequirementDnDItemType => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  return "entity" in obj && "category" in obj && "requirement" in obj;
};

interface RequirementTreeItemPropsType extends TreeItemProps {
  entity?: EntityType;
  category: CategoryCatalogueType;
  requirement: RequirementType;
  itemIndex: number;
  lastItem: boolean;
  orderRequirement: (
    category: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetRequirement: RequirementType,
    moveBefore?: boolean,
    moveAfter?: boolean
  ) => void;
  moveRequirement: (
    sourceEntity: EntityType | undefined,
    targetEntity: EntityType | undefined,
    sourceCategory: CategoryCatalogueType,
    sourceRequirement: RequirementType,
    targetCategory: CategoryCatalogueType,
    targetRequirement?: RequirementType,
    moveAfter?: boolean
  ) => void;
}

const RequirementTreeItem = forwardRef<HTMLElement, RequirementTreeItemPropsType>(
  ({ entity, category, requirement, lastItem, itemIndex, orderRequirement, moveRequirement, ...props }, ref) => {
    const isSameParent = useCallback(
      (item: RequirementDnDItemType) => item.category.categoryId === category.categoryId,
      [category.categoryId]
    );
    const [{ isDragging }, drag] = useDrag<RequirementDnDItemType, unknown, { isDragging: boolean }>({
      type: DraggableEnum.Requirement,
      item: {
        itemIndex,
        entity,
        category,
        requirement,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [hoverTop, setHoverTop] = useState(false);
    const [hoverBottom, setHoverBottom] = useState(false);
    const [moveBefore, setMoveBefore] = useState(false);
    const [moveAfter, setMoveAfter] = useState(false);

    const [{ isOver, item, canDrop }, drop] = useDrop({
      accept: DraggableEnum.Requirement,
      canDrop: (item: RequirementDnDItemType) => {
        return item.requirement.requirementId !== requirement.requirementId;
      },
      hover: (item, monitor) => {
        if (combinedRefs.current) {
          const hoverBoundingRect = combinedRefs.current.getBoundingClientRect();
          const maxHeight = 34; // keep drop target zones a reasonable size for open requirements
          const itemHeight = hoverBoundingRect.bottom - hoverBoundingRect.top;
          const headerHeight = Math.min(itemHeight, maxHeight);
          const hoverTopY = Math.min(itemHeight * 0.5, headerHeight);
          const clientOffset = monitor.getClientOffset();
          if (clientOffset) {
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            setHoverTop(hoverClientY <= hoverTopY);
            setHoverBottom(hoverClientY >= hoverTopY && hoverClientY <= itemHeight); // to the end of the "header" part
          }
        }
      },
      drop(item: RequirementDnDItemType) {
        if (isSameParent(item)) {
          if (moveBefore || moveAfter) {
            orderRequirement(
              category,
              item.requirement,
              requirement,
              item.itemIndex < itemIndex && moveBefore,
              item.itemIndex > itemIndex && moveAfter
            );
          }
        } else {
          if (moveBefore || moveAfter) {
            moveRequirement(item.entity, entity, item.category, item.requirement, category, requirement, moveAfter);
          }
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        item: monitor.getItem(),
        canDrop: monitor.canDrop(),
      }),
    });

    useEffect(() => {
      setMoveBefore(false);
      setMoveAfter(false);
      if (item && canDrop) {
        if (!(isSameParent(item) && itemIndex - item.itemIndex === 1)) {
          setMoveBefore(hoverTop);
        }
        if (!(isSameParent(item) && item.itemIndex - itemIndex === 1)) {
          setMoveAfter(hoverBottom);
        }
      }
    }, [canDrop, hoverBottom, hoverTop, isSameParent, item, itemIndex]);

    const innerRef = useRef<HTMLElement>(null);
    const combinedRefs = useCombinedRefs(ref, innerRef);

    const refCallback = useCallback(
      (element: HTMLElement) => {
        combinedRefs.current = element;
        element?.addEventListener("focusin", (e) => {
          // see https://github.com/mui-org/material-ui/issues/29518
          e.stopImmediatePropagation();
        });
        drop(drag(combinedRefs));
      },
      [combinedRefs, drag, drop]
    );

    let border = {};
    if (item) {
      if (moveBefore) {
        border = { borderTop: "1px solid red" };
      } else if (moveAfter) {
        border = { borderBottom: "1px solid red" };
      }
    }

    return (
      <StyledTreeItem
        ref={refCallback}
        {...props}
        style={{
          ...props.style,
          opacity: isDragging ? 0.2 : 1,
          ...(isOver && canDrop ? border : {}),
        }}
      />
    );
  }
);

export default RequirementTreeItem;
