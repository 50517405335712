import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { EntityEnum } from "../../../enums";
import useEntityService from "../../../hooks/EntityService";
import SubmitButton from "../../button/SubmitButton";

export const formSchema = yup
  .object({
    name: yup.string().trim().required("Entity name is required").default(""),
    entityType: yup
      .mixed<EntityEnum>()
      .oneOf([...Object.values(EntityEnum)], "Entity type is required")
      .required(),
    dueDate: yup.date().notRequired().nullable().typeError("Due date must be a valid date"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface AddEntityDialogProps {
  organisationId: string;
  clientId: string;
  periodId: string;
  open: boolean;
  onClose: (entityAdded: boolean) => void;
}

const AddEntityDialog = ({ organisationId, clientId, periodId, open, onClose }: AddEntityDialogProps) => {
  const entityService = useEntityService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      name: "",
      entityType: undefined,
      dueDate: null,
    },
  });

  const handleClose = (entityAdded: boolean) => {
    reset();
    onClose(entityAdded);
  };

  const onSubmit = async (data: FormType) => {
    const { name, entityType, dueDate } = data;
    await entityService.createEntity(organisationId, clientId, periodId, name, entityType, dueDate);
    handleClose(true);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} fullWidth maxWidth="sm" disableRestoreFocus>
      <DialogTitle>Add an entity</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register("name")}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth error={!!errors.entityType}>
                <InputLabel>Entity type</InputLabel>
                <Select label="Entity type" fullWidth defaultValue="" {...register("entityType")}>
                  {Object.entries(EntityEnum).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.entityType?.message}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth error={!!errors.dueDate}>
                      <DatePicker {...field} label="Due date" value={field.value || null} timezone="UTC" />
                      <FormHelperText>{errors.dueDate?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="contained" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Add" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddEntityDialog;
