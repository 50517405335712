import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const NotFoundPage = () => (
  <Box sx={{ p: 2 }}>
    <Typography variant="h4">Page not found</Typography>
    <p>Oops, we can't find that page.</p>
  </Box>
);

export default NotFoundPage;
