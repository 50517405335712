import React from "react";
import { PeriodType } from "../types";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { find } from "lodash";
import Box from "@mui/material/Box";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

type PeriodSelectProps = {
  periods: PeriodType[];
  selectedId?: string;
  activePeriodId?: string;
  onSelect: (period: PeriodType) => void;
};

const PeriodSelect = ({ periods, selectedId, activePeriodId, onSelect }: PeriodSelectProps) => {
  return (
    <FormControl fullWidth>
      <InputLabel>Period</InputLabel>
      <Select
        label="Period"
        value={selectedId || ""}
        renderValue={(periodId: string) => {
          const period = find(periods, (period) => period.periodId === periodId);
          return period ? (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CalendarMonthIcon fontSize="small" sx={{ mr: 2, color: "text.secondary" }} />
              <Typography variant="body1" sx={{ flexGrow: 1 }}>
                {period.name}
              </Typography>
            </Box>
          ) : (
            "Not selected"
          );
        }}
        onChange={(event: SelectChangeEvent<string>) => {
          const period = find(periods, (period) => period.periodId === event.target.value);
          if (period) {
            onSelect(period);
          }
        }}
      >
        {periods.map((period) => (
          <MenuItem
            key={period.periodId}
            value={period.periodId}
            selected={period.periodId === selectedId}
            sx={{ py: 1 }}
          >
            <ListItemIcon>
              <CalendarMonthIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={period.name} />
            {period.periodId === activePeriodId && (
              <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                Active period
              </Typography>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PeriodSelect;
