import { yupResolver } from "@hookform/resolvers/yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { CategoryType } from "../../../types";
import useEntityService from "../../../hooks/EntityService";
import SubmitButton from "../../button/SubmitButton";

const formSchema = yup
  .object({
    categoryName: yup.string().trim().required("Category name is required"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type EditCategoryDialogProps = {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  category: CategoryType;
  subCategory?: CategoryType;
  open: boolean;
  onClose: (categoryEdited: boolean) => void;
};

const EditCategoryDialog = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  category,
  subCategory,
  open,
  onClose,
}: EditCategoryDialogProps) => {
  const entityService = useEntityService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { categoryName } = data;
    await entityService.editCategory(
      organisationId,
      clientId,
      periodId,
      entityId,
      category.categoryId,
      subCategory?.categoryId,
      categoryName
    );
    handleClose(true);
  };

  const handleClose = (categoryEdited: boolean) => {
    reset({
      categoryName: categoryEdited ? getValues("categoryName") : subCategory ? subCategory.name : category.name,
    });
    onClose(categoryEdited);
  };

  useEffect(() => {
    setValue("categoryName", subCategory ? subCategory.name : category.name);
  }, [subCategory, category, setValue]);

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Edit {subCategory ? "sub-category" : "category"}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Category name"
            error={!!errors.categoryName}
            helperText={errors.categoryName?.message}
            {...register("categoryName")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditCategoryDialog;
