import Box from "@mui/material/Box";
import { UserType } from "../types";

type FormattedUserNameProps = {
  user?: UserType;
  defaultValue?: string;
};

const FormattedUserName = ({ user, defaultValue = "" }: FormattedUserNameProps) => {
  return user ? (
    <Box component="span">
      {user.firstName} {user.lastName}
    </Box>
  ) : (
    <Box component="span" sx={{ color: "grey.400" }}>
      {defaultValue}
    </Box>
  );
};

export default FormattedUserName;
