// TODO: these should probably also be Enum and Display pairs
export enum EntityEnum {
  Individual = "Individual",
  Company = "Company",
  Trust = "Trust",
}

export enum DraggableEnum {
  Entity = "Entity",
  Category = "Category",
  Requirement = "Requirement",
}
