import {
  collection,
  collectionGroup,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  onSnapshot,
  Query,
  QuerySnapshot,
  runTransaction,
  setDoc,
  Transaction,
  Unsubscribe,
  UpdateData,
  updateDoc,
  WithFieldValue,
} from "firebase/firestore";
import { useMemo } from "react";
import { useApplicationState } from "./ApplicationState";
import usePathService, { PathService } from "./PathService";
import ServiceError from "./ServiceError";

export interface FirestoreDocument {
  id: string;
  path: string;
}

export type FirestoreCreate<T extends FirestoreDocument> = Omit<WithFieldValue<T>, keyof FirestoreDocument>;
export type FirestoreUpdate<T extends FirestoreDocument> = Partial<Omit<WithFieldValue<T>, keyof FirestoreDocument>>;

export class FirestoreService {
  constructor(
    readonly db: Firestore,
    readonly pathService: PathService,
    readonly incrementLoadingCount: () => void,
    readonly decrementLoadingCount: () => void
  ) {}

  public getUsersCollection(): CollectionReference {
    return collection(this.db, this.pathService.getUsersCollectionPath());
  }

  public getUserDocument(userId: string): DocumentReference {
    return doc(this.db, this.pathService.getUserDocumentPath(userId));
  }

  public getInvitesCollection(): CollectionReference {
    return collection(this.db, this.pathService.getInvitesCollectionPath());
  }

  public getInviteDocument(inviteId: string): DocumentReference {
    return doc(this.db, this.pathService.getInviteDocumentPath(inviteId));
  }

  public getToursCollection(): CollectionReference {
    return collection(this.db, this.pathService.getToursCollectionPath());
  }

  public getTourDocument(tourId: string): DocumentReference {
    return doc(this.db, this.pathService.getTourDocumentPath(tourId));
  }

  public getTourUsersCollection(tourId: string): CollectionReference {
    return collection(this.db, this.pathService.getTourUsersCollectionPath(tourId));
  }

  public getTourTourUserDocument(tourId: string, userId: string): DocumentReference {
    return doc(this.db, this.pathService.getTourUserDocumentPath(tourId, userId));
  }

  public getOrganisationsCollection(): CollectionReference {
    return collection(this.db, this.pathService.getOrganisationsCollectionPath());
  }

  public getOrganisationDocument(organisationId: string): DocumentReference {
    return doc(this.db, this.pathService.getOrganisationDocumentPath(organisationId));
  }

  public getCataloguesCollection(organisationId: string): CollectionReference {
    return collection(this.db, this.pathService.getCataloguesCollectionPath(organisationId));
  }

  public getCatalogueDocument(organisationId: string, catalogueId: string): DocumentReference {
    return doc(this.db, this.pathService.getCatalogueDocumentPath(organisationId, catalogueId));
  }

  public getCatalogueCategoriesCollection(organisationId: string, catalogueId: string): CollectionReference {
    return collection(this.db, this.pathService.getCatalogueCategoriesCollectionPath(organisationId, catalogueId));
  }

  public getCatalogueCategoryDocument(
    organisationId: string,
    catalogueId: string,
    categoryId: string
  ): DocumentReference {
    return doc(this.db, this.pathService.getCatalogueCategoryDocumentPath(organisationId, catalogueId, categoryId));
  }

  public getCatalogueRequirementDocument(
    organisationId: string,
    catalogueId: string,
    categoryId: string,
    requirementId: string
  ): DocumentReference {
    return doc(
      this.db,
      this.pathService.getCatalogueRequirementDocumentPath(organisationId, catalogueId, categoryId, requirementId)
    );
  }

  public getClientsCollection(organisationId: string): CollectionReference {
    return collection(this.db, this.pathService.getClientsCollectionPath(organisationId));
  }

  public getClientDocument(organisationId: string, clientId: string): DocumentReference {
    return doc(this.db, this.pathService.getClientDocumentPath(organisationId, clientId));
  }

  public getShoeboxItemsCollection(organisationId: string, clientId: string): CollectionReference {
    return collection(this.db, this.pathService.getShoeboxItemsCollectionPath(organisationId, clientId));
  }

  public getShoeboxItemDocument(organisationId: string, clientId: string, shoeboxId: string): DocumentReference {
    return doc(this.db, this.pathService.getShoeboxItemDocumentPath(organisationId, clientId, shoeboxId));
  }

  public getPeriodsCollection(organisationId: string, clientId: string): CollectionReference {
    return collection(this.db, this.pathService.getPeriodsCollectionPath(organisationId, clientId));
  }

  public getPeriodDocument(organisationId: string, clientId: string, periodId: string): DocumentReference {
    return doc(this.db, this.pathService.getPeriodDocumentPath(organisationId, clientId, periodId));
  }

  public getFilesCollection(organisationId: string, clientId: string, periodId: string): CollectionReference {
    return collection(this.db, this.pathService.getFilesCollectionPath(organisationId, clientId, periodId));
  }

  public getFileDocument(
    organisationId: string,
    clientId: string,
    periodId: string,
    fileId: string
  ): DocumentReference {
    return doc(this.db, this.pathService.getFileDocumentPath(organisationId, clientId, periodId, fileId));
  }

  public getEntitiesCollectionGroup(): Query<DocumentData, DocumentData> {
    return this.getCollectionGroup("entities");
  }

  public getEntitiesCollection(organisationId: string, clientId: string, periodId: string): CollectionReference {
    return collection(this.db, this.pathService.getEntitiesCollectionPath(organisationId, clientId, periodId));
  }

  public getEntityDocument(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string
  ): DocumentReference {
    return doc(this.db, this.pathService.getEntityDocumentPath(organisationId, clientId, periodId, entityId));
  }

  public getCategoriesCollection(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId?: string
  ): CollectionReference {
    return collection(
      this.db,
      this.pathService.getCategoriesCollectionPath(organisationId, clientId, periodId, entityId, categoryId)
    );
  }

  public getCategoryDocument(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): DocumentReference {
    return doc(
      this.db,
      this.pathService.getCategoryDocumentPath(organisationId, clientId, periodId, entityId, categoryId, subCategoryId)
    );
  }

  public getRequirementsCollection(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId?: string
  ): CollectionReference {
    return collection(
      this.db,
      this.pathService.getRequirementsCollectionPath(
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId,
        subCategoryId
      )
    );
  }

  public getRequirementDocument(
    organisationId: string,
    clientId: string,
    periodId: string,
    entityId: string,
    categoryId: string,
    subCategoryId: string | undefined,
    requirementId: string
  ): DocumentReference {
    return doc(
      this.db,
      this.pathService.getRequirementDocumentPath(
        organisationId,
        clientId,
        periodId,
        entityId,
        categoryId,
        subCategoryId,
        requirementId
      )
    );
  }

  public getCollectionGroup(collectionName: string) {
    return collectionGroup(this.db, collectionName);
  }

  public getSystemInfoDocument(): DocumentReference {
    return doc(this.db, this.pathService.getSystemInfoDocumentPath());
  }

  public async getDocSnapshot<T>(documentPath: string): Promise<DocumentSnapshot<T>> {
    return this.wrapFirestoreCall(() => {
      const documentReference = doc(this.db, documentPath);
      return getDoc(documentReference) as Promise<DocumentSnapshot<T>>;
    });
  }

  public async getDocumentSnapshot<T>(documentReference: DocumentReference<T>): Promise<DocumentSnapshot<T>> {
    return this.wrapFirestoreCall(() => {
      return getDoc(documentReference) as Promise<DocumentSnapshot<T>>;
    });
  }

  public toDocument<T extends FirestoreDocument>(documentSnapshot: DocumentSnapshot): T {
    const data = documentSnapshot.data();
    return {
      id: documentSnapshot.id,
      path: documentSnapshot.ref.path,
      ...data,
    } as T;
  }

  public async getDocs<T>(query: Query<T>): Promise<QuerySnapshot<T>> {
    return this.wrapFirestoreCall(() => {
      return getDocs(query);
    });
  }

  public async countDocs(query: Query): Promise<number> {
    return this.wrapFirestoreCall(async () => {
      const snapshot = await getCountFromServer(query);
      return snapshot.data().count;
    });
  }

  public async deleteDocument(documentPath: string): Promise<void> {
    return this.wrapFirestoreCall(() => {
      const documentReference = doc(this.db, documentPath);
      return deleteDoc(documentReference);
    });
  }

  public async createDocument<T extends FirestoreDocument>(
    documentReference: DocumentReference,
    data: FirestoreCreate<T>
  ): Promise<void> {
    return this.wrapFirestoreCall(() => {
      return setDoc(documentReference, data);
    });
  }

  public async updateDocument<AppModelType, DbModelType extends DocumentData>(
    documentReference: DocumentReference<AppModelType, DbModelType>,
    data: UpdateData<DbModelType>
  ): Promise<void> {
    return this.wrapFirestoreCall(() => {
      return updateDoc(documentReference, data);
    });
  }

  public async deleteDoc(documentReference: DocumentReference<unknown>): Promise<void> {
    return this.wrapFirestoreCall(() => {
      return deleteDoc(documentReference);
    });
  }

  public watchCollection<T>(
    collectionReference: CollectionReference<T>,
    callback: (snapshot: QuerySnapshot) => void
  ): Unsubscribe {
    return onSnapshot(collection(this.db, collectionReference.path), callback);
  }

  public watchDocument<T>(
    documentReference: DocumentReference<T>,
    callback: (snapshot: DocumentSnapshot) => void
  ): Unsubscribe {
    return onSnapshot(doc(this.db, documentReference.path), callback);
  }

  public async inTransaction<T>(updateFunction: (transaction: Transaction) => Promise<T>): Promise<T> {
    return runTransaction<T>(this.db, async (transaction) => updateFunction(transaction));
  }

  /**
   * Wraps a Firestore call with loading count increment, error handling, and loading count decrement.
   *
   * @param {Function} call - The Firestore call to be wrapped.
   * @returns {Promise} - A promise that resolves with the result of the Firestore call.
   * @private
   */
  private async wrapFirestoreCall<T>(call: () => Promise<T>): Promise<T> {
    this.incrementLoadingCount();
    try {
      return await call();
    } catch (e) {
      console.error("Error: %o", e);
      throw new ServiceError(`Error connecting to the database`);
    } finally {
      this.decrementLoadingCount();
    }
  }
}

const useFirestoreService = (): FirestoreService => {
  const { incrementLoadingCount, decrementLoadingCount } = useApplicationState();
  const pathService = usePathService();
  const db = getFirestore();
  return useMemo(
    () => new FirestoreService(db, pathService, incrementLoadingCount, decrementLoadingCount),
    [db, pathService, incrementLoadingCount, decrementLoadingCount]
  );
};

export default useFirestoreService;
