import Joyride, { CallBackProps, Placement, STATUS, Step } from "react-joyride";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import JoyrideTooltip from "./JoyrideTooltip";
import { includes, map } from "lodash";
import { useApplicationState } from "../../hooks/ApplicationState";
import useTourService from "../../hooks/TourService";

export type GuidedTourStep = {
  target: string;
  title: string;
  content: ReactNode;
  placement: Placement;
};

type GuidedTourProps = {
  tourId: string;
  steps: GuidedTourStep[];
  delay?: number; // delay in ms before the tour is started
};

const GuidedTour = ({ tourId, steps, delay = 5000 }: GuidedTourProps) => {
  const tourService = useTourService();
  const { userId } = useApplicationState();

  const [tourSteps, setTourSteps] = useState<Step[]>([]);
  const [hasDoneTour, setHasDoneTour] = useState<boolean>();
  const [runTour, setRunTour] = useState<boolean>(false);

  const checkTourStatus = useCallback(async () => {
    if (userId) {
      const result = await tourService.hasDoneTour(tourId, userId);
      setHasDoneTour(result);
    }
  }, [tourService, userId, tourId]);

  const markTourAsDone = useCallback(async () => {
    if (userId) {
      await tourService.markAsDone(tourId, userId);
    }
  }, [tourService, tourId, userId]);

  useEffect(() => {
    // disable beacon on all steps
    setTourSteps(map(steps, (step) => ({ ...step, disableBeacon: true })));
  }, [steps]);

  // check if the user has already done this tour
  useEffect(() => {
    void checkTourStatus();
  }, [checkTourStatus]);

  // run the tour after delay milliseconds
  useEffect(() => {
    if (hasDoneTour === false) {
      const timeoutId = setTimeout(() => {
        setRunTour(true);
      }, delay);
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [hasDoneTour, delay]);

  const handleCallback = (data: CallBackProps) => {
    if (includes([STATUS.SKIPPED, STATUS.FINISHED], data.status)) {
      void markTourAsDone();
    }
  };

  return (
    <Joyride
      steps={tourSteps}
      run={runTour}
      continuous
      disableCloseOnEsc
      disableOverlayClose
      disableScrolling
      tooltipComponent={JoyrideTooltip}
      callback={handleCallback}
      styles={{
        overlay: { height: document.body.scrollHeight },
        options: { zIndex: 10000 },
      }}
    />
  );
};

export default GuidedTour;
