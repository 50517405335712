import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import useRequirementService from "../../../hooks/RequirementService";
import { RequirementWithSubCategoryIdType } from "../../../types";
import SubmitButton from "../../button/SubmitButton";

type DeleteRequirementsDialogProps = {
  open: boolean;
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  categoryId: string;
  requirements: RequirementWithSubCategoryIdType[];
  onClose: (requirementDeleted: boolean) => void;
};

const DeleteRequirementsDialog = ({
  open,
  organisationId,
  clientId,
  periodId,
  entityId,
  categoryId,
  requirements,
  onClose,
}: DeleteRequirementsDialogProps) => {
  const requirementService = useRequirementService();

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async () => {
    const requirementDeletionIds = requirements.map(({ requirementId, subCategoryId }) => ({
      requirementId,
      subCategoryId,
    }));
    await requirementService.deleteRequirements(
      organisationId,
      clientId,
      periodId,
      entityId,
      categoryId,
      requirementDeletionIds
    );
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} disableRestoreFocus fullWidth>
      <DialogTitle>Delete requirements</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete &nbsp;
            <Box component="span" sx={{ fontWeight: "bold" }}>
              {requirements.length}
            </Box>
            &nbsp; requirements?
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ mb: 1 }}>
          <Button variant="outlined" color="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Delete" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DeleteRequirementsDialog;
