import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BaseAuthPage from "./BaseAuthPage";
import SubmitButton from "../button/SubmitButton";
import { useApplicationState } from "../../hooks/ApplicationState";
import useUserService from "../../hooks/UserService";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

const formSchema = yup
  .object({
    firstName: yup.string().trim().required("First name is required"),
    lastName: yup.string().trim().required("Last name is required"),
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
    teamName: yup.string().trim().required("Team name is required"),
    password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

const RegisterPage = () => {
  const { setSystemMessage } = useApplicationState();
  const userService = useUserService();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { firstName, lastName, email, teamName, password } = data;
    try {
      const message = await userService.registerNewUser(firstName, lastName, email, teamName, password);
      setSystemMessage(message);
      reset();
      navigate("/login");
    } catch (e: any) {
      const message = e.message || "An unexpected error occurred. Please reload the page and try again.";
      setError("email", { type: "custom", message });
    }
  };

  return (
    <BaseAuthPage
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6">Sign up to Taxy</Typography>
            </Grid>

            <Grid item xs={12} textAlign="center" sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.primary">
                Enter your details below so you can start using Taxy.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="First name"
                fullWidth
                autoFocus
                error={errors?.firstName?.message != null}
                helperText={errors.firstName?.message}
                {...register("firstName")}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Last name"
                fullWidth
                error={errors?.lastName?.message != null}
                helperText={errors.lastName?.message}
                {...register("lastName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email address"
                autoComplete="username"
                error={errors?.email?.message != null}
                helperText={errors.email?.message}
                {...register("email")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Team name"
                error={errors?.teamName?.message != null}
                helperText={
                  errors.teamName?.message ||
                  "A name for your team. If you invite others to join your team this is the name they will see."
                }
                {...register("teamName")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                {...register("password")}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm password"
                type="password"
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message}
                {...register("confirmPassword")}
              />
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Typography variant="body2" color="text.secondary">
                By clicking the "Register" button below you agree to Taxy's{" "}
                <Link to="/terms-and-conditions" target="_blank">
                  terms and conditions
                </Link>{" "}
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign="center" sx={{ mt: 2 }}>
              <SubmitButton loading={isSubmitting} label="Register" />
            </Grid>
          </Grid>
        </form>
      }
      footer={
        <>
          Already have an account? Click <Link to="/login">here</Link> to login.
        </>
      }
    />
  );
};

export default RegisterPage;
