import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useClientService from "../../hooks/ClientService";
import usePeriodService from "../../hooks/PeriodService";
import ServiceError from "../../hooks/ServiceError";
import { useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";
import useOrganisationService from "../../hooks/OrganisationService";
import { useCallback, useEffect, useState } from "react";
import { OrganisationType } from "../../types";

const formSchema = yup
  .object({
    organisationId: yup.string().required(),
    name: yup.string().trim().required(),
    periodName: yup.string().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface AddClientDialogProps {
  open: boolean;
  onClose: () => void;
}

const AddClientDialog = ({ open, onClose }: AddClientDialogProps) => {
  const { organisationIds } = useApplicationState();
  const organisationService = useOrganisationService();
  const clientService = useClientService();
  const periodService = usePeriodService();
  const navigate = useNavigate();

  const [organisations, setOrganisations] = useState<OrganisationType[]>([]);

  const loadOrganisations = useCallback(async () => {
    const results = await Promise.all(organisationIds.map((organisationId) => organisationService.get(organisationId)));
    setOrganisations(results);
  }, [organisationIds, organisationService]);

  useEffect(() => {
    void loadOrganisations();
  }, [loadOrganisations]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { organisationId, name, periodName } = data;
    try {
      const clientId = await clientService.createClient(organisationId, name, periodName);
      navigate(clientService.getClientUrl(organisationId, clientId));
    } catch (e) {
      if (e instanceof ServiceError) {
        setError("name", {
          type: "custom",
          message: e.message,
        });
      }
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>New client</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box component="div" sx={{ mb: "1rem" }}>
            <TextField
              id="organisationId"
              label="Organisation"
              select
              fullWidth
              defaultValue={organisations[0]?.organisationId}
              error={!!errors.organisationId}
              helperText={errors.organisationId?.message}
              {...register("organisationId")}
            >
              {organisations.map((organisation) => (
                <MenuItem key={organisation.organisationId} value={organisation.organisationId}>
                  {organisation.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box component="div" sx={{ mb: "1rem" }}>
            <TextField
              id="name"
              autoFocus
              fullWidth
              label="Name"
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register("name")}
            />
          </Box>

          <Box component="div" sx={{ mb: "1rem" }}>
            <TextField
              id="periodName"
              label="Period name"
              select
              fullWidth
              defaultValue={periodService.getDefaultPeriod()}
              error={!!errors.periodName}
              helperText={errors.periodName?.message}
              {...register("periodName")}
            >
              {periodService.getPeriods().map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddClientDialog;
