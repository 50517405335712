import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import WebFont from "webfontloader";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { RecoilRoot } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-au";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

WebFont.load({ google: { families: ["Roboto:300,400,500,600"] } });

dayjs.extend(utc);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <RecoilRoot>
        <Container maxWidth={false} disableGutters>
          <Suspense
            fallback={
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }
          >
            <BrowserRouter>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
                <App />
              </LocalizationProvider>
            </BrowserRouter>
          </Suspense>
        </Container>
      </RecoilRoot>
    </ThemeProvider>
  </React.StrictMode>
);
