import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { groupBy, includes, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useAdminService from "../../../hooks/AdminService";
import { EntityWithOrganisationType, OrganisationType, OrganisationWithTotalEntitiesType } from "../../../types";
import AdminLayout from "../AdminLayout";
import OrganisationSearch from "../organisations/OrganisationSearch";
import EntitiesGrowthChart from "./EntitiesGrowthChart";

type SortDirection = "asc" | "desc";
type SortBy = "name" | "totalEntities";
const sortFields: SortBy[] = ["name", "totalEntities"];

const Reports = () => {
  const adminService = useAdminService();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [entities, setEntities] = useState<EntityWithOrganisationType[]>([]);

  const [organisations, setOrganisations] = useState<OrganisationWithTotalEntitiesType[]>([]);

  // organisation after applying sort filters
  const [sortedOrganisations, setSortedOrganisations] = useState<OrganisationWithTotalEntitiesType[]>([]);

  // organisation after applying search filters
  const [filteredOrganisations, setFilteredOrganisations] = useState<OrganisationWithTotalEntitiesType[]>([]);

  const handleSearchResults = (results: OrganisationType[]) => {
    setFilteredOrganisations(results as OrganisationWithTotalEntitiesType[]);
  };

  const loadEntities = useCallback(async () => {
    const result = await adminService.listEntitiesWithOrganisations();
    const organisationsWithTotalEntities = Object.entries(groupBy(result, "organisationId")).map(
      ([organisationId, entities]) =>
        ({
          organisationId,
          name: entities[0]?.organisationName,
          totalEntities: entities.length,
        } as OrganisationWithTotalEntitiesType)
    );
    setEntities(result);
    setOrganisations(organisationsWithTotalEntities);
  }, [adminService]);

  useEffect(() => {
    void loadEntities();
  }, [loadEntities]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  useEffect(() => {
    if (sortBy && includes(sortFields, sortBy)) {
      setSortedOrganisations(orderBy(filteredOrganisations, (organisation) => organisation[sortBy], sortDirection));
    } else {
      setSortedOrganisations(filteredOrganisations);
    }
  }, [sortBy, sortDirection, filteredOrganisations]);

  return (
    <AdminLayout header={<Typography variant="h4">Reports</Typography>}>
      <Box display="flex" gap={2} flexDirection="column" width="100%">
        <Box maxWidth={320}>
          <Card>
            <CardContent>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography variant="h6">Total entities</Typography>
                  <Typography variant="h3">{entities.length}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Box maxWidth={800}>
          <Card>
            <CardContent>
              <EntitiesGrowthChart entities={entities} />
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Card>
            <CardContent>
              <Box>
                <Typography variant="h6">Entities per organisation</Typography>
              </Box>

              <Box marginTop={4} marginBottom={2}>
                <OrganisationSearch organisations={organisations} onSearchResults={handleSearchResults} />
              </Box>

              <TableContainer component={Paper} square elevation={0}>
                <Table aria-label="Organisation" size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <SortLabel field="name" label="Organisation" />
                      </TableCell>
                      <TableCell align="right">
                        <SortLabel field="totalEntities" label="Total entities" />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedOrganisations.map((organisation) => (
                      <TableRow key={organisation.organisationId}>
                        <TableCell>{organisation.name}</TableCell>
                        <TableCell align="right">{organisation.totalEntities}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </AdminLayout>
  );
};

export default Reports;
