import React from "react";
import useOrganisationService from "../../hooks/OrganisationService";
import { UserType } from "../../types";
import Typography from "@mui/material/Typography";
import { Box, DialogContentText } from "@mui/material";
import ConfirmationDialog from "../dialogs/ConfirmationDialog";

type AddTeamMemberAdminDialogProps = {
  organisationId: string;
  user: UserType;
  open: boolean;
  onClose: (userUpdated: boolean) => void;
};

const AddTeamMemberAdminDialog = ({ organisationId, user, open, onClose }: AddTeamMemberAdminDialogProps) => {
  const organisationService = useOrganisationService();

  const onConfirm = async () => {
    await organisationService.updateOrganisationAdminUser(organisationId, user.uid, true);
    onClose(true);
  };

  return (
    <ConfirmationDialog
      open={open}
      title="Add administrator rights"
      content={
        <>
          <DialogContentText>Are you sure you want to make the following user an adminstrator?</DialogContentText>

          <Box sx={{ my: 4 }}>
            <Typography variant="body1">
              {user.firstName} {user.lastName}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Box>

          <DialogContentText>
            Note: team administrators can add/remove users and manage the catalogue.
          </DialogContentText>
        </>
      }
      confirmButtonLabel="Add"
      onClose={() => onClose(false)}
      onConfirm={onConfirm}
      maxWidth="sm"
    />
  );
};

export default AddTeamMemberAdminDialog;
