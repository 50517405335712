import { AddAdminUserRequest, RoleType } from "@taxy/common";
import { doc, query, where } from "firebase/firestore";
import { find, forEach } from "lodash";
import { useMemo } from "react";
import { EntityType, EntityWithOrganisationType, OrganisationType, UserType } from "../types";
import { useApplicationState } from "./ApplicationState";
import useFirestoreService, { FirestoreService } from "./FirestoreService";
import useHttpFunctions, { HttpFunctions } from "./HttpFunctions";
import ServiceError from "./ServiceError";

const ADMIN_ROLE: RoleType = "ADMIN";

export class AdminService {
  constructor(readonly firestoreService: FirestoreService, readonly httpFunctions: HttpFunctions) {}

  public async listAdminUsers(): Promise<UserType[]> {
    try {
      const collectionReference = this.firestoreService.getUsersCollection();
      const adminUsersQuery = query(collectionReference, where("roles", "array-contains", ADMIN_ROLE));
      const querySnapshot = await this.firestoreService.getDocs(adminUsersQuery);
      return querySnapshot.docs.map((snapshot) => snapshot.data() as UserType);
    } catch (error) {
      console.error("Error listing admin users", error);
      throw new ServiceError("Unable to list admin users");
    }
  }

  public async createAdminUser({ firstName, lastName, email }: AddAdminUserRequest) {
    const { data } = await this.httpFunctions.addAdminUser({
      firstName,
      lastName,
      email,
    });

    if (!data.success) {
      throw new ServiceError(data.message);
    } else {
      return data.message;
    }
  }

  public async listOrganisations(): Promise<OrganisationType[]> {
    try {
      const collectionReference = this.firestoreService.getOrganisationsCollection();
      const organisationsQuery = query(collectionReference);
      const querySnapshot = await this.firestoreService.getDocs(organisationsQuery);
      return querySnapshot.docs.map(
        (snapshot) => ({ ...snapshot.data(), organisationId: snapshot.id } as OrganisationType)
      );
    } catch (error) {
      console.error("Error listing organisations:", error);
      throw new ServiceError("Unable to list organisations");
    }
  }

  public async createOrganisation(name: string): Promise<string> {
    try {
      const collectionReference = this.firestoreService.getOrganisationsCollection();
      const organisationDoc = doc(collectionReference);
      await this.firestoreService.createDocument(organisationDoc, { name });
      return organisationDoc.id;
    } catch (error) {
      console.error("Error creating organisation:", error);
      throw new ServiceError("Unable to create organisation");
    }
  }

  public async listEntitiesWithOrganisations(): Promise<EntityWithOrganisationType[]> {
    try {
      const [entities, organisations] = await Promise.all([
        this.firestoreService.getDocs(query(this.firestoreService.getEntitiesCollectionGroup())),
        this.listOrganisations(),
      ]);

      const entitiesWithOrganisations: EntityWithOrganisationType[] = [];
      forEach(entities.docs, (doc) => {
        const entity = this.firestoreService.toDocument<EntityType>(doc);
        const { organisationId } = this.firestoreService.pathService.parseDocumentPath(entity.path);
        const organisation = find(organisations, (organisation) => organisation.organisationId === organisationId);

        if (organisation) {
          entitiesWithOrganisations.push({
            ...organisation,
            ...entity,
            organisationName: organisation.name,
          });
        }
      });
      return entitiesWithOrganisations;
    } catch (error) {
      console.error("Error listing entities with organisations:", error);
      throw new ServiceError("Unable to list entities with organisations");
    }
  }
}

const useAdminService = () => {
  const firestoreService = useFirestoreService();
  const httpFunctions = useHttpFunctions();
  const { isAdmin } = useApplicationState();

  if (!isAdmin) {
    throw new ServiceError("Unauthorized access: user is not an authenticated admin.");
  }

  return useMemo(() => new AdminService(firestoreService, httpFunctions), [firestoreService, httpFunctions]);
};

export default useAdminService;
