import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import SubmitButton from "../button/SubmitButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useInviteService from "../../hooks/InviteService";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserService from "../../hooks/UserService";
import { useApplicationState } from "../../hooks/ApplicationState";

const formSchema = yup
  .object({
    firstName: yup.string().trim().required("First name is required"),
    lastName: yup.string().trim().required("Last name is required"),
    email: yup.string().email("Email must be a valid email address").required("Email is required"),
    password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type SignUpFormProps = {
  inviteCode: string;
};

const SignUpForm = ({ inviteCode }: SignUpFormProps) => {
  const userService = useUserService();
  const inviteService = useInviteService();
  const { setSystemMessage } = useApplicationState();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
    setValue,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const loadInvite = useCallback(async () => {
    const invite = await inviteService.getInvite(inviteCode);
    if (invite) {
      setValue("email", invite?.invitee?.email || "");
      setValue("firstName", invite?.invitee?.firstName || "");
      setValue("lastName", invite?.invitee?.lastName || "");
    }
  }, [inviteCode, inviteService, setValue]);

  useEffect(() => {
    void loadInvite();
  }, [loadInvite]);

  const onSubmit = async (data: FormType) => {
    const { firstName, lastName, email, password } = data;
    try {
      const message = await userService.registerInvitedUser(firstName, lastName, email, password, inviteCode);
      setSystemMessage(message);
      await userService.signIn(email, password);
      navigate("/");
      reset();
    } catch (e: any) {
      const message = e.message || "An unexpected error has occurred";
      setError("email", { type: "custom", message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6">Sign up to Taxy</Typography>
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                fullWidth
                label="First name"
                InputLabelProps={{ shrink: field.value !== "" }}
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Last name"
                InputLabelProps={{ shrink: field.value !== "" }}
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Email"
                error={!!errors.email}
                autoComplete="username"
                InputLabelProps={{ shrink: field.value !== "" }}
                helperText={
                  errors.email?.message || "Note: this must be the email address where you received your invite"
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            autoComplete="new-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register("password")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Confirm password"
            type="password"
            autoComplete="new-password"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            {...register("confirmPassword")}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <SubmitButton loading={isSubmitting} label="Register" />
        </Grid>
      </Grid>
    </form>
  );
};

export default SignUpForm;
