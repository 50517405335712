import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getAuth } from "firebase/auth";
import { useCallback, useEffect, useState } from "react";
import { useApplicationState } from "./hooks/ApplicationState";

const DebugPage = () => {
  const applicationState = useApplicationState();

  const [token, setToken] = useState<string>();

  const getToken = useCallback(async () => {
    const auth = getAuth();
    if (auth && auth.currentUser) {
      const token = await auth.currentUser.getIdToken(true);
      setToken(token);
    }
  }, []);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <Container maxWidth="sm" sx={{ mb: 4 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Debug info</Typography>
        </Grid>

        <Grid item xs={12}>
          <pre>{JSON.stringify(applicationState, null, 2)}</pre>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">Bearer token</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ overflowWrap: "break-word", fontFamily: "monospace" }}>{token}</Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DebugPage;
