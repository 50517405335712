import { TableSortLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { includes, keyBy, orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import useClientService from "../../../hooks/ClientService";
import useUserService from "../../../hooks/UserService";
import { ClientType, UserType } from "../../../types";
import ClientSearch from "../../home/ClientSearch";

type OrganisationClientsProps = {
  organisationId: string;
};

type SortBy = "name";
type SortDirection = "asc" | "desc";

const OrganisationClients = ({ organisationId }: OrganisationClientsProps) => {
  const clientService = useClientService();
  const userService = useUserService();

  const [sortBy, setSortBy] = useState<SortBy>();
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const [clients, setClients] = useState<ClientType[]>([]);

  // clients after applying search filters
  const [filteredClients, setFilteredClients] = useState<ClientType[]>([]);

  // clients after applying sort filters
  const [sortedClients, setSortedClients] = useState<ClientType[]>([]);

  const [accountants, setAccountants] = useState<Record<string, UserType>>({});

  const loadClients = useCallback(async () => {
    const results = await clientService.getClients([organisationId]);
    setClients(results);
  }, [clientService, organisationId]);

  const loadAccountants = useCallback(async () => {
    const results = await userService.listAccountantUsers([organisationId]);
    setAccountants(keyBy(results, (result) => result.uid));
  }, [organisationId, userService]);

  useEffect(() => {
    void loadClients();
    void loadAccountants();
  }, [loadAccountants, loadClients]);

  useEffect(() => {
    if (sortBy && includes(["name"], sortBy)) {
      setSortedClients(orderBy(filteredClients, (client) => client[sortBy], sortDirection));
    } else {
      setSortedClients(filteredClients);
    }
  }, [sortBy, sortDirection, filteredClients, accountants]);

  const sortTable = (fieldName: SortBy) => {
    setSortBy(fieldName);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  const handleSearchResults = (results: ClientType[]) => {
    setFilteredClients(results);
  };

  const SortLabel = ({ field, label }: { field: SortBy; label: string }) => {
    return (
      <TableSortLabel active={sortBy === field} direction={sortDirection} onClick={() => sortTable(field)}>
        {label}
      </TableSortLabel>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ my: 2 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Organisation clients</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ my: 2 }}>
          <ClientSearch clients={clients} hasSearchChip={false} onSearchResults={handleSearchResults} />
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper} square elevation={0}>
            <Table aria-label="Clients">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <SortLabel field="name" label="Client" />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedClients.map((client) => (
                  <TableRow key={client.clientId}>
                    <TableCell>{client.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrganisationClients;
