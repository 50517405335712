import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import useAdminService from "../../../hooks/AdminService";
import ServiceError from "../../../hooks/ServiceError";

const formSchema = yup
  .object({
    name: yup.string().trim().required(),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface AddOrganisationDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddOrganisationDialog = ({ open, onClose, onSuccess }: AddOrganisationDialogProps) => {
  const adminService = useAdminService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    setError,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { name } = data;
    try {
      await adminService.createOrganisation(name);
      reset();
      onSuccess();
    } catch (e) {
      if (e instanceof ServiceError) {
        setError("name", {
          type: "custom",
          message: e.message,
        });
      }
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} disableRestoreFocus fullWidth maxWidth="sm">
      <DialogTitle>New organisation</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box component="div" sx={{ mb: "1rem" }}>
            <TextField
              required
              autoFocus
              fullWidth
              label="Name"
              error={!!errors.name}
              helperText={errors.name?.message}
              {...register("name")}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting}>
            Create
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddOrganisationDialog;
