export enum ClientStatusEnum {
  WAITING = "WAITING",
  ALLCOMPLETED = "ALLCOMPLETED",
  REVIEW = "REVIEW",
}

export const ClientStatusEnumDisplay = {
  [ClientStatusEnum.WAITING]: "Waiting",
  [ClientStatusEnum.ALLCOMPLETED]: "All completed",
  [ClientStatusEnum.REVIEW]: "Review",
};

// these are theme colours or custom colours defined in theme.tsx
export const ClientStatusEnumColour = {
  [ClientStatusEnum.WAITING]: "statusWaiting",
  [ClientStatusEnum.ALLCOMPLETED]: "statusCompleted",
  [ClientStatusEnum.REVIEW]: "statusReview",
  default: "default",
} as const;
