import { DragType, ShoeboxItem } from "../../types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { find, get, map } from "lodash";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import MailIcon from "@mui/icons-material/Mail";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from "@mui/icons-material/Delete";
import FormattedTimestamp from "../FormattedTimestamp";
import DraggableItem from "../../dnd/DraggableItem";
import TaxyTooltip from "../TaxyTooltip";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { DragOverlay, DragStartEvent, useDndMonitor } from "@dnd-kit/core";
import ShoeboxItemDetailsDialog from "../shoebox/ShoeboxItemDetailsDialog";
import Drawer from "@mui/material/Drawer";
import React, { Fragment, useCallback, useState } from "react";
import useShoeboxService from "../../hooks/ShoeboxService";
import useClientService from "../../hooks/ClientService";
import Divider from "@mui/material/Divider";

type ShoeboxProps = {
  organisationId: string;
  clientId: string;
  shoeboxItems: ShoeboxItem[];
  open: boolean;
  onShoeboxUpdated: () => Promise<void>;
};

const Shoebox = ({ organisationId, clientId, shoeboxItems, open, onShoeboxUpdated }: ShoeboxProps) => {
  const shoeboxService = useShoeboxService();
  const clientService = useClientService();

  const [selectedShoeboxItem, setSelectedShoeboxItem] = useState<ShoeboxItem>();
  const [shoeboxDragFile, setShoeboxDragFile] = useState<string>();

  const handleArchive = useCallback(
    async (shoeboxItemId: string) => {
      await clientService.archiveShoeboxItem(organisationId, clientId, shoeboxItemId);
      void onShoeboxUpdated();
    },
    [clientService, organisationId, clientId, onShoeboxUpdated]
  );

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;
      if (get(active.data.current, "type") === DragType.DRAGGABLE) {
        const [shoeboxId, fileId] = shoeboxService.parseShoeboxDragId(active.id as string);
        if (shoeboxId && fileId) {
          const shoeboxItem = find(shoeboxItems, ({ id }) => id === shoeboxId);
          const shoeboxFile = shoeboxItem?.files[fileId];
          if (shoeboxFile) {
            setShoeboxDragFile(shoeboxFile);
          }
        }
      }
    },
    [shoeboxItems, shoeboxService]
  );

  const onDragEnd = () => {
    setShoeboxDragFile(undefined);
  };

  useDndMonitor({
    onDragStart,
    onDragEnd,
  });

  return (
    <Drawer
      open={open}
      anchor="bottom"
      variant="persistent"
      PaperProps={{ sx: { height: "40vh" } }}
      ModalProps={{ disableScrollLock: true }}
    >
      <Box>
        <List>
          {map(shoeboxItems, (shoeboxItem) => {
            const { id, subject, createdTimestamp, from, files } = shoeboxItem;
            return (
              <Fragment key={id}>
                <ListItem
                  disablePadding
                  key={id}
                  secondaryAction={
                    <TaxyTooltip title="Click to archive">
                      <IconButton edge="end" onClick={() => void handleArchive(shoeboxItem.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TaxyTooltip>
                  }
                >
                  <ListItemButton
                    disableRipple
                    onClick={() => {
                      setSelectedShoeboxItem(shoeboxItem);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <MailIcon />
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      primary={subject}
                      disableTypography
                      secondary={
                        <Box>
                          <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                            <FormattedTimestamp timestamp={createdTimestamp} />
                          </Typography>

                          <Typography
                            sx={{ display: "inline", ml: 1, color: "grey.600" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            {from}
                          </Typography>

                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              mt: 1,
                              py: 2,
                            }}
                          >
                            {map(files, (fileName, fileId) => {
                              return (
                                <Box
                                  key={fileId}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mr: 3,
                                    pb: 1,
                                    "&:hover": { color: "grey.600" },
                                  }}
                                >
                                  {/* NOTE: draggable id must be a string so concatenate shoebox id and file id */}
                                  <DraggableItem
                                    id={shoeboxService.createShoeboxDragId(id, fileId)}
                                    disableTransform={true}
                                  >
                                    <Box sx={{ display: "flex" }}>
                                      <TaxyTooltip
                                        title="Hold and drag this file over the requirement you want to add it to"
                                        placement="bottom-end"
                                      >
                                        <DragIndicatorIcon sx={{ mr: 1, color: "grey.600" }} />
                                      </TaxyTooltip>
                                      <InsertDriveFileOutlinedIcon sx={{ mr: 0.5 }} />
                                    </Box>
                                  </DraggableItem>
                                  <Typography variant="body2">{fileName}</Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </Fragment>
            );
          })}
        </List>
      </Box>

      {shoeboxDragFile && (
        <DragOverlay>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              color: "grey.500",
              cursor: "pointer",
              textWrap: "nowrap",
            }}
          >
            <DragIndicatorIcon sx={{ mr: 1 }} />
            <InsertDriveFileOutlinedIcon sx={{ mr: 0.5 }} />
            <Typography variant="body2">{shoeboxDragFile}</Typography>
          </Box>
        </DragOverlay>
      )}

      {selectedShoeboxItem && (
        <ShoeboxItemDetailsDialog
          organisationId={organisationId}
          clientId={clientId}
          shoeboxItem={selectedShoeboxItem}
          onClose={() => setSelectedShoeboxItem(undefined)}
        />
      )}
    </Drawer>
  );
};

export default Shoebox;
