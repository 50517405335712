import React, { useCallback, useEffect, useState } from "react";
import { ClientType } from "../../../types";
import useClientService from "../../../hooks/ClientService";
import ClientHeader from "../ClientHeader";
import ClientUsers from "./ClientUsers";
import { useParams } from "react-router-dom";
import ClientSummary from "./ClientSummary";
import Box from "@mui/material/Box";

type ManageClientParams = {
  organisationId: string;
  clientId: string;
};

const ManageClient = () => {
  const { organisationId, clientId } = useParams<ManageClientParams>() as ManageClientParams;
  const clientService = useClientService();

  const [client, setClient] = useState<ClientType>();

  const loadClient = useCallback(async () => {
    const result = await clientService.getClient(organisationId, clientId);
    setClient(result);
  }, [organisationId, clientId, clientService]);

  useEffect(() => {
    void loadClient();
  }, [loadClient]);

  const onClientUpdate = () => {
    void loadClient();
  };

  return client ? (
    <Box>
      <ClientHeader client={client} />
      <ClientSummary client={client} onClientUpdate={onClientUpdate} />
      <ClientUsers organisationId={organisationId} clientId={client.clientId} />
    </Box>
  ) : null;
};

export default ManageClient;
