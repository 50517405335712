import { useMemo } from "react";
import useFirestoreService, { FirestoreService } from "./FirestoreService";

export class TourService {
  constructor(readonly firestoreService: FirestoreService) {}

  public async hasDoneTour(tourId: string, userId: string): Promise<boolean> {
    const tourUser = this.firestoreService.getTourTourUserDocument(tourId, userId);
    const tourUserDocument = await this.firestoreService.getDocumentSnapshot(tourUser);
    return tourUserDocument.exists();
  }

  public async markAsDone(tourId: string, userId: string): Promise<void> {
    const tourUser = this.firestoreService.getTourTourUserDocument(tourId, userId);
    await this.firestoreService.createDocument(tourUser, {});
  }
}

const useTourService = () => {
  const firestoreService = useFirestoreService();

  return useMemo(() => new TourService(firestoreService), [firestoreService]);
};

export default useTourService;
