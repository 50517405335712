import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import styled from "@emotion/styled";

type LoadingProps = {
  loading?: boolean;
};

const LinearProgressStyled = styled(LinearProgress)(() => ({
  height: 2,
}));

const Loading = ({ loading = true }: LoadingProps) => (
  <Box
    sx={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 100,
    }}
  >
    {loading && <LinearProgressStyled color="primary" />}
  </Box>
);

export default Loading;
