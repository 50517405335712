import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { isEmpty, orderBy, some } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ClientType, PeriodType } from "../../../types";
import useClientService from "../../../hooks/ClientService";
import { useEffect } from "react";

interface SetActivePeriodDialogProps {
  client: ClientType;
  periods: PeriodType[];
  onClose: (activePeriodUpdated: boolean) => void;
}

const formSchema = yup.object({
  periodId: yup.string().required("Period is required"),
});

type FormType = yup.InferType<typeof formSchema>;

const SetActivePeriodDialog = ({ client, periods, onClose }: SetActivePeriodDialogProps) => {
  const clientService = useClientService();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<FormType>({ resolver: yupResolver(formSchema), defaultValues: { periodId: "" } });

  const onSubmit = async (data: FormType) => {
    const { periodId } = data;
    await clientService.setActivePeriod(client.organisationId, client.clientId, periodId);
    handleClose(true);
  };

  const handleClose = (activePeriodUpdated: boolean) => {
    onClose(activePeriodUpdated);
  };

  useEffect(() => {
    if (client.activePeriodId && some(periods, (period) => period.periodId === client.activePeriodId)) {
      setValue("periodId", client.activePeriodId);
    }
  }, [setValue, client.activePeriodId, periods]);

  return (
    <Dialog open={true} onClose={() => handleClose(false)} disableRestoreFocus fullWidth>
      <DialogTitle>Set active period</DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ py: 2 }}>
          <DialogContentText sx={{ mb: 4 }}>Select the active period for this client.</DialogContentText>
          <Box>
            <Controller
              name="periodId"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Period"
                  error={!!errors.periodId}
                  select
                  helperText={errors.periodId?.message}
                >
                  {orderBy(periods, (period) => period.name, "desc").map((period) => (
                    <MenuItem key={period.periodId} value={period.periodId}>
                      {period.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="contained" disabled={isSubmitting || isEmpty(periods)}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetActivePeriodDialog;
