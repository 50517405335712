import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import SubmitButton from "../button/SubmitButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useUserService from "../../hooks/UserService";
import { useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";

const formSchema = yup
  .object({
    password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters"),
    confirmPassword: yup.string().oneOf([yup.ref("password"), undefined], "Passwords must match"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

type ResetPasswordFormProps = {
  oobCode: string;
};

const ResetPasswordForm = ({ oobCode }: ResetPasswordFormProps) => {
  const { setSystemMessage } = useApplicationState();
  const userService = useUserService();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormType>({ resolver: yupResolver(formSchema) });

  const onSubmit = async (data: FormType) => {
    const { password } = data;
    try {
      await userService.resetPassword(oobCode, password);
      setSystemMessage("Your password has been successfully changed.");
      reset();
      navigate("/login");
    } catch (e: any) {
      const message = "Error resetting password, please check the link and try again";
      setError("password", { type: "custom", message });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h6">Reset your password</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            autoComplete="new-password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...register("password")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Confirm password"
            type="password"
            autoComplete="new-password"
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            {...register("confirmPassword")}
          />
        </Grid>

        <Grid item xs={12} textAlign="center">
          <SubmitButton loading={isSubmitting} label="Set password" />
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordForm;
