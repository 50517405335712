import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApplicationState } from "../../hooks/ApplicationState";
import useUserService from "../../hooks/UserService";

const LogoutPage = () => {
  const { loggedIn } = useApplicationState();
  const userService = useUserService();
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    if (loggedIn) {
      await userService.signOut();
    }
    navigate("/login", { replace: true });
  }, [loggedIn, navigate, userService]);

  useEffect(() => {
    void logout();
  }, [logout]);

  return null;
};

export default LogoutPage;
