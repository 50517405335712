import { Breadcrumbs, Link, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import useOrganisationService from "../../hooks/OrganisationService";
import { OrganisationType } from "../../types";

type OrganisationParams = {
  organisationId: string;
};

const AdminBreadCrumb = () => {
  const { organisationId } = useParams<OrganisationParams>() as OrganisationParams;
  const organisationService = useOrganisationService();

  const [organisation, setOrganisation] = useState<OrganisationType | null>(null);

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter(Boolean);

  const loadOrganisation = useCallback(async () => {
    if (organisationId) {
      const result = await organisationService.get(organisationId);
      setOrganisation(result);
    }
  }, [organisationService, organisationId]);

  useEffect(() => {
    void loadOrganisation();
  }, [loadOrganisation]);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link component={RouterLink} to="/" underline="hover" color="inherit">
        Home
      </Link>
      {pathnames.map((value, index) => {
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathnames.length - 1;

        if (value === organisationId && !organisation) {
          return null; // Don't render anything until organisation name is fetched to avoid flickering
        }

        const displayValue =
          value === organisationId && organisation ? organisation.name : value.charAt(0).toUpperCase() + value.slice(1);

        return isLast ? (
          <Typography color="textPrimary" key={to}>
            {displayValue}
          </Typography>
        ) : (
          <Link component={RouterLink} to={to} underline="hover" color="inherit" key={to}>
            {displayValue}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default AdminBreadCrumb;
