import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { CategoryType } from "../../../types";
import useCatalogueService from "../../../hooks/CatalogueService";
import { useCallback, useEffect, useState } from "react";
import { find, forEach, size } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "../../button/SubmitButton";
import useEntityService from "../../../hooks/EntityService";

const schema = yup
  .object({
    categoryIds: yup.array().of(yup.string().required()).required(),
    categoryName: yup.string().when("categoryIds", ([categoryIds]) => {
      return categoryIds.length === 0
        ? yup
            .string()
            .trim()
            .required("If no category is selected from the catalogue, a category name must be provided")
            .max(100, "Category name must be 100 characters or less")
        : yup.string().notRequired();
    }),
  })
  .required();

type AddCategoryType = yup.InferType<typeof schema>;

interface AddCategoryDialogProps {
  organisationId: string;
  clientId: string;
  periodId: string;
  entityId: string;
  catalogueId: string;
  parentCategoryId?: string;
  open: boolean;
  onClose: (categoryAdded: boolean) => void;
}

const AddCategoryDialog = ({
  organisationId,
  clientId,
  periodId,
  entityId,
  catalogueId,
  parentCategoryId,
  open,
  onClose,
}: AddCategoryDialogProps) => {
  const catalogueService = useCatalogueService();
  const entityService = useEntityService();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [categorySelectOpen, setCategorySelectOpen] = useState<boolean>(false);

  const loadCategories = useCallback(async () => {
    const results = await catalogueService.getCatalogueCategories(organisationId, catalogueId);
    setCategories(results);
  }, [catalogueService, organisationId, catalogueId]);

  useEffect(() => {
    void loadCategories();
  }, [loadCategories]);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<AddCategoryType>({
    defaultValues: { categoryIds: [], categoryName: "" },
    resolver: yupResolver(schema),
  });

  const selectedCategoryIds = watch("categoryIds") || [];
  const categoryName = watch("categoryName") || [];

  const handleClose = (categoryAdded: boolean) => {
    reset();
    onClose(categoryAdded);
  };

  const getRenderValue = () => {
    const selectedCategoryNames: string[] = [];
    forEach(selectedCategoryIds, (selectedCategoryId) => {
      const category = find(categories, (category) => category.categoryId === selectedCategoryId);
      if (category) {
        selectedCategoryNames.push(category.name);
      }
    });
    return selectedCategoryNames.join(", ");
  };

  const onSubmit = async (data: AddCategoryType) => {
    const { categoryIds, categoryName } = data;
    if (size(categoryIds) > 0) {
      await entityService.addCategoriesFromCatalogue(
        organisationId,
        clientId,
        periodId,
        entityId,
        parentCategoryId,
        catalogueId,
        categoryIds
      );
    } else if (categoryName) {
      await entityService.addCategory(organisationId, clientId, periodId, entityId, parentCategoryId, categoryName);
    }
    handleClose(true);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)} disableRestoreFocus fullWidth maxWidth="md">
      <DialogTitle>Add category</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Box sx={{ mb: 2 }}>Choose one or more existing categories from the catalogue</Box>
          <FormControl fullWidth>
            <InputLabel id="categories-label">Categories</InputLabel>
            <Select
              id="categories"
              labelId="categories-label"
              multiple
              value={selectedCategoryIds}
              renderValue={getRenderValue}
              disabled={categoryName.length > 0}
              input={<OutlinedInput label="Categories" />}
              onOpen={() => setCategorySelectOpen(true)}
              onClose={() => setCategorySelectOpen(false)}
              inputProps={register("categoryIds")}
              {...register("categoryIds")}
            >
              {categories.map(({ categoryId, name }) => (
                <MenuItem key={categoryId} value={categoryId}>
                  <Checkbox checked={selectedCategoryIds.indexOf(categoryId) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mb: 2, mt: 4 }}>or add a new category</Box>
          <TextField
            autoFocus
            fullWidth
            label="Category name"
            disabled={selectedCategoryIds.length > 0}
            error={!!errors.categoryName}
            helperText={errors.categoryName?.message}
            {...register("categoryName")}
          />
        </DialogContent>

        <DialogActions sx={{ mt: 2 }}>
          <Button
            disabled={categorySelectOpen || isSubmitting}
            onClick={() => handleClose(false)}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" disabled={categorySelectOpen} />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddCategoryDialog;
