import DebugInfo from "./DebugInfo";
import CopyToClipboard from "../CopyToClipboard";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useApplicationState } from "../../hooks/ApplicationState";

type ClientDebugInfoProps = {
  organisationId: string;
  clientId: string;
};

const ClientDebugInfo = ({ organisationId, clientId }: ClientDebugInfoProps) => {
  const { isDev } = useApplicationState();
  const clientPath = `/organisations/${organisationId}/clients/${clientId}`;

  return isDev ? (
    <Box sx={{ display: "flex", justifyItems: "flex-start", mx: 1, mb: 8 }}>
      <DebugInfo>
        <Box sx={{ mx: 1 }}>
          <Typography variant="subtitle2">Client path:</Typography>
          <CopyToClipboard textToCopy={clientPath}>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {clientPath}
            </Typography>
          </CopyToClipboard>
        </Box>
      </DebugInfo>
    </Box>
  ) : null;
};

export default ClientDebugInfo;
