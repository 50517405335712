import Box from "@mui/material/Box";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";
import convertServerTimestamp from "../utilities/convertServerTimestamp";

type FormattedTimestampProps = {
  timestamp?: Timestamp;
  defaultValue?: string;
};

const FormattedTimestamp = ({ timestamp, defaultValue = "" }: FormattedTimestampProps) => {
  return timestamp ? (
    <Box component="span">{dayjs(convertServerTimestamp(timestamp)).format("DD/MM/YYYY")}</Box>
  ) : (
    <Box component="span" sx={{ color: "grey.400" }}>
      {defaultValue}
    </Box>
  );
};

export default FormattedTimestamp;
