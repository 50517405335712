import { SvgIconComponent } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type AdminNavigationCardProps = {
  icon: SvgIconComponent;
  title: string;
  url: string;
};

const AdminNavigationCard = ({ icon: Icon, title, url }: AdminNavigationCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        maxWidth: "16rem",
        width: "100%",
      }}
    >
      <CardContent
        component={Button}
        variant="text"
        color="inherit"
        onClick={() => navigate(url)}
        sx={{ width: "100%" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 0.5, p: 2 }}>
          <Icon sx={{ width: 48, height: 48 }} />
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AdminNavigationCard;
