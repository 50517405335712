import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitButton from "../../button/SubmitButton";
import useEntityService from "../../../hooks/EntityService";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { EntityType } from "../../../types";
import { useEffect } from "react";
import dayjs from "dayjs";
import { Timestamp } from "firebase/firestore";

export const formSchema = yup
  .object({
    name: yup.string().trim().required("Entity name is required").default(""),
    dueDate: yup.date().notRequired().nullable().typeError("Due date must be a valid date"),
  })
  .required();

type FormType = yup.InferType<typeof formSchema>;

interface EditEntityDialogProps {
  organisationId: string;
  clientId: string;
  periodId: string;
  entity: EntityType;
  onClose: (entityUpdated: boolean) => void;
}

const toDate = (timestamp?: Timestamp): Date | undefined => {
  return timestamp ? dayjs(timestamp.toDate()).toDate() : undefined;
};

const EditEntityDialog = ({ organisationId, clientId, periodId, entity, onClose }: EditEntityDialogProps) => {
  const entityService = useEntityService();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    setValue,
  } = useForm<FormType>({
    resolver: yupResolver(formSchema),
  });

  const handleClose = (entityUpdated: boolean) => {
    reset();
    onClose(entityUpdated);
  };

  const onSubmit = async (data: FormType) => {
    const { name, dueDate } = data;
    await entityService.updateEntity(organisationId, clientId, periodId, entity.entityId, name, dueDate);
    handleClose(true);
  };

  useEffect(() => {
    setValue("name", entity.name);
    setValue("dueDate", toDate(entity.dueDate));
  }, [entity.name, entity.dueDate, setValue]);

  return (
    <Dialog open={true} onClose={() => handleClose(false)} fullWidth maxWidth="sm" disableRestoreFocus>
      <DialogTitle>Edit entity</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                label="Name"
                error={!!errors.name}
                helperText={errors.name?.message}
                {...register("name")}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth error={!!errors.dueDate}>
                      <DatePicker
                        {...field}
                        label="Due date"
                        value={field.value ? dayjs(field.value) : null}
                        timezone="UTC"
                        defaultValue={null}
                      />
                      <FormHelperText>{errors.dueDate?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="contained" color="secondary">
            Cancel
          </Button>
          <SubmitButton loading={isSubmitting} label="Save" />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditEntityDialog;
