import { TooltipRenderProps } from "react-joyride";
import Card from "@mui/material/Card";
import { CardActions, CardContent, CardHeader } from "@mui/material";
import Button from "@mui/material/Button";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React from "react";

const JoyrideTooltip = ({
  index,
  step,
  size,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  isLastStep,
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <Card {...tooltipProps} sx={{ maxWidth: 400, border: "none" }}>
      <CardHeader
        title={step.title}
        titleTypographyProps={{ variant: "h6" }}
        subheader={`Step ${index + 1} of ${size}`}
        subheaderTypographyProps={{ variant: "body2" }}
        action={
          isLastStep ? null : (
            <Button variant="text" size="small" onClick={skipProps.onClick} color="secondary">
              Skip tour
            </Button>
          )
        }
      />

      <CardContent sx={{ pt: 0 }}>{step.content}</CardContent>

      <CardActions sx={{ display: "flex", mt: 1 }}>
        {index > 0 && (
          <Button variant="text" size="small" onClick={backProps.onClick} startIcon={<NavigateBeforeIcon />}>
            Previous
          </Button>
        )}

        <Box sx={{ flexGrow: 1, alignItems: "flex-end" }} textAlign="right">
          {isLastStep ? (
            <Button variant="text" size="small" onClick={closeProps.onClick} endIcon={<CloseIcon />}>
              Finish
            </Button>
          ) : (
            <Button variant="text" size="small" onClick={primaryProps.onClick} endIcon={<NavigateNextIcon />}>
              Next
            </Button>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default JoyrideTooltip;
