import React from "react";
import ClientBody from "./ClientBody";
import ClientDebugInfo from "../debug/ClientDebugInfo";
import { useParams } from "react-router-dom";

type ClientParams = {
  organisationId: string;
  clientId: string;
};

const Client = () => {
  const { organisationId, clientId } = useParams<ClientParams>() as ClientParams;

  return (
    <>
      <ClientBody organisationId={organisationId} clientId={clientId} />
      <ClientDebugInfo organisationId={organisationId} clientId={clientId} />
    </>
  );
};

export default Client;
