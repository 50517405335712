import { Box, Grid } from "@mui/material";
import React from "react";
import TaxyBreadcrumb from "./AdminBreadCrumb";

type AdminLayoutProps = {
  header: React.ReactNode;
  children: React.ReactNode;
};

const AdminLayout = ({ children, header }: AdminLayoutProps) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          p: 2,
        }}
      >
        <TaxyBreadcrumb />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid",
          borderColor: "neutral.light",
          p: 2,
        }}
      >
        {header}
      </Box>
      <Grid container sx={{ p: 2 }}>
        {children}
      </Grid>
    </>
  );
};

export default AdminLayout;
