import { useMemo } from "react";
import moment from "moment";
import { difference, times } from "lodash";

export class PeriodService {
  getPeriods(): string[] {
    const currentYear = moment().year();
    return times(5, (i) => {
      return `FY ${currentYear + 1 - i}`;
    });
  }

  getAvailablePeriods(existingPeriods: string[]) {
    const allPeriods = this.getPeriods();
    return difference(allPeriods, existingPeriods);
  }

  getDefaultPeriod(): string {
    return `FY ${moment().year()}`;
  }
}

const usePeriodService = () => {
  return useMemo(() => {
    return new PeriodService();
  }, []);
};

export default usePeriodService;
