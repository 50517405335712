import React, { ReactNode } from "react";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import type { DragEndEvent } from "@dnd-kit/core/dist/types";
import { restrictToParentElement } from "@dnd-kit/modifiers";

type VerticalSortContainerProps = {
  onDragEnd?: (event: DragEndEvent) => void;
  items: { id: string }[];
  children: ReactNode;
  includeDndContext?: boolean;
};

const VerticalSortContainer = ({
  onDragEnd,
  items,
  children,
  includeDndContext = true,
}: VerticalSortContainerProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  const sortableContext = (
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      {children}
    </SortableContext>
  );

  return includeDndContext ? (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToParentElement]}>
      {sortableContext}
    </DndContext>
  ) : (
    sortableContext
  );
};

export default VerticalSortContainer;
